import React, { useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { Input } from '@enterprise-ui/canvas-ui-react'

interface EmailFieldProps {
  emails?: string[]
  setEmails: (emails: string[]) => void
  id: string
  label: string
  errorText: string
  showErrorMessage: boolean
  checkTargetDomain: boolean
}
const EmailField: React.FC<EmailFieldProps> = ({
  emails,
  setEmails,
  id,
  label,
  errorText,
  showErrorMessage,
  checkTargetDomain,
}) => {
  const emailRegex = useMemo(() => {
    //abc.def1@target.com or abc_test@test.com.us or abc-ef@hello-minus.co.us
    return checkTargetDomain
      ? // eslint-disable-next-line no-useless-escape
        /^[a-z0-9.\-]+@target.com$/i
      : /^[a-z0-9.\-_]+@[a-z0-9.\-_]+\.[a-z0-9.\-_]+$/i
  }, [checkTargetDomain])

  const [emailString, setEmailString] = useState<string>(
    emails ? emails.join() : '',
  )
  const [invalidEmails, setInvalidEmails] = useState<string[]>([])
  const errorClass = 'hc-clr-error hc-fs-xs'
  const getErrorMessage = () => {
    if (invalidEmails.length > 0) {
      return `Invalid emails, please separate emails with a comma : ${invalidEmails.join()}`
    }
    if (emailString.length === 0) {
      return 'Please provide email'
    }
    return ''
  }
  const errorMessage = showErrorMessage ? getErrorMessage() : ''

  const labelValue = useMemo(() => {
    return checkTargetDomain
      ? `${label}*(Ex: john.doe@target.com, foo.bar@target.com)`
      : `${label}*(Ex: john.doe@vendor.com, foo.bar@vendor.com)`
  }, [checkTargetDomain, label])

  useEffect(() => {
    setInvalidEmails([])
    const invalidEmailsTemp: string[] = []
    const validEmailsTemp: string[] = []

    emailString.split(',').forEach((email) => {
      if (email) {
        emailRegex.test(email.trim())
          ? validEmailsTemp.push(email.trim())
          : invalidEmailsTemp.push(email)
      }
    })
    if (invalidEmailsTemp.length > 0) {
      setInvalidEmails(invalidEmailsTemp)
      setEmails([])
    } else {
      setEmails(validEmailsTemp)
    }
  }, [emailString, emailRegex, setEmails])

  return (
    <>
      <Input.Label className={errorMessage.length > 0 ? errorClass : ''}>
        {labelValue}
      </Input.Label>
      <Input.Text
        id="demo_00"
        value={emailString}
        placeholder={
          checkTargetDomain
            ? "Please provide Sourcing Manager's E-mail (Ex: john.doe@target.com, foo.bar@target.com) "
            : 'Enter a valid E-mail address (Ex: john.doe@vendor.com, foo.bar@vendor.com )'
        }
        onChange={(text: any) => {
          setEmailString(text.target.value)
        }}
      />

      {errorMessage.length > 0 && (
        <span>
          <FontAwesomeIcon
            className="hc-mr-dense"
            icon={faExclamationCircle}
            size="sm"
            color="#b85300"
          />
          <span className={errorClass}>{errorMessage}</span>
        </span>
      )}
    </>
  )
}

export default EmailField
