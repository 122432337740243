import React, { useEffect } from 'react'
import { useUserInfo } from '../../context/UserInfoContext'
import { getAppConfig } from '../../config/AppConfig'
import { UserType } from '../../utils/Constants'

const Logout = () => {
  const { userInfo } = useUserInfo()

  useEffect(() => {
    const config = getAppConfig()
    localStorage.removeItem('id_token')
    localStorage.removeItem('access_token')
    userInfo.user_type === UserType.INTERNAL
      ? (window.location.href = config.internalAuth.logoutUrl)
      : (window.location.href = config.externalAuth.logoutUrl)
  }, [userInfo.user_type])
  return <p>Logging out...</p>
}

export default Logout
