import {
  Button,
  Card,
  Grid,
  Input,
  ToastProvider,
  ToasterToastProps,
} from '@enterprise-ui/canvas-ui-react'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useBusinessPartnerInfo } from '../../context/BusinessPartnerInfoContext'
import { debounceSearchItems } from '../../services/ItemService'
import { ItemSearchResultType } from '../../types/ItemSearchResultType'
import { SearchCriteriaType } from '../../types/SearchCriteriaType'
import { createUrlSearchQuery, getUrlSearchParams } from '../../utils/UrlParams'
import DepartmentClassSearch from '../DeptClassSearch/DeptClassSearch'
import FactoryIdSearch from '../FactorySearch/FactoryIdSearch'
import FactoryNameSearch from '../FactorySearch/FactoryNameSearch'
import ManufacturerStyleSearch from '../ManufacturerStyleSearch/ManufacturerStyleSearch'
import TcinSearch from '../TcinSearch/TcinSearch'
import VendorSearch from '../VendorSearch/VendorSearch'

import {
  captureButtonClickEvent,
  captureSearchEvent,
} from '../../services/analytics/events-service'
import { shiftTcins } from '../../utils/SearchResultsUtil'
import PIDSearch from '../PIDSearch/PIDSearch'
import PcnEventIdSearch from '../PcnEventIdSearch/PcnEventIdSearch'

interface SearchContainerProps {
  onSearchResultsAvailable(searchResults: ItemSearchResultType[]): void
  setSearchInProgress(isSearchInProgress: boolean): void
  setSearchCriteria(searchCriteria: SearchCriteriaType): void
}

type UrlParamsType = {
  tcinsUrlParams: string[]
  dpcisUrlParams: string[]
  deptClassUrlParam: string
  vendorIdsUrlParam: string[]
  manufacturerStylesUrlParam: string[]
  pidsUrlParam: string[]
  factoryIdUrlParam: string
  factoryNameUrlParam: string
  noFactoryOnlyParamBoolean: boolean
  invalidFactoryParamBoolean: boolean
  noVopOnlyParamBoolean: boolean
  pcnEventIdsUrlParam: string[]
  showAllPcnEventTcinsUrlParamBoolean: boolean
}

const urlParamsInitial: UrlParamsType = {
  tcinsUrlParams: [],
  dpcisUrlParams: [],
  deptClassUrlParam: '',
  vendorIdsUrlParam: [],
  manufacturerStylesUrlParam: [],
  pidsUrlParam: [],
  factoryIdUrlParam: '',
  factoryNameUrlParam: '',
  noFactoryOnlyParamBoolean: false,
  invalidFactoryParamBoolean: false,
  noVopOnlyParamBoolean: false,
  pcnEventIdsUrlParam: [],
  showAllPcnEventTcinsUrlParamBoolean: false,
}

const noMatchingToast: ToasterToastProps = {
  type: 'alert',
  heading: 'No matching results',
  message: 'Your search criteria returned empty results',
}
const SearchContainer: FunctionComponent<SearchContainerProps> = ({
  onSearchResultsAvailable,
  setSearchInProgress,
  setSearchCriteria,
}) => {
  const makeToast = ToastProvider.useToaster()

  const location = useLocation()
  const [urlParams, setUrlParams] = useState<UrlParamsType>({
    ...urlParamsInitial,
  })

  const [tcins, setTcins] = useState<string[]>()
  const [dpcis, setDpcis] = useState<string[]>()
  const [deptClass, setDeptClass] = useState<string>()
  const [vendorIds, setVendorIds] = useState<string[]>()
  const [factoryId, setFactoryIds] = useState<string>()
  const [factoryName, setFactoryName] = useState<string>()
  const [manufacturerStyles, setManufacturerStyles] = useState<string[]>()
  const [pids, setPids] = useState<string[]>()
  const { businessPartner } = useBusinessPartnerInfo()
  const [noFactoriesOnly, setNoFactoriesOnly] = useState<boolean>(false)
  const [invalidFactoryOnly, setInvalidFactoryOnly] = useState<boolean>(false)
  const [noVopOnly, setNoVopOnly] = useState<boolean>(false)
  const [pcnEventIds, setPcnEventIds] = useState<string[]>()
  const [navigateTo, setNavigateTo] = useState({
    pathname: '/search',
    search: '',
  })
  const [resetKey, setResetKey] = useState<number>(0)

  const navigate = useNavigate()

  useEffect(() => {
    navigate(navigateTo)
  }, [navigate, navigateTo])

  const onSearch = useCallback(
    (
      tcins: string[],
      dpcis: string[],
      dept_class: string,
      gms_vendor_numbers: string[],
      manufacturer_styles: string[],
      pids: string[],
      factory_id: string,
      no_factory_only: boolean,
      invalid_factories: boolean,
      no_vop_only: boolean,
      pcn_event_ids: string[],
      show_all_pcn_event_tcins: boolean,
    ) => {
      let searchCriteria: SearchCriteriaType = {
        tcins,
        dpcis,
        dept_class,
        gms_vendor_numbers,
        manufacturer_styles,
        pids,
        factory_id,
        no_factory_only,
        invalid_factories,
        no_vop_only,
        pcn_event_ids,
      }
      setSearchInProgress(true)
      captureSearchEvent(searchCriteria)
      setSearchCriteria(searchCriteria)

      searchCriteria =
        show_all_pcn_event_tcins && pcn_event_ids.length > 0
          ? { pcn_event_ids }
          : searchCriteria

      debounceSearchItems(
        searchCriteria,
        (results: any) => {
          setSearchInProgress(false)
          if (results.length === 0) {
            makeToast(noMatchingToast)
          }
          onSearchResultsAvailable(shiftTcins(results, tcins))
        },
        (err: any) => {
          makeToast({
            type: 'error',
            heading: 'No matching results',
            message: err.response.data,
          })
          onSearchResultsAvailable([])
          setSearchInProgress(false)
        },
      )
    },
    [
      setSearchInProgress,
      setSearchCriteria,
      onSearchResultsAvailable,
      makeToast,
    ],
  )

  useEffect(() => {
    if (location.search) {
      const {
        tcinsUrlParams,
        dpcisUrlParams,
        deptClassUrlParam,
        vendorIdsUrlParam,
        manufacturerStylesUrlParam,
        pidsUrlParam,
        factoryIdUrlParam,
        factoryNameUrlParam,
        noFactoryOnlyParam,
        invalidFactoryOnlyParam,
        noVopOnlyParam,
        pcnEventIdsUrlParam,
        showAllPcnEventTcinsUrlParam,
      } = getUrlSearchParams(location.search)
      const noFactoryOnlyParamBoolean: boolean = noFactoryOnlyParam === 'true'
      const invalidFactoryParamBoolean: boolean =
        invalidFactoryOnlyParam === 'true'
      const noVopOnlyParamBoolean: boolean = noVopOnlyParam === 'true'
      const showAllPcnEventTcinsUrlParamBoolean: boolean =
        showAllPcnEventTcinsUrlParam === 'true'
      setUrlParams({
        tcinsUrlParams,
        dpcisUrlParams,
        deptClassUrlParam,
        vendorIdsUrlParam,
        manufacturerStylesUrlParam,
        pidsUrlParam,
        factoryIdUrlParam,
        factoryNameUrlParam,
        noFactoryOnlyParamBoolean,
        invalidFactoryParamBoolean,
        noVopOnlyParamBoolean,
        pcnEventIdsUrlParam,
        showAllPcnEventTcinsUrlParamBoolean,
      })
      onSearch(
        tcinsUrlParams,
        dpcisUrlParams,
        deptClassUrlParam,
        vendorIdsUrlParam,
        manufacturerStylesUrlParam,
        pidsUrlParam,
        factoryIdUrlParam,
        noFactoryOnlyParamBoolean,
        invalidFactoryParamBoolean,
        noVopOnlyParamBoolean,
        pcnEventIdsUrlParam,
        showAllPcnEventTcinsUrlParamBoolean,
      )
    } else {
      onSearchResultsAvailable([])
      setUrlParams(urlParamsInitial)
      setResetKey(Math.random())
    }
  }, [location.search, onSearch, onSearchResultsAvailable])

  const goToSearch = useCallback(
    (showAllPcnEventTcins: boolean = false) => {
      let vendorIdsTemp: string[] = vendorIds ?? []
      if (noFactoriesOnly && vendorIdsTemp.length === 0) {
        vendorIdsTemp = businessPartner.vendors.map(
          (vendor) => vendor.gms_vendor_number,
        )
      }
      if (!(pcnEventIds && pcnEventIds.length > 0 && showAllPcnEventTcins)) {
        showAllPcnEventTcins = false
      }
      const searchQueryStringTemp = createUrlSearchQuery(
        tcins,
        dpcis,
        deptClass,
        vendorIdsTemp,
        manufacturerStyles,
        pids,
        factoryId,
        factoryName,
        noFactoriesOnly,
        invalidFactoryOnly,
        noVopOnly,
        pcnEventIds,
        showAllPcnEventTcins,
      )
      setNavigateTo({
        pathname: '/search',
        search: searchQueryStringTemp,
      })
    },
    [
      businessPartner,
      deptClass,
      dpcis,
      noFactoriesOnly,
      invalidFactoryOnly,
      noVopOnly,
      tcins,
      vendorIds,
      factoryId,
      factoryName,
      manufacturerStyles,
      pids,
      pcnEventIds,
    ],
  )

  useEffect(() => {
    if (noFactoriesOnly) {
      goToSearch()
    }
  }, [noFactoriesOnly, goToSearch])

  const reset = () => {
    setNavigateTo({
      pathname: '/search',
      search: '',
    })
    setNoFactoriesOnly(false)
    setInvalidFactoryOnly(false)
    setNoVopOnly(false)
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      goToSearch()
    }
  }

  return (
    <>
      <Card corners="top">
        <div key={resetKey} className="hc-pa-normal">
          <Grid.Container justify="center">
            <Grid.Item>
              <TcinSearch
                tcinsInitialState={urlParams.tcinsUrlParams}
                dpcisInitialState={urlParams.dpcisUrlParams}
                onTcinChange={setTcins}
                onDpciChange={setDpcis}
                handleKeyPress={handleKeyPress}
              />
            </Grid.Item>
            <Grid.Item>
              <ManufacturerStyleSearch
                manufacturerStyleInitialState={
                  urlParams.manufacturerStylesUrlParam
                }
                onManufacturerStyleChange={setManufacturerStyles}
                handleKeyPress={handleKeyPress}
              />
            </Grid.Item>
            <Grid.Item>
              <PIDSearch
                pidsInitialState={urlParams.pidsUrlParam}
                onPidsChange={setPids}
                handleKeyPress={handleKeyPress}
              />
            </Grid.Item>
            <Grid.Item>
              <DepartmentClassSearch
                deptClassInitialState={urlParams.deptClassUrlParam}
                onDeptClassChange={setDeptClass}
              />
            </Grid.Item>
            <Grid.Item>
              <VendorSearch
                vendorIdsInitialState={urlParams.vendorIdsUrlParam}
                onVendorIdChange={setVendorIds}
              />
            </Grid.Item>
            <Grid.Item xs={2}>
              <FactoryNameSearch
                factoryNameSearchInitialState={urlParams.factoryNameUrlParam}
                onFactoryChange={setFactoryIds}
                onFactoryNameChange={setFactoryName}
              />
            </Grid.Item>
            <Grid.Item>
              <FactoryIdSearch
                FactorySearchInitialState={urlParams.factoryIdUrlParam}
                onFactoryNameChange={setFactoryName}
                onFactoryChange={setFactoryIds}
              />
            </Grid.Item>
            <Grid.Item>
              <PcnEventIdSearch
                pcnEventIdsInitialState={urlParams.pcnEventIdsUrlParam}
                onPcnEventIdsChange={setPcnEventIds}
              />
            </Grid.Item>
            <Grid.Item className="hc-pt-expanded hc-pr-none hc-pl-normal">
              <Grid.Container style={{ justify: 'center' }} noWrap={true}>
                <Grid.Item style={{ marginTop: '5px' }}>
                  <Button
                    data-testid="search-btn"
                    type="secondary"
                    onClick={() => goToSearch()}
                  >
                    Search
                  </Button>
                </Grid.Item>
                <Grid.Item style={{ marginTop: '5px' }}>
                  <Button
                    type="secondary"
                    onClick={() => {
                      captureButtonClickEvent('Reset')
                      reset()
                    }}
                  >
                    Reset
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Card>
      {urlParams.pcnEventIdsUrlParam &&
        urlParams.pcnEventIdsUrlParam.length > 0 && (
          <div style={{ marginTop: '5px' }}>
            <Card corners="top" className="hc-bg-highlight">
              <div style={{ padding: '2px' }}>
                <Input.Checkbox
                  id="demo_00"
                  label={`Show all items in PCN Event ${pcnEventIds?.join()}`}
                  onChange={(event: any) => {
                    goToSearch(event.target.checked)
                  }}
                  highlight
                  dense
                  checked={
                    urlParams.showAllPcnEventTcinsUrlParamBoolean ? true : false
                  }
                />
              </div>
            </Card>
          </div>
        )}
    </>
  )
}

export default SearchContainer
