import {
  FactoryTimelineItem,
  FactoryTimeline,
  LeanFactory,
} from '../types/ItemDetailsType'
import { subDays, addDays } from 'date-fns'
import { hasNoCommonTimeline } from './FactoriesTimelineUtil'

export const addFactoryToFactoryTimeline = (
  factoryToAdd: LeanFactory,
  editableFactoryTimeLine: FactoryTimeline,
) => {
  let factoryTimelineItems: FactoryTimelineItem[] = []

  const factoryAddedBeforeOldestFactory = addFactoryAsOldestFactoryTimeline(
    factoryToAdd,
    editableFactoryTimeLine.factoryTimelineItems,
  )
  const factoryAddedAfterNewestFactory = addFactoryAsNewestFactoryTimeline(
    factoryToAdd,
    editableFactoryTimeLine.factoryTimelineItems,
  )

  factoryTimelineItems.push(...factoryAddedBeforeOldestFactory)
  factoryTimelineItems.push(...factoryAddedAfterNewestFactory)

  /*
    We want to add the new factory to the timeline in a way that it merges with the existing timeline items. 
    To do this we will loop through the existing timeline items and check if the new factory can be added into the factory timeline.
 */
  editableFactoryTimeLine.factoryTimelineItems.forEach(
    (factoryTimelineItem) => {
      const factoryAlreadyExists = factoryTimelineItem.factories.some(
        (factory) => {
          return (
            factory.factory_id === factoryToAdd.factory_id &&
            factory.vendor_order_spec_id ===
              factoryToAdd.vendor_order_spec_id &&
            factory.is_removed !== true
          )
        },
      )

      if (factoryAlreadyExists) {
        factoryTimelineItems.push(factoryTimelineItem)
      } else if (hasNoCommonTimeline(factoryToAdd, factoryTimelineItem)) {
        factoryTimelineItems.push(factoryTimelineItem)
      } else {
        const newFactoryTimelines = addFactoryToCommonTimeline(
          factoryToAdd,
          factoryTimelineItem,
        )
        factoryTimelineItems.push(...newFactoryTimelines)
      }
    },
  )

  // adjust dates of factories within timeline item
  factoryTimelineItems = factoryTimelineItems.map((factoryTimelineItem) => {
    const afterDupFactoryRemoved: LeanFactory[] = []
    const updatedFactories = factoryTimelineItem.factories
      .map((factory) => {
        return {
          ...factory,
          effective_start_date: factoryTimelineItem.effective_start_date,
          effective_end_date: factoryTimelineItem.effective_end_date,
        }
      })
      .sort((factory1, factory2) => {
        return factory1.factory_id < factory2.factory_id ? -1 : 1
      })

    updatedFactories.forEach((factory) => {
      if (
        Number(factoryTimelineItem.effective_start_date) ===
          Number(factoryToAdd.effective_start_date) &&
        Number(factoryTimelineItem.effective_end_date) ===
          Number(factoryToAdd.effective_end_date)
      ) {
        if (
          factory.factory_id !== factoryToAdd.factory_id ||
          (factory.factory_id === factoryToAdd.factory_id &&
            factory.vendor_order_spec_id === factoryToAdd.vendor_order_spec_id)
        ) {
          afterDupFactoryRemoved.push({
            ...factory,
            effective_start_date: factoryTimelineItem.effective_start_date,
            effective_end_date: factoryTimelineItem.effective_end_date,
          })
        }
      } else {
        afterDupFactoryRemoved.push({
          ...factory,
          effective_start_date: factoryTimelineItem.effective_start_date,
          effective_end_date: factoryTimelineItem.effective_end_date,
        })
      }
    })
    return {
      ...factoryTimelineItem,
      factories: afterDupFactoryRemoved,
    }
  })

  return factoryTimelineItems.sort((t1, t2) => {
    return t1.effective_start_date > t2.effective_start_date ? -1 : 1
  })
}

const addFactoryToCommonTimeline = (
  factoryToAdd: LeanFactory,
  factoryTimelineItem: FactoryTimelineItem,
): FactoryTimelineItem[] => {
  let factoryTimelineItems: FactoryTimelineItem[] = []

  if (
    factoryToAdd.effective_start_date > factoryTimelineItem.effective_start_date
  ) {
    factoryTimelineItems.push({
      effective_start_date: factoryTimelineItem.effective_start_date,
      effective_end_date: subDays(factoryToAdd.effective_start_date, 1),
      factories: factoryTimelineItem.factories,
    })
  }

  const startDate =
    factoryToAdd.effective_start_date > factoryTimelineItem.effective_start_date
      ? factoryToAdd.effective_start_date
      : factoryTimelineItem.effective_start_date

  if (
    factoryToAdd.effective_end_date > factoryTimelineItem.effective_end_date
  ) {
    factoryTimelineItems.push({
      effective_start_date: startDate,
      effective_end_date: factoryTimelineItem.effective_end_date,
      factories: [...factoryTimelineItem.factories, factoryToAdd],
    })
  } else if (
    factoryToAdd.effective_end_date < factoryTimelineItem.effective_end_date
  ) {
    factoryTimelineItems.push({
      effective_start_date: startDate,
      effective_end_date: factoryToAdd.effective_end_date,
      factories: [...factoryTimelineItem.factories, factoryToAdd],
    })

    factoryTimelineItems.push({
      effective_start_date: addDays(factoryToAdd.effective_end_date, 1),
      effective_end_date: factoryTimelineItem.effective_end_date,
      factories: factoryTimelineItem.factories,
    })
  } else {
    factoryTimelineItems.push({
      effective_start_date: startDate,
      effective_end_date: factoryTimelineItem.effective_end_date,
      factories: [...factoryTimelineItem.factories, factoryToAdd],
    })
  }

  return factoryTimelineItems
}

const addFactoryAsOldestFactoryTimeline = (
  factoryToAdd: LeanFactory,
  factoryTimelineItems: FactoryTimelineItem[],
): FactoryTimelineItem[] => {
  let newTimeline: FactoryTimelineItem[] = []

  const oldestFactoryTimelineItem =
    factoryTimelineItems[factoryTimelineItems.length - 1]

  if (
    oldestFactoryTimelineItem?.effective_start_date &&
    factoryToAdd.effective_start_date <
      oldestFactoryTimelineItem.effective_start_date &&
    factoryToAdd.effective_end_date >
      oldestFactoryTimelineItem.effective_start_date
  ) {
    newTimeline.push({
      effective_start_date: factoryToAdd.effective_start_date,
      effective_end_date: subDays(
        oldestFactoryTimelineItem.effective_start_date,
        1,
      ),
      factories: [factoryToAdd],
    })
  }
  return newTimeline
}

const addFactoryAsNewestFactoryTimeline = (
  factoryToAdd: LeanFactory,
  factoryTimelineItems: FactoryTimelineItem[],
): FactoryTimelineItem[] => {
  let newTimeline: FactoryTimelineItem[] = []

  const newestFactoryTimelineItem = factoryTimelineItems[0]

  if (
    newestFactoryTimelineItem?.effective_end_date &&
    factoryToAdd.effective_end_date >
      newestFactoryTimelineItem.effective_end_date &&
    factoryToAdd.effective_start_date <
      newestFactoryTimelineItem.effective_end_date
  ) {
    newTimeline.push({
      effective_start_date: addDays(
        newestFactoryTimelineItem.effective_end_date,
        1,
      ),
      effective_end_date: factoryToAdd.effective_end_date,
      factories: [factoryToAdd],
    })
  }

  return newTimeline
}
