import React, { useState, useEffect } from 'react'
import {
  Modal,
  Grid,
  Button,
  Form,
  ProgressBar,
  ToastProviderProps,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import './Feedback.scss'
import { IconInfoType, SelectedIconInfoType } from '../../types/Feedback'
import { trackCustomEvent } from '../../services/firefly/firefly-service'

interface IProps {
  showFeedback: boolean
  headingText: string
  iconsInfo: IconInfoType[]
  setShowFeedback: (showFeedback: boolean) => void
}

const Feedback: React.FC<IProps> = ({
  showFeedback,
  setShowFeedback,
  headingText,
  iconsInfo,
}) => {
  const [comment, setComment] = useState<string>('')
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [selectedIconInfo, setSelectedIconInfo] =
    useState<SelectedIconInfoType>()
  const [icons, setIcons] = useState(iconsInfo)
  const makeToast = ToastProvider.useToaster()
  const feedbackSubmittedToast: ToastProviderProps = {
    heading: 'Feedback Submitted',
    message: 'Feedback sent to Factorial Team',
    type: 'success',
    toastid: Math.random().toString(),
  }
  const feedbackFailureToast: ToastProviderProps = {
    heading: 'Feedback Submission failed',
    message: 'Please try again',
    type: 'alert',
    toastid: Math.random().toString(),
  }
  const sendFeedback = (
    comment: string,
    feedbackIcon: SelectedIconInfoType,
  ) => {
    setInProgress(true)
    trackCustomEvent(
      'feedback',
      feedbackIcon.name,
      comment,
      feedbackIcon.rating,
    )
      .then(() => {
        makeToast(feedbackSubmittedToast)
        setShowFeedback(false)
      })
      .catch(() => {
        makeToast(feedbackFailureToast)
      })
      .finally(() => {
        setInProgress(false)
      })
  }

  useEffect(() => {
    setIcons(
      icons.map((it) =>
        it.iconId === selectedIconInfo?.id
          ? { ...it, selected: true }
          : { ...it, selected: false },
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIconInfo?.id])

  return (
    <Modal
      headingText={headingText}
      onRefuse={() => setShowFeedback(false)}
      isVisible={showFeedback}
      className="feedback"
    >
      <Grid.Container spacing="none" className="hc-ph-lg">
        {inProgress && <ProgressBar indeterminate />}
        <Grid.Item xs={12} className="hc-mv-normal">
          <p>How do you feel about your experience today?</p>
        </Grid.Item>
        <Grid.Item className="hc-mv-normal icons" xs={12}>
          {icons.map((it) => (
            <Button
              aria-label="Menu Icon"
              key={it.iconId}
              className={`${it.iconName} ${it.selected ? 'selected' : ''}`}
              onClick={() =>
                setSelectedIconInfo({
                  id: it.iconId,
                  name: it.iconName,
                  rating: it.rating,
                })
              }
              iconOnly
              alt={it.iconName}
            >
              {it.icon}
            </Button>
          ))}
        </Grid.Item>
        <Grid.Item className="hc-mv-dense" xs={12}>
          <Form.Field
            label="What makes you feel that way?"
            id="feedbackComments"
            type="textarea"
            grow
            size="dense"
            onChange={(e: any) => {
              setComment(e.target.value)
            }}
          />
        </Grid.Item>
        <Grid.Item className="hc-mv-dense hc-ta-right" xs={12}>
          <Button outline="true" onClick={() => setShowFeedback(false)}>
            Cancel
          </Button>
          {selectedIconInfo ? (
            <Button
              type="primary"
              outline="true"
              className="hc-ml-dense"
              onClick={() => {
                sendFeedback(comment, selectedIconInfo)
              }}
            >
              Send
            </Button>
          ) : (
            <Button
              type="primary"
              outline="true"
              className="hc-ml-dense"
              disabled
            >
              Send
            </Button>
          )}
        </Grid.Item>
      </Grid.Container>
    </Modal>
  )
}

export default Feedback
