import {
  Button,
  Grid,
  GridContainer,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import React from 'react'
interface WithdrawConfirmModalProps {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  onWithdrawClicked: () => void
}
const WithdrawConfirmModal: React.FC<WithdrawConfirmModalProps> = (
  props: WithdrawConfirmModalProps,
) => {
  return (
    <Modal
      headingText="Confirm request withdrawl"
      isVisible={props.showModal}
      onRefuse={() => props.setShowModal(false)}
    >
      <div className="hc-pa-normal">
        <GridContainer>
          <Grid.Item xs>
            <p>
              Withdrawing a change factory request will remove it from Target's
              list of requests for review.
            </p>
            <p>Are you sure you want to withdraw the request?</p>
          </Grid.Item>
        </GridContainer>
        <GridContainer direction="row-reverse" spacing="dense">
          <Grid.Item>
            <Button type="primary" onClick={props.onWithdrawClicked}>
              Yes, Withdraw
            </Button>
          </Grid.Item>
          <Grid.Item>
            <Button
              onClick={() => {
                props.setShowModal(false)
              }}
            >
              No, Cancel
            </Button>
          </Grid.Item>
        </GridContainer>
      </div>
    </Modal>
  )
}

export default WithdrawConfirmModal
