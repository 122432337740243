/*
👋 “Hey” from the Praxis team
+ */

import { createRoot } from 'react-dom/client'
import { onError } from '@praxis/component-logging'
import App from './App'

// Standard errors will be automatically logged to STDOUT when thrown.
// The logging sidecar in TAP will process and send logs to the appropriate environment in Kibana.
// Learn more about TAP sidecars: https://tapdocs.prod.target.com/runtime/sidecars/#_platform_sidecars
window.onerror = onError

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
