import { FunctionComponent } from 'react'
import { SmartAssist } from 'smart-assist-canvas'
import { getAppConfig } from '../../config/AppConfig'
import { useUserInfo } from '../../context/UserInfoContext'
import { UserType } from '../../utils/Constants'
import { useHelp } from '../../context/HelpContext'

export const Help: FunctionComponent = () => {
  const { label, startingQuery, setStartingQuery, isHelpOpen, setIsHelpOpen } =
    useHelp()
  const { userInfo } = useUserInfo()
  const config = getAppConfig()
  const apiKey = config.apiKey
  const baseUrl = config.smartAssist ? config.smartAssist.baseUrl : ''
  const userType = userInfo.user_type === UserType.INTERNAL ? 'I' : 'E'
  const confluenceSpace = config.smartAssist
    ? config.smartAssist.confluenceSpace
    : ''
  const environment = config.smartAssist?.environment
  let token = localStorage.getItem('access_token') ?? ''
  return (
    <SmartAssist
      baseUrl={baseUrl}
      confluenceSpace={confluenceSpace}
      label={label}
      apiKey={apiKey}
      userType={userType}
      token={token}
      startingQuery={startingQuery}
      isOpen={isHelpOpen}
      onClose={() => {
        setIsHelpOpen(false)
        setStartingQuery('')
      }}
      iconColor="white"
      environment={environment}
      key={startingQuery}
    />
  )
}
