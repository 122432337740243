import React from 'react'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-autocomplete'
import '@enterprise-ui/canvas-ui-css-datepicker'
import { ConfigProvider } from './components/ConfigProvider'
import { analyticsService } from './services/analytics/analytics-service'
import AppLayout from './layouts/AppLayout'
import { AllContextProviders } from './context/AllContextProviders'
import './App.scss'
function App() {
  // Pageviews are tracked in the router, but only on changes
  // This tracks the first pageview or landing page
  analyticsService.pageView(window.location.pathname)
  return (
    <ConfigProvider>
      <AllContextProviders>
        <AppLayout />
      </AllContextProviders>
    </ConfigProvider>
  )
}

export default App
