import React, { useState, useEffect } from 'react'
import { ItemDetailsType, LeanFactory } from '../../types/ItemDetailsType'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { AutoCompleteOptionType } from '../../types/AutoCompleteOptionType'
import { lookupVendorIdDept } from '../../services/LookupService'

interface EditVOPFormProps {
  currFactory: LeanFactory
  onEditVOP: (factoryChgVOP: LeanFactory) => void
  showForm: boolean
  selectedVendor: AutoCompleteOptionType
  itemDetails: ItemDetailsType
}

export const EditVOPForm: React.FC<EditVOPFormProps> = ({
  currFactory,
  onEditVOP,
  showForm,
  selectedVendor,
  itemDetails,
}) => {
  const [, setIsFormValid] = useState<boolean>(false)
  const [newVOP, setNewVOP] = useState<AutoCompleteOptionType>()

  useEffect(() => {
    const isValidNewVOPInput = newVOP ? true : false
    setIsFormValid(isValidNewVOPInput)
  }, [newVOP, setIsFormValid])

  const editVOP = (value: AutoCompleteOptionType) => {
    if (value) {
      onEditVOP({
        factory_id: currFactory.factory_id,
        factory_name: currFactory.factory_name,
        vendor_order_spec_id: value.id,
        effective_start_date: currFactory.effective_start_date,
        effective_end_date: currFactory.effective_end_date,
        factory_active: true,
      })
    }
  }

  return (
    <>
      {showForm && (
        <Grid.Container>
          <Grid.Item xs={4}>
            <Autocomplete
              label="Vendor Order Point"
              id="vop"
              required
              filter={Autocomplete.filters.none}
              onUpdate={(_id, value) => {
                setNewVOP(value)
                editVOP(value)
              }}
              value={newVOP}
              options={async (value: any) => {
                return await lookupVendorIdDept(
                  selectedVendor.id + '-' + itemDetails.department_id,
                )
              }}
            />
          </Grid.Item>
        </Grid.Container>
      )}
    </>
  )
}
