import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useGrid } from '../../hooks/useGrid'
import { getSearchResultsColumDefs } from './ColumnDefintions'
import { ItemSearchResultType } from '../../types/ItemSearchResultType'
import FactorialGrid from '../FactorialGrid/FactorialGrid'
import { gridOptions } from './GridOptions'
import { useUserInfo } from '../../context/UserInfoContext'
import { UserType } from '../../utils/Constants'
import { SearchCriteriaType } from '../../types/SearchCriteriaType'
import ClearAllFilters from '../FactorialGrid/ClearAllFilters'
import { Export } from '../Export/Export'
import { exportItemFactories } from '../../services/ItemService'
import FactorialSpotlight from '../Tour/FactorialSpotlight'
import { SpotlightIds } from '../Tour/SpotlightIds'
import { Button, Dialog, Input } from '@enterprise-ui/canvas-ui-react'
import { captureSearchResultsExportEvent } from '../../services/analytics/events-service'
import { faUserEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EditFactoriesButton } from './EditFactories'
import { useNavigate } from 'react-router-dom'
import { checkDept } from '../../utils/CheckDept'
import { distinctStr } from '../../utils/UniqueString'
import BulkEditVOPModal from '../EditBulkVOP/BulkEditVOPModal'

interface SearchResultsGridProps {
  searchResultsData: ItemSearchResultType[]
  searchCriteria?: SearchCriteriaType
}

const SearchResultsGrid: FunctionComponent<SearchResultsGridProps> = ({
  searchResultsData,
  searchCriteria,
}) => {
  const navigate = useNavigate()
  const [isBulkEditVOPModalOpen, setIsBulkEditVOPModalOpen] =
    useState<boolean>(false)
  const [bulkEditVOPModalKey, setBulkEditVOPModalKey] = useState<number>()
  const { gridReady, onGridReady, getGridApi } = useGrid()
  const { userInfo } = useUserInfo()
  const [selectedTcins, setSelectedTcins] = useState<string[]>([])
  const [selectedDepts, setSelectedDepts] = useState<string[]>([])
  const [deptDialog, setDeptDialog] = useState<boolean>(false)
  const [disableClearFilter, setDisableClearFilter] = useState(true)

  const [showEditFactoriesButton, setEditFactoriesButton] =
    useState<boolean>(false)
  const [showEditVopButton, setEditVopButton] = useState<boolean>(false)
  const searchResultsColDefs = useMemo(
    () => getSearchResultsColumDefs(userInfo.user_type === UserType.INTERNAL),
    [userInfo.user_type],
  )
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(100)

  useEffect(() => {
    if (!isBulkEditVOPModalOpen) {
      setBulkEditVOPModalKey(Date.now())
      setEditVopButton(false)
      setEditFactoriesButton(false)
    }
  }, [isBulkEditVOPModalOpen, selectedTcins.length])

  useEffect(() => {
    if (selectedTcins.length === 0) {
      if (showEditFactoriesButton) {
        setEditFactoriesButton(false)
      }
      if (showEditVopButton) {
        setEditVopButton(false)
      }
    }
  }, [selectedTcins.length, showEditFactoriesButton, showEditVopButton])

  const navigateToBulkVOPChangePage = () => {
    if (selectedTcins.length > 1) {
      setIsBulkEditVOPModalOpen(true)
    } else {
      //single tcin selected
      navigate('/item-factory-details?tcin=' + selectedTcins[0])
    }
  }
  const gridWidth = '100%'
  const searchResultsGridOptions = {
    ...gridOptions,
    onGridReady: onGridReady,
    onSelectionChanged: (params: any) => {
      const tcinsSelected = params.api.getSelectedRows().map((row: any) => {
        return row.tcin
      })
      const deptSelected = params.api.getSelectedRows().map((row: any) => {
        return row.department
      })
      setSelectedDepts(deptSelected)
      setSelectedTcins(tcinsSelected)
    },
    onFilterChanged: (filter: any) => {
      Object.keys(filter.api.getFilterModel()).length === 0
        ? setDisableClearFilter(true)
        : setDisableClearFilter(false)
    },
    onPaginationChanged: (event: any) => {
      if (getGridApi()) {
        for (
          let index = currentPage * pageSize;
          index < currentPage * pageSize + pageSize;
          index++
        ) {
          const node = getGridApi().getRowNode(index)
          node && node.setSelected(false)
        }
        setCurrentPage(getGridApi().paginationGetCurrentPage())
        setPageSize(getGridApi().paginationGetPageSize())
      }
      setIsSelectAll(false)
    },
  }

  useEffect(() => {
    if (gridReady) {
      getGridApi().setColumnDefs(searchResultsColDefs)
    }
  }, [gridReady, getGridApi, searchResultsColDefs])

  useEffect(() => {
    if (gridReady) {
      getGridApi().setRowData(Object.values(searchResultsData))
    }
  }, [gridReady, searchResultsData, getGridApi])

  useEffect(() => {
    if (gridReady) {
      for (
        let index = currentPage * pageSize;
        index < currentPage * pageSize + pageSize;
        index++
      ) {
        const node = getGridApi().getRowNode(index)
        node && node.setSelected(isSelectAll)
      }
    }
  }, [isSelectAll, currentPage, getGridApi, gridReady, pageSize])

  return (
    <div
      style={{
        padding: '1px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#efefef',
        marginTop: '20px',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '300px' }}>
          {userInfo.user_type === UserType.EXTERNAL && (
            <Input.Checkbox
              size="dense"
              id="select-all"
              label="Select All Rows in this page"
              checked={isSelectAll}
              onChange={(event: any) => {
                setIsSelectAll(event.target.checked)
              }}
            />
          )}
        </div>

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {!showEditFactoriesButton &&
            !showEditVopButton &&
            selectedTcins &&
            selectedTcins.length > 1 && (
              <>
                <Button
                  onClick={() => {
                    setEditFactoriesButton(true)
                    navigateToBulkVOPChangePage()
                  }}
                >
                  <FontAwesomeIcon
                    className="hc-mr-dense"
                    icon={faUserEdit}
                    size="lg"
                  />
                  Edit Factories
                </Button>

                <Button
                  onClick={() => {
                    setEditVopButton(true)
                    if (!checkDept(selectedDepts)) {
                      setDeptDialog(true)
                    } else {
                      navigateToBulkVOPChangePage()
                    }
                  }}
                >
                  <FontAwesomeIcon
                    className="hc-mr-dense"
                    icon={faUserEdit}
                    size="lg"
                  />
                  Edit VOP
                </Button>
              </>
            )}
          {selectedTcins &&
            showEditFactoriesButton &&
            !showEditVopButton &&
            selectedTcins.length > 1 && (
              <>
                <EditFactoriesButton
                  showEditFactoriesButton={showEditFactoriesButton}
                  selectedTcins={selectedTcins}
                  selectedDepts={selectedDepts}
                ></EditFactoriesButton>
              </>
            )}
          {selectedTcins && showEditVopButton && selectedTcins.length > 1 && (
            <>
              <BulkEditVOPModal
                isOpen={isBulkEditVOPModalOpen}
                onClose={() => setIsBulkEditVOPModalOpen(false)}
                tcins={selectedTcins}
                dept={selectedDepts[0]}
                key={bulkEditVOPModalKey}
              ></BulkEditVOPModal>
            </>
          )}
          <div>
            <ClearAllFilters
              gridApi={getGridApi}
              disable={disableClearFilter}
            />
            <FactorialSpotlight
              spotlightId={SpotlightIds.ClearAllFilters}
              contentWidth="sm"
              contentLocation="bottom-left"
            />
          </div>
          <div>
            <Export
              onClick={() => {
                captureSearchResultsExportEvent(searchResultsData.length)
                return searchCriteria
                  ? exportItemFactories(searchCriteria)
                  : Promise.resolve(1)
              }}
            />
          </div>
        </div>
      </div>
      <FactorialSpotlight
        spotlightId={SpotlightIds.TcinClickForFactoryDetails}
        contentWidth="lg"
        contentLocation="bottom-left"
        key="tour_tcin_click"
      />
      <FactorialSpotlight
        spotlightId={SpotlightIds.AgGridFilters}
        contentWidth="lg"
        contentLocation="bottom-right"
        key="tour_filters"
      />
      <FactorialGrid
        gridOptions={searchResultsGridOptions}
        gridWidth={gridWidth}
      />
      <Dialog
        headingText={
          'Selected DPCIs/TCINs are from multiple departments [ ' +
          '\n' +
          distinctStr(selectedDepts) +
          ' ' +
          ' ] .Please only select items from the same department to make a VOP change'
        }
        approveButtonText="OK"
        onApprove={() => {
          setDeptDialog(false)
        }}
        isVisible={deptDialog}
      />
    </div>
  )
}
export default SearchResultsGrid
