import React, {
  useState,
  useEffect,
  FunctionComponent,
  useCallback,
} from 'react'
import { ProgressBar, Grid } from '@enterprise-ui/canvas-ui-react'
import { getChangeRequests } from '../../services/ChangeRequestService'
import { ChangeRequestType } from '../../types/ChangeRequestType'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  getUrlParamsForAllChangeRequests,
  createUrlQueryForAllChangeRequests,
} from '../../utils/UrlParams'
import {
  ChangeRequestQueryType,
  ReviewStatusType,
} from '../../types/ChangeRequestQueryType'
import { VendorActions } from '../../components/AllChangeRequests/Actions/Actions'
import AllChangeRequestsFilters from '../../components/AllChangeRequests/Filters/Filters'
import AllChangeRequestsGrid from '../../components/AllChangeRequests/Grid/AllChangeRequestsGrid'
import { useUserInfo } from '../../context/UserInfoContext'
import { useReload } from '../../hooks/useForceReload'

const AllChangeRequestsExternal: FunctionComponent = () => {
  const [fetchInProgress, setFetchInProgress] = useState<boolean>(false)
  const [pendingApprovals, setPendingApprovals] = useState<ChangeRequestType[]>(
    [],
  )
  const [selectedChangeRequests, setSelectedChangeRequests] = useState<
    ChangeRequestType[]
  >([])
  const [navigateTo, setNavigateTo] = useState({
    pathname: '/all-change-requests',
    search: '',
  })
  const { userInfo } = useUserInfo()
  const location = useLocation()
  const navigate = useNavigate()

  const { pendingOnly } = getUrlParamsForAllChangeRequests(location.search)
  const [reload, forceReload] = useReload()

  const onFilterUpdate = useCallback((_pendingOnly: boolean): void => {
    const searchQueryPath = createUrlQueryForAllChangeRequests(
      true,
      _pendingOnly,
    )
    setNavigateTo({
      pathname: '/all-change-requests',
      search: searchQueryPath,
    })
  }, [])

  useEffect(() => {
    navigate(navigateTo)
  }, [navigate, navigateTo])

  useEffect(() => {
    if (location.pathname === '/all-change-requests') {
      setSelectedChangeRequests([])
      const changeRequestsQuery: ChangeRequestQueryType = {}
      changeRequestsQuery.external_contacts = [userInfo.email_id]
      if (pendingOnly) {
        changeRequestsQuery.review_status = [ReviewStatusType.Initiated]
      }

      setFetchInProgress(true)
      getChangeRequests(changeRequestsQuery)
        .then((changeRequests: ChangeRequestType[]) => {
          setPendingApprovals(changeRequests)
          setFetchInProgress(false)
        })
        .catch(() => {
          setFetchInProgress(false)
        })
    }
  }, [
    location.search,
    location.pathname,
    userInfo.email_id,
    pendingOnly,
    reload,
  ])

  return (
    <>
      {fetchInProgress && <ProgressBar indeterminate />}
      <Grid.Container justify="space-between" align="flex-end">
        <Grid.Item className="hc-pt-dense hc-pb-dense">
          <VendorActions
            selectedChangeRequests={selectedChangeRequests}
            onActionCompleted={forceReload}
          />
        </Grid.Item>
        <Grid.Item className="hc-pt-dense hc-pb-dense">
          <AllChangeRequestsFilters
            pendingOnly={pendingOnly}
            onFilterUpdate={onFilterUpdate}
          />
        </Grid.Item>
        <Grid.Item xs={12} className="hc-pt-none">
          <AllChangeRequestsGrid
            pendingOnly={pendingOnly}
            pendingApprovalsData={pendingApprovals}
            setSelectedChangeRequests={setSelectedChangeRequests}
          />
        </Grid.Item>
      </Grid.Container>
    </>
  )
}

export default AllChangeRequestsExternal
