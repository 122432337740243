import React from 'react'
import { Grid, Card } from '@enterprise-ui/canvas-ui-react'
import { ItemDetailsType } from '../../types/ItemDetailsType'
import FactoryDetailsCard from './FactoryDetailsCard'
import { AutoCompleteOptionType } from '../../types/AutoCompleteOptionType'
import { ChangeRequestType } from '../../types/ChangeRequestType'

const getFactoryTimelinesForVendor = (
  itemDetails: ItemDetailsType,
  vendorId: string,
) => {
  const vendorFactoryTimelines = itemDetails.vendor_factory_timelines.filter(
    (factoryTimelineItem) => {
      return factoryTimelineItem.vendor_id === vendorId
    },
  )
  return {
    factoryTimelineItems: vendorFactoryTimelines[0]
      ? vendorFactoryTimelines[0].factory_timelines
      : [],
  }
}

interface FactoryDetailsCardsProps {
  selectedVendors: AutoCompleteOptionType[]
  itemDetails: ItemDetailsType
  onFactoryAction: () => void
}

export const FactoryDetailsCards: React.FC<FactoryDetailsCardsProps> = ({
  selectedVendors,
  itemDetails,
  onFactoryAction,
}) => {
  return (
    <Grid.Container>
      {selectedVendors.length > 0 ? (
        selectedVendors.map((selectedVendor: AutoCompleteOptionType) => {
          return (
            <Grid.Item xs={12} key={selectedVendor.id}>
              <Card elevation={1}>
                <FactoryDetailsCard
                  factoryTimeline={getFactoryTimelinesForVendor(
                    itemDetails,
                    selectedVendor.id,
                  )}
                  changeRequest={itemDetails.change_requests?.filter(
                    (changeReq: ChangeRequestType) => {
                      return changeReq.gms_vendor_number === selectedVendor.id
                    },
                  )}
                  selectedVendor={selectedVendor}
                  itemDetails={itemDetails}
                  onFactoryAction={onFactoryAction}
                />
              </Card>
            </Grid.Item>
          )
        })
      ) : (
        <Grid.Item xs={12}>
          <div>No vendor(s) selected.</div>
        </Grid.Item>
      )}
    </Grid.Container>
  )
}
