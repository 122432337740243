import React, { ReactNode } from 'react'
import { useState } from 'react'

const ItemDetailsModalContext = React.createContext<any>(null)

interface Props {
  children: ReactNode
}

const ItemDetailsModalContextProvider = ({ children }: Props) => {
  const [showItemDetailsModal, setShowItemDetailsModal] = useState(false)
  const [selectedTcin, setSelectedTcin] = useState('')

  return (
    <ItemDetailsModalContext.Provider
      value={{
        showItemDetailsModal,
        setShowItemDetailsModal,
        selectedTcin,
        setSelectedTcin,
      }}
    >
      {children}
    </ItemDetailsModalContext.Provider>
  )
}

const useItemDetailsModal = (): {
  showItemDetailsModal: boolean
  setShowItemDetailsModal: (showItemDetailsModal: boolean) => void
  selectedTcin: string
  setSelectedTcin: (selectedTcin: string) => void
} => {
  return React.useContext(ItemDetailsModalContext)
}

export { ItemDetailsModalContextProvider, useItemDetailsModal }
