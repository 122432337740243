export const vopGridOptions = {
  defaultColDef: {
    filter: 'agMultiColumnFilter',
    menuTabs: ['filterMenuTab'],
    resizable: true,
    editable: false,
    sortable: true,
  },
  pagination: false,
  headerHeight: 48,
  rowHeight: 28,
  suppressContextMenu: true,
  stopEditingWhenCellsLoseFocus: true,
  enterNavigatesVertically: true,
  undoRedoCellEditing: true,
  rowSelection: 'multiple',
  suppressRowClickSelection: true,
  excelStyles: [
    {
      id: 'header',
      font: {
        bold: true,
      },
    },
  ],
}
