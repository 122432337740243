import React, { useState, useEffect, FunctionComponent } from 'react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { lookupDeptClasses } from '../../services/LookupService'
import { LookupValue } from '../../types/LookupValue'

interface DepartmentClassSearchProps {
  deptClassInitialState?: string
  onDeptClassChange(deptClass?: string): void
}

const DepartmentClassSearch: FunctionComponent<DepartmentClassSearchProps> = ({
  deptClassInitialState,
  onDeptClassChange,
}) => {
  const [deptClass, setDeptClass] = useState<LookupValue>()

  //get deptClass lookupValue based on deptClassInitialState
  useEffect(() => {
    if (deptClassInitialState) {
      lookupDeptClasses(deptClassInitialState).then(
        (lookupValues: LookupValue[]) => {
          lookupValues &&
            lookupValues.length > 0 &&
            setDeptClass(lookupValues[0])
        },
      )
    }
  }, [deptClassInitialState])

  useEffect(() => {
    deptClass && deptClass.value
      ? onDeptClassChange(deptClass.value)
      : onDeptClassChange()
  }, [deptClass, onDeptClassChange])

  return (
    <Autocomplete
      label="Department - Class"
      id="departmentId"
      location="left"
      filter={Autocomplete.filters.none}
      onUpdate={(id: any, value: any) => setDeptClass(value)}
      value={deptClass}
      options={async (value: any) => {
        if (!value) return []
        if (deptClass && value === deptClass.label) return [deptClass]
        return await lookupDeptClasses(value)
      }}
    />
  )
}

export default React.memo(DepartmentClassSearch)
