import { ItemSearchResultType } from '../types/ItemSearchResultType'

const shiftTcins = (results: ItemSearchResultType[], tcins: string[]) => {
  if (tcins && results && tcins.length > 0 && results.length > 0) {
    const topTcins = results.filter((r) => tcins.includes(r.tcin + ''))
    results = results.filter((r) => !tcins.includes(r.tcin + ''))
    results.unshift(...topTcins)
  }
  return results
}

export { shiftTcins }
