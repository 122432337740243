import React from 'react'
import { Button } from '@enterprise-ui/canvas-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import { captureButtonClickEvent } from '../../services/analytics/events-service'

interface ReplaceFactoryButtonProps {
  showButton: boolean
  onReplaceFactoryButtonClick: () => void
}

export const ReplaceFactoryButton: React.FC<ReplaceFactoryButtonProps> = ({
  showButton,
  onReplaceFactoryButtonClick,
}) => {
  return (
    <>
      {showButton && (
        <Button
          onClick={() => {
            captureButtonClickEvent('Replace Factory')
            onReplaceFactoryButtonClick()
          }}
        >
          <FontAwesomeIcon
            className="hc-mr-dense"
            icon={faExchangeAlt}
            size="lg"
          />
          Replace Factory
        </Button>
      )}
    </>
  )
}
