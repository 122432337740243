import {
  Button,
  Grid,
  GridContainer,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import React, { useEffect, useState } from 'react'
import EmailField from '../../Common/EmailField'
interface ReassignModalProps {
  showModal: boolean
  existingInternalContacts?: string[]
  setShowModal: (showModal: boolean) => void
  onReassignClicked: (internalContacts: string[]) => void
}
const ReassignModal: React.FC<ReassignModalProps> = ({
  showModal,
  setShowModal,
  onReassignClicked,
  existingInternalContacts = [],
}) => {
  const [internalContacts, setInternalContacts] = useState<string[]>(
    existingInternalContacts,
  )
  const [showErrors, setShowErrors] = useState<boolean>(false)
  const onReassign = () => {
    internalContacts.length === 0
      ? setShowErrors(true)
      : onReassignClicked(internalContacts)
  }
  useEffect(() => {
    showModal && setInternalContacts(existingInternalContacts)
  }, [showModal, setInternalContacts, existingInternalContacts])

  return (
    <Modal
      headingText="Reassign change request"
      isVisible={showModal}
      onRefuse={() => setShowModal(false)}
    >
      <div className="hc-pa-normal">
        <GridContainer>
          <Grid.Item xs>
            <p>
              Provide the email address of Target team member who should be
              approving this Change Request
            </p>
          </Grid.Item>
        </GridContainer>
        <GridContainer direction="row-reverse" spacing="dense">
          <Grid.Item xs={12} className="hc-pt-none hc-pb-none">
            <EmailField
              emails={internalContacts}
              setEmails={setInternalContacts}
              id="internalContacts"
              label="Target Approver Email(s)"
              errorText="Please enter a valid Target E-mail(@target.com) and press enter."
              checkTargetDomain={true}
              showErrorMessage={showErrors}
            />
          </Grid.Item>
          <Grid.Item>
            <Button type="primary" onClick={onReassign}>
              Reassign
            </Button>
          </Grid.Item>
          <Grid.Item>
            <Button
              onClick={() => {
                setShowModal(false)
              }}
            >
              Cancel
            </Button>
          </Grid.Item>
        </GridContainer>
      </div>
    </Modal>
  )
}

export default ReassignModal
