import React, { ReactNode, useCallback } from 'react'
import { useState } from 'react'
import { BusinessPartnerType, Vendor } from '../types/BusinessPartnerType'

const BusinessPartnerInfoContext = React.createContext<any>(null)

interface Props {
  children: ReactNode
}

const BusinessPartnerInfoContextProvider = ({ children }: Props) => {
  const [businessPartner, setBusinessPartner] = useState<BusinessPartnerType>()
  const getVendorByGmsVendorNumber: (
    gmsVendorNumber: string,
  ) => Vendor | undefined = useCallback(
    (gmsVendorNumber) => {
      return businessPartner?.vendors.find((v) => {
        return v.gms_vendor_number === gmsVendorNumber
      })
    },
    [businessPartner],
  )
  return (
    <BusinessPartnerInfoContext.Provider
      value={{
        businessPartner,
        setBusinessPartner,
        getVendorByGmsVendorNumber,
      }}
    >
      {children}
    </BusinessPartnerInfoContext.Provider>
  )
}

const useBusinessPartnerInfo = (): {
  businessPartner: BusinessPartnerType
  setBusinessPartner: (businessPartner: BusinessPartnerType) => void
  getVendorByGmsVendorNumber: (gmsVendorNumber: string) => Vendor | undefined
} => {
  return React.useContext(BusinessPartnerInfoContext)
}

export { BusinessPartnerInfoContextProvider, useBusinessPartnerInfo }
