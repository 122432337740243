import { BulkChangeRequestType } from '../types/BulkChangeRequestType'
import { getBaseUrl } from '../config/AppConfig'
import { AxiosUtil } from '../utils/AxiosUtil'
import { ChangeRequestType } from '../types/ChangeRequestType'
import { ChangeRequestQueryType } from '../types/ChangeRequestQueryType'
import { UpdateChangeRequestQueryType } from '../types/UpdateChangeRequestQueryType'
import { BulkChangeResponseType } from '../types/BulkChangeResponseType'

const submitChangeRequest = async (
  changeRequests: BulkChangeRequestType[],
): Promise<BulkChangeResponseType[]> => {
  const url = getBaseUrl() + '/change_requests'
  return AxiosUtil.post(url, changeRequests)
    .then((response) => response.data)
    .then((data) => {
      return data
    })
}

const getChangeRequests = async (
  changeRequestQuery: ChangeRequestQueryType,
): Promise<ChangeRequestType[]> => {
  const url = getBaseUrl() + '/change_requests/search'
  return AxiosUtil.post(url, changeRequestQuery)
    .then((response) => response.data)
    .then((data) => {
      return data
    })
}

const getChangeRequestCount = async (
  changeRequestCountsQuery: ChangeRequestQueryType,
): Promise<number> => {
  const url = getBaseUrl() + '/change_requests/counts'
  return AxiosUtil.post(url, changeRequestCountsQuery)
    .then((response) => response.data)
    .then((data) => {
      return data
    })
}

const updateChangeRequests = async (
  updateChangeRequestQuery: UpdateChangeRequestQueryType,
): Promise<any> => {
  const url = getBaseUrl() + '/change_requests'
  return AxiosUtil.put(url, updateChangeRequestQuery)
    .then((response) => response.data)
    .then((data) => {
      return data
    })
}

export {
  submitChangeRequest,
  getChangeRequests,
  getChangeRequestCount,
  updateChangeRequests,
}
