import { trackCustomEvent } from '../firefly/firefly-service'
import { googleAnalyticsService } from './google-analytics-service'

export interface EventArgs {
  category: string
  action: string
  label?: string
  value?: number
  nonInteraction?: boolean
}

export interface AgGridEventArgs {
  action: string
  label?: string
  value?: number
  nonInteraction?: boolean
}

export interface TimingArgs {
  category: string
  variable: string
  value: number
  label?: string
}

export interface ExceptionArgs {
  description?: string
  fatal?: boolean
}

export interface FieldsObject {
  [i: string]: any
}

export interface AnalyticsService {
  event: (args: EventArgs) => void
  exception: (args: ExceptionArgs) => void
  pageView: (path: string) => void
  modalView: (name: string) => void
  timing: (args: TimingArgs) => void
}

const event = (args: EventArgs) => {
  googleAnalyticsService.event(args)
  const { category, action, label } = args
  const key = category === 'Button Clicks' ? action : category
  const value = category === 'Button Clicks' ? 'clicked' : `${action} ${label}`
  trackCustomEvent('event', key, value)
}

const exception = (args: ExceptionArgs) => {
  googleAnalyticsService.exception(args)
}

const axiosException = (error: any) => {
  const message: string | null = error?.message ?? error?.data?.message
  exception({
    description: `${error?.response?.status}: ${message}`,
    fatal: false,
  })
}

const pageView = (path: string) => {
  googleAnalyticsService.pageView(path)
}

const pageViewTimings: { [path: string]: number } = {}

const pageNavigationTimerStart = (path: string) => {
  if (pageViewTimings[path]) {
    console.error(`Page Navigation overwrote timing for: ${path}`)
  }
  pageViewTimings[path] = window?.performance?.now()
}

const pageNavigationTimerStop = (path: string) => {
  const now = window?.performance?.now()
  if (pageViewTimings[path] && now) {
    const pageLoadTime = Math.round(now - pageViewTimings[path]!)
    timing({
      category: 'Page Navigation Time',
      variable: 'load',
      value: pageLoadTime,
      label: path,
    })
  }
  delete pageViewTimings[path]
}

const modalView = (name: string) => {
  googleAnalyticsService.modalView(name)
}

const requestTimings: {
  [id: number]: {
    path: string
    startTime: number
  }
} = {}

const requestTimerStart = (path: string): number => {
  const id = window?.performance?.now()
  if (id) {
    requestTimings[id] = {
      path,
      startTime: id,
    }
  }
  return id
}

const requestTimerStop = (id: number) => {
  const now = window?.performance?.now()
  if (requestTimings[id] && now) {
    const requestTotalTime = Math.round(now - requestTimings[id].startTime)
    timing({
      category: 'Request Time',
      variable: 'request',
      value: requestTotalTime,
      label: requestTimings[id].path,
    })
  }
  delete requestTimings[id]
}

const timing = (args: TimingArgs): void => {
  googleAnalyticsService.timing(args)
}

export const analyticsService = {
  event,
  exception,
  axiosException,
  pageView,
  pageNavigationTimerStart,
  pageNavigationTimerStop,
  modalView,
  requestTimerStart,
  requestTimerStop,
  timing,
}
