import React, { ReactNode } from 'react'
import { useState } from 'react'

const FeedbackContext = React.createContext<any>(null)

interface Props {
  children: ReactNode
}

const FeedbackContextProvider = ({ children }: Props) => {
  const [feedbackTitle, setFeedbackTitle] = useState<string>(
    'Send feedback to the Factorial team',
  )
  const [isFeedbackOpen, setIsFeedbackOpen] = useState<boolean>(false)

  return (
    <FeedbackContext.Provider
      value={{
        feedbackTitle,
        setFeedbackTitle,
        isFeedbackOpen,
        setIsFeedbackOpen,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  )
}

const useFeedback = (): {
  feedbackTitle: string
  setFeedbackTitle: (query: string) => void
  isFeedbackOpen: boolean
  setIsFeedbackOpen: (isFeedbackOpen: boolean) => void
} => {
  return React.useContext(FeedbackContext)
}

export { FeedbackContextProvider, useFeedback }
