import React, { useState, useEffect } from 'react'
import {
  Spinner,
  ToastProvider,
  ToastProviderProps,
} from '@enterprise-ui/canvas-ui-react'
import {
  FactoryTimeline,
  ItemDetailsType,
  ItemVendor,
} from '../../types/ItemDetailsType'
import { AutoCompleteOptionType } from '../../types/AutoCompleteOptionType'
import { ChangeRequestType } from '../../types/ChangeRequestType'
import { useUserInfo } from '../../context/UserInfoContext'
import { UserType } from '../../utils/Constants'
import { BusinessPartnerType } from '../../types/BusinessPartnerType'
import { getBusinessPartnerByGmsVendor } from '../../services/BusinessPartnerService'
import { useBusinessPartnerInfo } from '../../context/BusinessPartnerInfoContext'
import { EditFactoriesTimeline } from '../FactoryTimeline/Edit/EditFactoriesTimeline'

import './FactoryDetailsCard.scss'
import FactoryDetailsCardHeader from './FactoryDetailsCardHeader'

const vmmErrorToast: ToastProviderProps = {
  heading: 'VMM service error',
  message: 'Could not get factory locations info',
  type: 'alert',
}

interface FactoryDetailsCardProps {
  factoryTimeline: FactoryTimeline
  changeRequest?: ChangeRequestType[]
  selectedVendor: AutoCompleteOptionType
  itemDetails: ItemDetailsType
  onFactoryAction: () => void
}

const FactoryDetailsCard: React.FC<FactoryDetailsCardProps> = ({
  factoryTimeline,
  changeRequest,
  selectedVendor,
  itemDetails,
  onFactoryAction,
}) => {
  const [selectedBusinessPartner, setSelectedBusinessPartner] =
    useState<BusinessPartnerType>()
  const [isInProgress, setIsInProgress] = useState<boolean>(false)
  const { userInfo } = useUserInfo()
  const makeToast = ToastProvider.useToaster()
  const { businessPartner } = useBusinessPartnerInfo()
  const itemVendor = itemDetails.vendors.filter((v: ItemVendor) => {
    return v.id === selectedVendor.id
  })
  const mfgStyle = itemVendor[0].manufacturer_style

  useEffect(() => {
    if (userInfo.user_type === UserType.EXTERNAL) {
      setSelectedBusinessPartner(businessPartner)
    }
  }, [setSelectedBusinessPartner, businessPartner, userInfo])

  useEffect(() => {
    if (!selectedBusinessPartner && userInfo.user_type === UserType.INTERNAL) {
      setIsInProgress(true)
      getBusinessPartnerByGmsVendor(selectedVendor.id)
        .then((res) => {
          setIsInProgress(false)
          setSelectedBusinessPartner(res)
        })
        .catch(() => {
          setIsInProgress(false)
          makeToast(vmmErrorToast)
        })
    }
  }, [
    selectedVendor,
    setIsInProgress,
    makeToast,
    setSelectedBusinessPartner,
    selectedBusinessPartner,
    userInfo,
  ])

  return (
    <>
      <FactoryDetailsCardHeader
        vendorName={selectedVendor.label}
        bpId={selectedBusinessPartner?.bp_id ?? ''}
        mfgStyle={mfgStyle}
      />

      <EditFactoriesTimeline
        factoryTimeline={factoryTimeline}
        locations={selectedBusinessPartner?.locations}
        selectedVendor={selectedVendor}
        selectedBusinessPartner={selectedBusinessPartner}
        changeRequest={changeRequest}
        itemDetails={itemDetails}
        onFactoryAction={onFactoryAction}
      />

      {isInProgress && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Spinner size="dense" />
        </div>
      )}
    </>
  )
}

export default FactoryDetailsCard
