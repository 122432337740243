import React, { useState, useEffect, useCallback } from 'react'
import { Form } from '@enterprise-ui/canvas-ui-react'

interface ManufacturerStyleSearchProps {
  manufacturerStyleInitialState?: string[]
  onManufacturerStyleChange(manufacturerStyles: string[]): void
  handleKeyPress: (e: React.KeyboardEvent) => void
}
const ManufacturerStyleSearch: React.FC<ManufacturerStyleSearchProps> = ({
  manufacturerStyleInitialState,
  onManufacturerStyleChange,
  handleKeyPress,
}) => {
  const [manufacturerStyles, setManufacturerStyles] = useState<string[]>([])

  const onInputChange = useCallback(
    (value: string) => {
      const inputValue = value
      if (inputValue.length > 0) {
        const manufStyles: string[] = []
        inputValue.split(/[ ,]+/).forEach((val) => {
          manufStyles.push(val)
        })
        setManufacturerStyles(manufStyles)
        onManufacturerStyleChange(manufStyles)
      } else {
        setManufacturerStyles([])
        onManufacturerStyleChange([])
      }
    },
    [setManufacturerStyles, onManufacturerStyleChange],
  )

  useEffect(() => {
    if (
      manufacturerStyleInitialState &&
      manufacturerStyleInitialState.length > 0
    ) {
      setManufacturerStyles([...manufacturerStyleInitialState])
      onInputChange(manufacturerStyleInitialState.join())
    }
  }, [manufacturerStyleInitialState, setManufacturerStyles, onInputChange])
  return (
    <Form.Field
      label="Mfg Style#"
      type="text"
      id={'mfg-style'}
      onChange={(event: any) => onInputChange(event.target.value)}
      value={manufacturerStyles}
      onKeyPress={handleKeyPress}
    />
  )
}

export default ManufacturerStyleSearch
