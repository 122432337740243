import { useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { analyticsService } from '../services/analytics/analytics-service'
import { usePageInfo } from '../context/PageInfoContext'
import { useHelp } from '../context/HelpContext'
import FactorialSpotlight from '../components/Tour/FactorialSpotlight'
import { SpotlightIds } from '../components/Tour/SpotlightIds'

const FactorialView = (props: any) => {
  const { setPageTitle } = usePageInfo()
  const location = useLocation()

  // set help context
  const { setLabel } = useHelp()
  const setHelpContext = useCallback(
    (view: string) => {
      switch (view) {
        case 'Search': {
          setLabel('search')
          break
        }
        case 'Change Requests': {
          setLabel('change-requests')
          break
        }
        case 'Item Factory Details': {
          setLabel('item-factory-details')
          break
        }
        default: {
          setLabel('search')
          break
        }
      }
    },
    [setLabel],
  )

  useEffect(() => {
    setPageTitle(props.view)
    setHelpContext(props.view)
  }, [props.view, setPageTitle, setHelpContext])

  useEffect(() => {
    return analyticsService.pageView(location.pathname)
  }, [location])

  return (
    <>
      {[
        SpotlightIds.NavigateToSearch,
        SpotlightIds.NavigateToChangeRequests,
        SpotlightIds.NavigateToItemFactoryDetails,
      ].map((spotlightId) => {
        return (
          <FactorialSpotlight
            spotlightId={spotlightId}
            contentWidth="sm"
            contentLocation="bottom-right"
            key={spotlightId}
          />
        )
      })}
      {props.children()}
    </>
  )
}

export default FactorialView
