const dateFormatter = (params: any) => {
  return params.data
    ? new Date(params.data.created_on).toLocaleDateString()
    : ''
}

const filterParams = {
  comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
    let cellDate = new Date(cellValue)
    cellDate = new Date(
      cellDate.getFullYear(),
      cellDate.getMonth(),
      cellDate.getDate(),
      0,
      0,
      0,
    )
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1
    }
  },
  browserDatePicker: true,
}

export const AllChangeRequestsColumDefs = (isPendingOnly: boolean) => {
  const columnDefs: any[] = [
    {
      headerName: 'DPCI',
      field: 'dpci',
      flex: 1,
    },
    {
      headerName: 'Dept',
      valueGetter: function (params: any) {
        return (
          params.data.item_details.department_id +
          ' - ' +
          params.data.item_details.department_name
        )
      },
      flex: 1,
    },
    {
      headerName: 'Class',
      valueGetter: function (params: any) {
        return (
          params.data.item_details.class_id +
          ' - ' +
          params.data.item_details.class_name
        )
      },
      flex: 1,
    },
    {
      headerName: 'Description',
      field: 'item_details.description',
      flex: 1,
    },
    {
      headerName: 'Vendor Id',
      field: 'vendor_id',
      flex: 1,
    },
    {
      headerName: 'Business Partner',
      field: 'bp_name',
      flex: 1,
    },
    {
      headerName: 'Spark PID',
      field: 'item_details.pids',
      flex: 1,
    },
    {
      headerName: 'Reason For Change',
      field: 'reason_for_change',
      flex: 1,
    },
    {
      headerName: 'Change Request Comments',
      field: 'reason_for_change_description',
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
    {
      headerName: 'Status',
      field: 'review_status',
      flex: 1,
    },
    {
      headerName: 'Requested on',
      field: 'created_on',
      flex: 1,
      sort: 'desc',
      filter: 'agDateColumnFilter',
      valueFormatter: dateFormatter,
      filterParams: filterParams,
    },
    {
      headerName: 'Updated on',
      field: 'updated_on',
      flex: 1,
      sort: 'desc',
      filter: 'agDateColumnFilter',
      valueFormatter: dateFormatter,
      filterParams: filterParams,
    },
    {
      headerName: 'Cycle',
      field: 'cycle_description',
      valueFormatter: (params: any) => params.value.join(', '),
      hide: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
    {
      headerName: 'Requested By',
      field: 'change_request_initiator.user_name',
      flex: 1,
    },
    {
      headerName: 'Assigned To',
      field: 'internal_contacts',
      valueFormatter: (params: any) => params.value.join(', '),
      flex: 1,
    },
  ]

  if (isPendingOnly) {
    columnDefs.unshift({
      headerName: 'TCIN',
      field: 'tcin',
      flex: 1,
      cellRenderer: 'itemFactoryDetailsButton',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      cellRendererParams: (params: any) => {
        return { tcin: params.data.tcin }
      },
    })
  } else {
    columnDefs.unshift({
      headerName: 'TCIN',
      field: 'tcin',
      flex: 1,
      cellRenderer: 'itemFactoryDetailsButton',
      cellRendererParams: (params: any) => {
        return { tcin: params.data.tcin }
      },
    })
  }
  return columnDefs
}
