import { Modal } from '@enterprise-ui/canvas-ui-react'
import React from 'react'
import { ChangeRequestType } from '../../types/ChangeRequestType'
import ChangeHistoryCards from './ChangeHistoryCards'

interface ChangeHistoryModalProps {
  isOpen: boolean
  onClose: () => void
  changeRequests: ChangeRequestType[]
}

const ChangeHistoryModal: React.FC<ChangeHistoryModalProps> = ({
  isOpen,
  onClose,
  changeRequests,
}) => {
  return (
    <Modal
      headingText={`Change History`}
      isVisible={isOpen}
      onRefuse={() => {
        onClose()
      }}
    >
      <div className="hc-pa-normal">
        {changeRequests.length > 0 && (
          <>
            <h3>{`TCIN: ${changeRequests[0].tcin}  BP: ${changeRequests[0].bp_id} Vendor Id: ${changeRequests[0].gms_vendor_number}`}</h3>
            <ChangeHistoryCards changeRequests={changeRequests} />
          </>
        )}
      </div>
    </Modal>
  )
}

export default ChangeHistoryModal
