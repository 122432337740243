import React, { useEffect, useState } from 'react'
import {
  Button,
  Grid,
  Modal,
  ProgressBar,
  Stepper,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { faCopy } from '@fortawesome/free-regular-svg-icons/faCopy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFeedback } from '../../context/FeedbackContext'
import { useLocalStorage } from '../../context/LocalStorageContext'
import { getItemDetails } from '../../services/ItemService'
import { AutoCompleteOptionType } from '../../types/AutoCompleteOptionType'
import { BulkChangeResponseType } from '../../types/BulkChangeResponseType'
import { ItemDetailsType } from '../../types/ItemDetailsType'
import { ItemVendorFactoryTimeline } from '../../types/ItemVendorFactoryType'
import { getDefaultEndDate } from '../../utils/Constants'
import { Actions } from './Actions'
import './BulkChangeRequestModal.scss'
import {
  filterItemsWithPendingCRs,
  groupItemsByVendor,
} from './BulkChangeRequestUtil'
import BulkChangeResponse from './BulkChangeResponse'
import ItemsDroppedWarning from './ItemsDroppedWarning'
import ReviewTimelines from './ReviewTimelines'
import SelectFactories from './SelectFactories'
import SubmitChangeRequestForm from './SubmitChangeRequestForm'

interface BulkChangeRequestModalProps {
  isOpen: boolean
  onClose: () => void
  tcins: string[]
  dept: string
  action: Actions
}

const BulkChangeRequestModal: React.FC<BulkChangeRequestModalProps> = ({
  isOpen,
  onClose,
  tcins,
  dept,
  action,
}) => {
  const [activeStep, setActiveStep] = useState<String>('factory')
  const [isFetchingItemDetails, setIsFetchingItemDetails] =
    useState<boolean>(false)
  const [itemsByVendor, setItemsByVendor] = useState<{
    [key: string]: ItemDetailsType[]
  }>({})
  const [newFactoryLocationOption, setNewFactoryLocationOption] = useState<{
    [key: string]: AutoCompleteOptionType
  }>({})
  const [removedFactoryLocationOption, setRemovedFactoryLocationOption] =
    useState<{
      [key: string]: AutoCompleteOptionType
    }>({})
  const [newVOPOption, setNewVOPOption] = useState<{
    [key: string]: AutoCompleteOptionType
  }>({})

  const [newFactoryEffectiveStartDate, setNewFactoryEffectiveStartDate] =
    useState<Date>()
  const [newFactoryEffectiveEndDate, setNewFactoryEffectiveEndDate] =
    useState<Date>(getDefaultEndDate())
  const [tcinsWithCRs, setTcinsWithCRs] = useState<string[]>()
  const [tcinsWithoutCRs, setTcinsWithoutCRs] = useState<string[]>()

  const [itemVendorFactoryTimelines, setItemVendorFactoryTimelines] = useState<
    ItemVendorFactoryTimeline[]
  >([])
  const [
    unchangedItemVendorFactoryTimelines,
    setUnchangedItemItemVendorFactoryTimelines,
  ] = useState<ItemVendorFactoryTimeline[]>([])
  const [
    invalidItemVendorFactoryTimelines,
    setInvalidItemVendorFactoryTimelines,
  ] = useState<ItemVendorFactoryTimeline[]>([])

  const [bulkChangeResponse, setBulkChangeResponse] = useState<
    BulkChangeResponseType[]
  >([])

  const { setIsFeedbackOpen, setFeedbackTitle } = useFeedback()
  const { bulkEditFeedbackSubmittedAt, setBulkEditFeedbackSubmittedAt } =
    useLocalStorage()

  const [continueEditing, setContinueEditing] = useState<boolean>(true)

  useEffect(() => {
    if (tcins && tcins.length > 0 && isOpen) {
      setIsFetchingItemDetails(true)
      getItemDetails({ tcins: tcins })
        .then((itemDetailsRes: ItemDetailsType[]) => {
          setIsFetchingItemDetails(false)
          if (itemDetailsRes && itemDetailsRes.length > 0) {
            const { tcinsWithPendingCRs, itemsWithoutCRs } =
              filterItemsWithPendingCRs(itemDetailsRes)
            setTcinsWithCRs(tcinsWithPendingCRs)
            setTcinsWithoutCRs(itemsWithoutCRs.map((it) => it.tcin))
            setItemsByVendor(groupItemsByVendor(itemsWithoutCRs))
            if (tcinsWithPendingCRs && tcinsWithPendingCRs.length > 0) {
              setContinueEditing(false)
            }
          }
        })
        .catch(() => {
          setIsFetchingItemDetails(false)
        })
    }
  }, [tcins, isOpen])

  useEffect(() => {
    if (activeStep === 'selectAction') {
      setNewFactoryLocationOption({})
      setRemovedFactoryLocationOption({})
    }
  }, [activeStep])

  const showReview = (
    factoryEffectiveStartDate: Date,
    factoryEffectiveEndDate: Date,
    newfactoryLocationOption: { [key: string]: AutoCompleteOptionType },
    newVOPOption: { [key: string]: AutoCompleteOptionType },
    removedfactoryLocationOption: { [key: string]: AutoCompleteOptionType },
  ) => {
    setNewFactoryEffectiveEndDate(factoryEffectiveEndDate)
    setNewFactoryEffectiveStartDate(factoryEffectiveStartDate)
    setNewFactoryLocationOption(newfactoryLocationOption)
    setNewVOPOption(newVOPOption)
    setRemovedFactoryLocationOption(removedfactoryLocationOption)
    setActiveStep('review')
  }

  const promptForFeedback = () => {
    const fortyDaysBackwardFromToday = new Date(new Date().getDate() - 40)
    return (
      bulkEditFeedbackSubmittedAt &&
      bulkEditFeedbackSubmittedAt < fortyDaysBackwardFromToday &&
      bulkChangeResponse.length > 0
    )
  }

  const onModalClose = () => {
    if (promptForFeedback()) {
      setFeedbackTitle(
        'Please provide your feedback for Bulk Update functionality',
      )
      setIsFeedbackOpen(true)
      setBulkEditFeedbackSubmittedAt(new Date())
    }

    onClose()
  }
  return (
    <Modal
      headingText={`Bulk Change Request - ${action}`}
      isVisible={isOpen}
      onRefuse={(e: any) => {
        if (e.target.nodeName !== 'DIV') {
          onModalClose()
        }
      }}
      className="bulk-change-request-modal"
    >
      <div className="hc-pa-normal">
        {isFetchingItemDetails && <ProgressBar indeterminate />}
        {tcinsWithCRs && tcinsWithCRs.length > 0 && !continueEditing && (
          <ItemsDroppedWarning
            tcinsWithCRs={tcinsWithCRs}
            tcinsWithoutCRs={tcinsWithoutCRs || []}
            onCancel={onModalClose}
            onContinue={() => setContinueEditing(true)}
          />
        )}

        {continueEditing && (
          <Grid.Container justify="center">
            <Grid.Item>
              <div style={{ overflowY: 'auto' }}>
                {bulkChangeResponse.length === 0 && (
                  <div>
                    <Stepper activeStep="{activeStep}">
                      <Stepper.Item index={1} name="factory" completed>
                        Choose Factory
                      </Stepper.Item>
                      <Stepper.Item index={2} name="review" completed>
                        Review
                      </Stepper.Item>
                      <Stepper.Item index={3} name="submit" completed>
                        Submit
                      </Stepper.Item>
                    </Stepper>
                    <div className="hc-pa-normal">
                      <p className="hc-ma-normal">
                        You are editing {tcinsWithoutCRs?.length} items
                        <span>
                          <Tooltip
                            location="top"
                            content="Copy tcins to clipboard"
                          >
                            <Button
                              size="dense"
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  tcinsWithoutCRs?.join() ?? '',
                                )
                              }
                            >
                              <FontAwesomeIcon
                                className="hc-mr-dense"
                                icon={faCopy}
                                size="lg"
                              />
                            </Button>
                          </Tooltip>
                        </span>
                      </p>
                      {activeStep === 'factory' && (
                        <SelectFactories
                          onNext={showReview}
                          onPrev={() => setActiveStep('selectAction')}
                          action={action}
                          factoryEffectiveStartDate={
                            newFactoryEffectiveStartDate
                          }
                          factoryEffectiveEndDate={newFactoryEffectiveEndDate}
                          newFactoryLocation={newFactoryLocationOption}
                          removedFactoryLocation={removedFactoryLocationOption}
                          newVOP={newVOPOption}
                          vendorIds={Object.keys(itemsByVendor)}
                          dept={dept}
                          itemsByVendor={itemsByVendor}
                        />
                      )}
                      {activeStep === 'review' && (
                        <ReviewTimelines
                          factoryEffectiveStartDate={
                            newFactoryEffectiveStartDate
                          }
                          factoryEffectiveEndDate={newFactoryEffectiveEndDate}
                          newFactoryLocationOption={newFactoryLocationOption}
                          removedFactoryLocationOption={
                            removedFactoryLocationOption
                          }
                          newVOPOption={newVOPOption}
                          itemsByVendor={itemsByVendor}
                          onNext={(
                            itemVendorFactoryTimelinesInput,
                            unchangedItemVendorFactoryTimelinesInput,
                            invalidItemVendorFactoryTimelinesInput,
                          ) => {
                            setItemVendorFactoryTimelines(
                              itemVendorFactoryTimelinesInput,
                            )
                            setUnchangedItemItemVendorFactoryTimelines(
                              unchangedItemVendorFactoryTimelinesInput,
                            )
                            setInvalidItemVendorFactoryTimelines(
                              invalidItemVendorFactoryTimelinesInput,
                            )
                            setActiveStep('submit')
                          }}
                          onPrev={() => {
                            setActiveStep('factory')
                          }}
                        />
                      )}
                      {activeStep === 'submit' && (
                        <SubmitChangeRequestForm
                          itemVendorFactoryTimelines={
                            itemVendorFactoryTimelines
                          }
                          onSubmit={(
                            bulkChangeResp: BulkChangeResponseType[],
                          ) => setBulkChangeResponse(bulkChangeResp)}
                          onPrev={() => {
                            setActiveStep('review')
                          }}
                        />
                      )}
                    </div>
                  </div>
                )}
                {bulkChangeResponse.length > 0 && (
                  <BulkChangeResponse
                    bulkChangeResponse={bulkChangeResponse}
                    invalidItemVendorFactoryTimelines={
                      invalidItemVendorFactoryTimelines
                    }
                    unchangedItemVendorFactoryTimelines={
                      unchangedItemVendorFactoryTimelines
                    }
                    onExit={() => {
                      onModalClose()
                    }}
                  />
                )}
              </div>
            </Grid.Item>
          </Grid.Container>
        )}
      </div>
    </Modal>
  )
}

export default BulkChangeRequestModal
