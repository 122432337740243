import React, {
  useState,
  FunctionComponent,
  useEffect,
  useCallback,
} from 'react'
import { Form } from '@enterprise-ui/canvas-ui-react'
import FactorialSpotlight from '../Tour/FactorialSpotlight'
import { SpotlightIds } from '../Tour/SpotlightIds'

export interface TcinSearchProps {
  tcinsInitialState?: string[]
  dpcisInitialState?: string[]
  onTcinChange(tcins: string[]): void
  onDpciChange(dpcis: string[]): void
  handleKeyPress: (e: React.KeyboardEvent) => void
}

const TcinSearch: FunctionComponent<TcinSearchProps> = ({
  tcinsInitialState,
  dpcisInitialState,
  onTcinChange,
  onDpciChange,
  handleKeyPress,
}) => {
  const [inputTcinsDpcis, setInputTcinsDpcis] = useState<string[]>([])

  const onInputChange = useCallback(
    (value: string) => {
      const inputValue = value
      if (inputValue.length > 0) {
        const tcins: string[] = []
        const dpcis: string[] = []
        const tcinsDpcis: string[] = []
        inputValue.split(/[ ,]+/).forEach((val) => {
          val.match(/[-]/) ? dpcis.push(val) : tcins.push(val)
          tcinsDpcis.push(val)
        })
        onTcinChange(tcins)
        onDpciChange(dpcis)
        setInputTcinsDpcis(tcinsDpcis)
      } else {
        onTcinChange([])
        onDpciChange([])
        setInputTcinsDpcis([])
      }
    },
    [onTcinChange, onDpciChange],
  )

  useEffect(() => {
    let tcinsDpcisParams: string[] = []
    if (tcinsInitialState && tcinsInitialState.length > 0) {
      tcinsDpcisParams = [...tcinsInitialState]
    }
    if (dpcisInitialState && dpcisInitialState.length > 0) {
      tcinsDpcisParams =
        tcinsDpcisParams && tcinsDpcisParams.length > 0
          ? [...tcinsDpcisParams, ...dpcisInitialState]
          : [...dpcisInitialState]
    }
    setInputTcinsDpcis(tcinsDpcisParams)
    onInputChange(tcinsDpcisParams.join())
  }, [tcinsInitialState, dpcisInitialState, setInputTcinsDpcis, onInputChange])

  return (
    <>
      <Form.Field
        data-testid="tcin-dpci"
        label="TCIN / DPCI"
        type="text"
        id={'tcin-dpci'}
        onChange={(event: any) => onInputChange(event.target.value)}
        value={inputTcinsDpcis}
        onKeyPress={handleKeyPress}
      />
      <FactorialSpotlight
        spotlightId={SpotlightIds.TcinSearchBox}
        contentWidth="sm"
        contentLocation="bottom-right"
      />
    </>
  )
}

export default React.memo(TcinSearch)
