import ItemFactoryDetailsButton from '../ItemFactoryDetailsButton'
export const gridOptions = {
  defaultColDef: {
    filter: true,
    menuTabs: ['filterMenuTab'],
    resizable: true,
    editable: false,
    sortable: true,
  },
  headerHeight: 48,
  rowHeight: 28,
  suppressContextMenu: true,
  stopEditingWhenCellsLoseFocus: true,
  enterNavigatesVertically: true,
  components: {
    itemFactoryDetailsButton: ItemFactoryDetailsButton,
  },
  statusBar: {
    statusPanels: [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'right',
      },
    ],
  },
}
