import React from 'react'
// WARNING: Canvas v7 - Component "IconGroup" has been deprecated. Please remove this import.
import { Caption } from '@enterprise-ui/canvas-ui-react'

export interface LabelValueProps {
  label: string
  value: string | undefined
  dense?: boolean
  className?: String
}

const LabelValue: React.FC<LabelValueProps> = (props: LabelValueProps) => {
  return props.dense ? (
    <p className={props.className + ' hc-fs-caption'}>
      <strong>{props.label}</strong>
      {props.value}
    </p>
  ) : (
    <div>
      <Caption above={props.label} aria-label={props.label}>
        <p className={props.className + ' hc-fs-caption'}>{props.value}</p>
      </Caption>
    </div>
  )
}

export default LabelValue
