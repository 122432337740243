import React, { useState, useEffect, FunctionComponent } from 'react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { lookupVendors } from '../../services/LookupService'
import { LookupValue } from '../../types/LookupValue'
import { useBusinessPartnerInfo } from '../../context/BusinessPartnerInfoContext'
import { useUserInfo } from '../../context/UserInfoContext'
import { UserType } from '../../utils/Constants'

interface VendorSearchProps {
  vendorIdsInitialState?: string[]
  onVendorIdChange(vendorId: string[]): void
}

const VendorSearch: FunctionComponent<VendorSearchProps> = ({
  vendorIdsInitialState,
  onVendorIdChange,
}) => {
  const [vendorId, setVendorId] = useState<LookupValue>()
  const vendorIdInitialState = vendorIdsInitialState && vendorIdsInitialState[0]
  const [vendorsLookupForExternal, setVendorsLookupForExternal] = useState<
    LookupValue[]
  >([])
  const { businessPartner } = useBusinessPartnerInfo()
  const { userInfo } = useUserInfo()

  useEffect(() => {
    if (userInfo.user_type === UserType.EXTERNAL && businessPartner) {
      if (businessPartner.vendors) {
        const vendorsLookup: LookupValue[] = businessPartner.vendors.map(
          (vendor) => {
            return {
              id: vendor.gms_vendor_number,
              value: vendor.gms_vendor_number,
              label: vendor.gms_vendor_number + ' ' + vendor.vendor_name,
            }
          },
        )
        setVendorsLookupForExternal(vendorsLookup)
      }
    }
  }, [userInfo.user_type, businessPartner])

  const vendorIdLookupValues = async (value: any) => {
    if (userInfo.user_type === UserType.EXTERNAL) {
      return vendorsLookupForExternal
    }
    if (!value) return []
    if (vendorId && value === vendorId.label) return [vendorId]
    return await lookupVendors(value)
  }

  //get vendor lookupValue based on vendorIdsInitialState
  useEffect(() => {
    if (vendorIdInitialState) {
      lookupVendors(vendorIdInitialState).then(
        (lookupValues: LookupValue[]) => {
          lookupValues &&
            lookupValues.length > 0 &&
            setVendorId(lookupValues[0])
        },
      )
    }
  }, [vendorIdInitialState])

  useEffect(() => {
    vendorId && vendorId.value
      ? onVendorIdChange([vendorId.value])
      : onVendorIdChange([])
  }, [vendorId, onVendorIdChange])

  return (
    <Autocomplete
      label="Vendor Id/Name"
      id="vendorId"
      location="left"
      filter={Autocomplete.filters.none}
      onUpdate={(id: any, value: any) => setVendorId(value)}
      value={vendorId}
      options={vendorIdLookupValues}
    />
  )
}

export default React.memo(VendorSearch)
