import { AnalyticsService, ExceptionArgs } from './analytics-service'
import ReactGA, { EventArgs, TimingArgs } from 'react-ga'
import { getAppConfig } from '../../config/AppConfig'
import { getUserIdFromAccessToken } from '../../utils/AxiosUtil'

let initialized = false
let googleAnalyticsInitialized = false

const getTrackingId = (): string => getAppConfig().analyticsTrackingId

const initializeGoogleAnalytics = (): boolean => {
  if (!googleAnalyticsInitialized) {
    const trackingId = getTrackingId()
    const userId = getUserIdFromAccessToken()
    console.log('registering factorial ga with tid ' + getTrackingId())
    if (trackingId && trackingId !== null && ReactGA.initialize) {
      ReactGA.initialize(
        [
          {
            trackingId: getTrackingId(),
            gaOptions: {
              name: 'factorialTracker',
              userId: btoa(userId),
            },
          },
        ],
        {
          alwaysSendToDefaultTracker: false,
        },
      )
      googleAnalyticsInitialized = true
    } else {
      console.warn(
        'Unable to initialize Google Analytics, trackingId not found!',
      )
    }
  }
  return googleAnalyticsInitialized
}

const initialize = (): boolean => {
  if (!initialized) {
    initialized = initializeGoogleAnalytics()
  }

  // return if we can track
  return initialized || googleAnalyticsInitialized
}

const initializeAndRun = <T>(
  fn: (...args: any[]) => T,
): ((...args: any[]) => T | void) => {
  if (initialize()) {
    return fn
  }
  return () => {}
}

export const googleAnalyticsService: AnalyticsService = {
  event: (args: EventArgs): void =>
    initializeAndRun(ReactGA.event)(args, ['factorialTracker']),

  exception: (args: ExceptionArgs) =>
    initializeAndRun(ReactGA.exception)({
      exDescription: args.description,
      exFatal: args.fatal,
    }),

  pageView: (path: string): void =>
    initializeAndRun(ReactGA.pageview)(path, ['factorialTracker']),

  modalView: (name: string): void =>
    initializeAndRun(ReactGA.modalview)(name, ['factorialTracker']),

  timing: (args: TimingArgs): void =>
    initializeAndRun(ReactGA.timing)(args, ['factorialTracker']),
}
