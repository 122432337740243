import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { FunctionComponent, useEffect, useState } from 'react'
import { lookupCycleDesc } from '../../services/LookupService'
import { LookupValue } from '../../types/LookupValue'

interface CycleDescDropDownProps {
  onCycleDescChange(cycleDesc?: string[]): void
}
const CycleDescDropDown: FunctionComponent<CycleDescDropDownProps> = ({
  onCycleDescChange,
}) => {
  const [cycleDesc, setCycleDescription] = useState<LookupValue[]>([])
  const [cycleDescOptions, setCycleDescOptions] = useState<LookupValue[]>([])

  useEffect(() => {
    const fetchLookupCycleDesc = async () => {
      const result = await lookupCycleDesc()
      setCycleDescOptions(result)
    }
    fetchLookupCycleDesc()
  }, [])

  useEffect(() => {
    cycleDesc && cycleDesc.length > 0
      ? onCycleDescChange(cycleDesc.map((cd) => cd.value))
      : onCycleDescChange()
  }, [cycleDesc, onCycleDescChange])

  return (
    <Autocomplete
      label="Cycle ( Select one or more cycle descriptions )"
      id="cycleDescription"
      location="left"
      multiselect
      filter={Autocomplete.filters.none}
      onUpdate={(id: any, value: any) => setCycleDescription(value)}
      value={cycleDesc}
      options={cycleDescOptions}
    />
  )
}

export { CycleDescDropDown }
