import {
  Chip,
  Grid,
  Heading,
  SideNav,
  Spinner,
  TargetLogo,
} from '@enterprise-ui/canvas-ui-react'
import { faIndustry } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { getAppConfig } from '../../config/AppConfig'
import { useBusinessPartnerInfo } from '../../context/BusinessPartnerInfoContext'
import { useReleaseNotes } from '../../context/ReleaseNotesContext'
import { useSideNav } from '../../context/SideNavContext'
import { useUserInfo } from '../../context/UserInfoContext'
import { getChangeRequestCount } from '../../services/ChangeRequestService'
import EnterpriseIcon, { FlagIcon } from '@enterprise-ui/icons'
import {
  getItemsWithInvalidFactoryCount,
  getItemsWithNoFactoryCount,
  getItemsWithNoVopCount,
} from '../../services/ItemService'
import { captureButtonClickEvent } from '../../services/analytics/events-service'
import {
  ChangeRequestQueryType,
  ReviewStatusType,
} from '../../types/ChangeRequestQueryType'
import { UserType } from '../../utils/Constants'
import './FactorialSideNav.scss'
import {
  externalSideNavLinks,
  internalSideNavLinks,
  signOutLink,
} from './FactorialSideNavLinks'
const FactorialSideNav = () => {
  const { isSideNavVisible, setIsSideNavVisible } = useSideNav()
  const { userInfo } = useUserInfo()
  const sideNavLinks =
    userInfo.user_type === UserType.INTERNAL
      ? internalSideNavLinks
      : externalSideNavLinks
  const [pendingApprovalCount, setPendingApprovalCount] = useState<number>(0)
  const { setIsReleaseNotesOpen } = useReleaseNotes()
  const [noFactoriesCount, setNoFactoriesCount] = useState<number>(0)
  const [invalidFactoryCount, setInvalidFactoryCount] = useState<number>(0)
  const [noVopCount, setnoVopCount] = useState<number>(0)
  const [selectedCounter, setSelectedCounter] = useState<number>(0)
  const navigate = useNavigate()
  const [vendorId, setVendorId] = useState<String[]>([])
  const [fetchingNoFactoriesCount, setFetchingNoFactoriesCount] =
    useState<boolean>(false)
  const [fetchingNoVopCount, setFetchingNoVopCount] = useState<boolean>(false)
  const [fetchingInvalidFactoriesCount, setFetchingInvalidFactoriesCount] =
    useState<boolean>(false)
  //get pending requests count
  const pendingRequestsCountQuery: ChangeRequestQueryType = {
    review_status: [ReviewStatusType.Initiated],
  }
  const { businessPartner } = useBusinessPartnerInfo()
  userInfo.user_type === UserType.INTERNAL
    ? (pendingRequestsCountQuery.internal_contacts = [userInfo.email_id])
    : (pendingRequestsCountQuery.external_contacts = [userInfo.email_id])
  useEffect(() => {
    if (userInfo.user_type === UserType.EXTERNAL && businessPartner) {
      if (businessPartner.vendors) {
        const vendors: String[] = businessPartner.vendors.map(
          (vendor) => vendor.gms_vendor_number,
        )
        setVendorId(vendors)
        setFetchingNoFactoriesCount(false)
        setFetchingInvalidFactoriesCount(false)
        setFetchingNoVopCount(false)
        getItemsWithNoFactoryCount(vendors).then(
          (noFactoryItemCount: number) => {
            setFetchingNoFactoriesCount(true)
            setNoFactoriesCount(noFactoryItemCount)
          },
        )
        getItemsWithInvalidFactoryCount(vendors).then(
          (invalidFactoryCount: number) => {
            setFetchingInvalidFactoriesCount(true)
            setInvalidFactoryCount(invalidFactoryCount)
          },
        )
        getItemsWithNoVopCount(vendors).then((noVopCount: number) => {
          setFetchingNoVopCount(true)
          setnoVopCount(noVopCount)
        })
      }
    }
    getChangeRequestCount(pendingRequestsCountQuery).then(
      (changeRequestCount: number) => {
        setPendingApprovalCount(changeRequestCount)
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.user_type, businessPartner])

  useEffect(() => {
    const navSearch = (url: string) => {
      navigate({ pathname: '/search', search: url })
    }
    if (selectedCounter !== 0) {
      var url = ''
      for (const vendor of vendorId) {
        url += vendor + ','
      }
      url = url.slice(0, -1) + '&'
      if (selectedCounter === 1) {
        navSearch(url + 'no-factory-only=true')
      } else if (selectedCounter === 2) {
        navSearch(url + 'invalid-factory-only=true')
      } else if (selectedCounter === 3) {
        navSearch(url + 'no-vop-only=true')
      }
      setSelectedCounter(0)
    }
  }, [selectedCounter, vendorId, navigate])

  return (
    <>
      {isSideNavVisible && (
        <>
          <SideNav
            className="side-nav-container"
            isVisible={isSideNavVisible}
            suppressAutoOpen={true}
            hasOverlay={false}
            onRequestClose={() => {}}
          >
            <SideNav.Header className="side-nav-header">
              <Grid.Container
                spacing="dense"
                align="flex-end"
                justify="center"
                className="side-nav-header"
              >
                <Grid.Item>
                  <FontAwesomeIcon icon={faIndustry} color="white" size="3x" />
                </Grid.Item>
                <Grid.Item>
                  <Heading size={4} className="hc-clr-white">
                    Factorial
                  </Heading>
                </Grid.Item>
              </Grid.Container>
            </SideNav.Header>
            <SideNav.Navigation>
              <SideNav.Navigation>
                {sideNavLinks.map((sideNavLink) => {
                  return (
                    <SideNav.NavigationItem
                      as={NavLink}
                      to={sideNavLink.navigationPath}
                      key={sideNavLink.label}
                      onClick={() => {
                        setIsSideNavVisible(false)
                      }}
                    >
                      <div
                        className="side-nav-link"
                        data-testid={sideNavLink.label}
                      >
                        <EnterpriseIcon
                          icon={sideNavLink.icon}
                          color="white"
                          size="lg"
                          style={{ paddingRight: '5px' }}
                        />
                        {sideNavLink.label === 'Change Requests'
                          ? 'Change Requests (' + pendingApprovalCount + ')'
                          : sideNavLink.label}
                      </div>
                    </SideNav.NavigationItem>
                  )
                })}
                <SideNav.NavigationItem
                  onClick={(e: any) => {
                    if (e && e.preventDefault) {
                      e.preventDefault()
                    }
                    captureButtonClickEvent('Release Notes')
                    setIsReleaseNotesOpen(true)
                    setIsSideNavVisible(false)
                  }}
                >
                  <div className="side-nav-link">
                    <EnterpriseIcon icon={FlagIcon} color="white" />
                    Release Notes
                  </div>
                </SideNav.NavigationItem>
                <SideNav.NavigationItem
                  as={NavLink}
                  to={signOutLink.navigationPath}
                  key={signOutLink.label}
                  onClick={() => {
                    setIsSideNavVisible(false)
                  }}
                >
                  <div className="side-nav-link">
                    <EnterpriseIcon
                      icon={signOutLink.icon}
                      size="lg"
                      color="white"
                    />
                    {signOutLink.label}
                  </div>
                </SideNav.NavigationItem>
                {userInfo.user_type === UserType.EXTERNAL && (
                  <div>
                    <p
                      style={{
                        margin: '10px',
                        color: 'white',
                      }}
                    >
                      Items Without Factories:{' '}
                      {fetchingNoFactoriesCount ? (
                        <Chip
                          color="error"
                          size="dense"
                          style={{
                            fontWeight: 'bold',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setSelectedCounter(1)
                          }}
                        >
                          {noFactoriesCount}
                        </Chip>
                      ) : (
                        <Spinner size="dense" />
                      )}
                    </p>
                    <p
                      style={{
                        margin: '10px',
                        color: 'white',
                      }}
                    >
                      Items With Invalid Factories:{' '}
                      {fetchingInvalidFactoriesCount ? (
                        <Chip
                          color="error"
                          size="dense"
                          style={{
                            fontWeight: 'bold',
                            cursor: 'pointer',
                          }}
                          onClick={() => setSelectedCounter(2)}
                        >
                          {invalidFactoryCount}
                        </Chip>
                      ) : (
                        <Spinner size="dense" />
                      )}
                    </p>
                    <p
                      style={{
                        margin: '10px',
                        color: 'white',
                      }}
                    >
                      Items Without VOPs:{' '}
                      {fetchingNoVopCount ? (
                        <Chip
                          color="error"
                          size="dense"
                          style={{
                            fontWeight: 'bold',
                            cursor: 'pointer',
                          }}
                          onClick={() => setSelectedCounter(3)}
                        >
                          {noVopCount}
                        </Chip>
                      ) : (
                        <Spinner size="dense" />
                      )}
                    </p>
                  </div>
                )}
              </SideNav.Navigation>
            </SideNav.Navigation>
            <SideNav.Footer>
              <div className="side-nav-footer hc-clr-grey03">
                <div className="hc-pa-dense">
                  Factorial | {getAppConfig().version} |{' '}
                  <TargetLogo color="white" size="dense" /> Target
                </div>
              </div>
            </SideNav.Footer>
          </SideNav>
        </>
      )}
    </>
  )
}
export { FactorialSideNav }
