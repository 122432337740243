import { LeanFactory } from '../types/ItemDetailsType'

export const distinctFactory = (factoryList: LeanFactory[]) => {
  const factoryL: string[] = []
  factoryList.forEach((factory) => {
    factoryL.push(factory.factory_id + '-' + factory.factory_name)
  })
  return factoryL.filter(onlyUnique)
}
function onlyUnique(value: any, index: any, self: string | any[]) {
  return self.indexOf(value) === index
}
