import { format } from 'date-fns'

export const isTodayBeforeOrEqualTo = (endDate: Date) => {
  const currDate = new Date()
  return currDate <= endDate
}

export const getTodaysDate = () => {
  const now = new Date()
  return new Date(
    now.getUTCFullYear() +
      '-' +
      (now.getUTCMonth() + 1) +
      '-' +
      now.getUTCDate() +
      ' GMT',
  )
}

export const getTodaysDatestring = () => {
  const now = new Date()
  return (
    now.getUTCFullYear() +
    '-' +
    (now.getUTCMonth() + 1) +
    '-' +
    now.getUTCDate() +
    ' GMT'
  )
}

export const getTodaysDateFormatted = () => {
  const now = new Date()
  return getDateFormatted(now)
}

export const getDateFormatted = (date: Date) => {
  const utcDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
  )
  return format(utcDate, 'MM/dd/yyyy')
}
