import {
  Button,
  Grid,
  GridItem,
  InputRadio,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import React, { useEffect, useState } from 'react'
import { useBusinessPartnerInfo } from '../../context/BusinessPartnerInfoContext'
import { lookupVendorIdDept } from '../../services/LookupService'
import { AutoCompleteOptionType } from '../../types/AutoCompleteOptionType'
import { ChangeRequestType } from '../../types/ChangeRequestType'
import {
  FactoryTimelineItem,
  ItemDetailsType,
  LeanFactory,
  TcinVopChangeDetail,
} from '../../types/ItemDetailsType'
import { ItemVendorFactoryTimeline } from '../../types/ItemVendorFactoryType'
import { ReviewStatus } from '../../utils/Constants'
import { getTodaysDate } from '../../utils/DateUtil'
import { createEditableVOPFactoryTimeline } from '../../utils/FactoriesTimelineUtil'
import { distinctFactory } from '../../utils/UniqueFactories'

const getFactoryTimelinesForVendor = (
  itemDetails: ItemDetailsType,
  vendorId: string,
) => {
  const vendorFactoryTimelines = itemDetails.vendor_factory_timelines.filter(
    (factoryTimelineItem) => {
      return factoryTimelineItem.vendor_id === vendorId
    },
  )
  return {
    factoryTimelineItems: vendorFactoryTimelines[0]
      ? vendorFactoryTimelines[0].factory_timelines
      : [],
  }
}

interface ChooseActiveFactoriesProps {
  onReview: () => void
  onBulkEditVOP: (vopForTcinsChanged: TcinVopChangeDetail[]) => void
  onBulkChangeReq: (
    itemVendorFactoryTimelines: ItemVendorFactoryTimeline[],
  ) => void
  itemDetails: ItemDetailsType[]
  vendorIds: string[]
  dept: string
}
const ChooseActiveFactories: React.FC<ChooseActiveFactoriesProps> = ({
  onReview,
  onBulkEditVOP,
  onBulkChangeReq,
  itemDetails,
  vendorIds,
  dept,
}) => {
  const { getVendorByGmsVendorNumber } = useBusinessPartnerInfo()

  const [selectedFactory, setSelectedFactory] = useState<string>('')
  const vendors = vendorIds.map((vendorId: string) =>
    getVendorByGmsVendorNumber(vendorId),
  )
  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  let selectedTcinsDetail: TcinVopChangeDetail[] = []
  let itemVendorFactoryTimelines: ItemVendorFactoryTimeline[] = []
  let vopFactoryTimelineItems: FactoryTimelineItem[] = []
  let vopFactories: LeanFactory[] = []
  const factoryTimelines = itemDetails.map((itemDetail) =>
    createEditableVOPFactoryTimeline(
      getFactoryTimelinesForVendor(itemDetail, vendorIds[0]),
    ),
  )
  const factories = factoryTimelines
    .map((factoryTimeline) =>
      factoryTimeline.factoryTimelineItems.map((factoryTimelineItem) =>
        factoryTimelineItem.factories.map((factory) => factory),
      ),
    )
    .flat()
    .flat()

  useEffect(() => {
    const isValidInput = selectedFactory ? true : false
    setIsFormValid(isValidInput)
  }, [selectedFactory])!
  let itmDtlWithoutCR: ItemDetailsType[] = []
  let hasCR: ChangeRequestType[] | undefined = []
  // eslint-disable-next-line array-callback-return
  itemDetails.map((itemDetail) => {
    hasCR = itemDetail.change_requests?.filter(
      (changeRequest) => changeRequest.review_status === ReviewStatus.INITIATED,
    )

    if (hasCR === undefined || hasCR.length < 1) {
      itmDtlWithoutCR.push(itemDetail)
    } else {
      selectedTcinsDetail.push({
        tcin: itemDetail.tcin,
        dpci: itemDetail.dpci,
        description: itemDetail.description,
        selectedFactory: selectedFactory,
        activeFactory: '',
        vop: '',
        vopUpdateResult: itemDetail.tcin + ' has a pending change request ',
      })
    }
    hasCR = []
  })
  const distinctFactories = distinctFactory(factories)
  const getTcinFactoryDetails = (vop: string) => {
    vopFactories = []
    vopFactoryTimelineItems = []
    itemVendorFactoryTimelines = []
    const todaysDate = getTodaysDate()
    itmDtlWithoutCR
      .map((itemDetail) =>
        itemDetail.vendor_factory_timelines.map((vendorFactoryTimelines) =>
          vendorFactoryTimelines.factory_timelines.map(
            // eslint-disable-next-line array-callback-return
            (factoryTimelineItems) => {
              if (
                (factoryTimelineItems.effective_start_date <= todaysDate &&
                  todaysDate <= factoryTimelineItems.effective_end_date) ||
                factoryTimelineItems.effective_end_date > todaysDate
              ) {
                // eslint-disable-next-line array-callback-return
                factoryTimelineItems.factories.map((ftl) => {
                  if (
                    selectedFactory
                      .substring(0, selectedFactory.indexOf('-'))
                      .trim() === ftl.factory_id
                  ) {
                    vopFactories = []
                    vopFactories.push({
                      factory_id: ftl.factory_id,
                      factory_name: ftl.factory_name,
                      effective_start_date: ftl.effective_start_date,
                      effective_end_date: ftl.effective_end_date,
                      vendor_order_spec_id: vop,
                      is_added: false,
                      is_removed: false,
                      is_vop_changed: true,
                      factory_active: true,
                    })
                    vopFactoryTimelineItems = []
                    vopFactoryTimelineItems.push({
                      effective_start_date:
                        factoryTimelineItems.effective_start_date,
                      effective_end_date:
                        factoryTimelineItems.effective_end_date,
                      factories: vopFactories,
                    })
                    selectedTcinsDetail.push({
                      tcin: itemDetail.tcin,
                      dpci: itemDetail.dpci,
                      description: itemDetail.description,
                      selectedFactory: selectedFactory,
                      activeFactory: ftl.factory_id,
                      vop: vop,
                      vopUpdateResult: 'Successful',
                    })
                    itemVendorFactoryTimelines.push({
                      tcin: itemDetail.tcin,
                      dpci: itemDetail.dpci,
                      factoryTimelineItems: vopFactoryTimelineItems,
                      isDateAdjusted: false,
                    })
                  } else {
                    selectedTcinsDetail.push({
                      tcin: itemDetail.tcin,
                      dpci: itemDetail.dpci,
                      description: itemDetail.description,
                      selectedFactory: selectedFactory,
                      activeFactory: ftl.factory_id,
                      vop: vop,
                      vopUpdateResult:
                        'Selected Factory does not exist for Item',
                    })
                  }
                })
              }
            },
          ),
        ),
      )
      .flat()
      .flat()
      .flat()
      .flat()
  }

  const bulkEditVOP = (value: AutoCompleteOptionType) => {
    if (value) {
      getTcinFactoryDetails(value.id)
      onBulkEditVOP(selectedTcinsDetail)
      onBulkChangeReq(itemVendorFactoryTimelines!)
    }
  }

  const goToReview = () => {
    onReview()
  }

  return (
    <div className="hc-pa-normal">
      <Grid.Container direction="column" align="start">
        <Grid.Item>
          <Grid.Container justify="flex-start">
            <Grid.Item>
              <div style={{ width: '400px', marginRight: '10px' }}></div>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        {vendors &&
          vendors.map(
            (vendor) =>
              vendor && (
                <Grid.Item className="hc-bg-grey06 hc-ma-normal">
                  <Grid.Container justify="space-between">
                    <Grid.Item className="hc-pb-none" xs={12}>
                      <div style={{ fontWeight: 'bold', fontSize: '12px' }}>
                        {`${vendor?.gms_vendor_number} - ${vendor?.vendor_name}`}
                      </div>
                    </Grid.Item>
                    <Grid.Item xs={8}>
                      Please choose an active factory
                      {distinctFactories.map((factory) => (
                        <Factory
                          factory={factory}
                          selectedFactory={selectedFactory}
                          onSelect={(factory: string) => {
                            setSelectedFactory(factory)
                          }}
                        ></Factory>
                      ))}
                    </Grid.Item>
                    <Grid.Item xs={4} className="hc-pb-none">
                      <Autocomplete
                        label="Vendor Order Point"
                        id="vop"
                        required
                        filter={Autocomplete.filters.none}
                        onUpdate={(id, value) => {
                          bulkEditVOP(value)
                        }}
                        options={async () => {
                          return await lookupVendorIdDept(
                            vendor.gms_vendor_number +
                              '-' +
                              dept.substring(0, dept.indexOf('-')).trim(),
                          )
                        }}
                      />
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              ),
          )}
        <Grid.Item>
          <Grid.Container justify="flex-end">
            <GridItem>
              <Button
                type="primary"
                disabled={!isFormValid}
                onClick={goToReview}
                size="dense"
              >
                <span style={{ textTransform: 'capitalize' }}>
                  Next: Review
                </span>
              </Button>
            </GridItem>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
    </div>
  )
}

interface FactoryProps {
  factory: string
  selectedFactory?: string
  onSelect?: (factory: string) => void
}
const Factory: React.FC<FactoryProps> = ({
  factory,
  selectedFactory,
  onSelect,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        marginBottom: '5px',
      }}
    >
      <FactorySelector
        factory={factory}
        selectedFactory={selectedFactory}
        onSelect={onSelect}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginLeft: '5px',
        }}
      >
        <p>{factory}</p>
      </div>
    </div>
  )
}

interface FactorySelectorProps {
  factory: string
  selectedFactory?: string
  onSelect?: (factory: string) => void
}
const FactorySelector: React.FC<FactorySelectorProps> = ({
  factory,
  selectedFactory,
  onSelect,
}) => {
  const id = factory
  return (
    <InputRadio
      id={id}
      options={[
        {
          value: id,
          label: null,
        },
      ]}
      value={selectedFactory ? selectedFactory : ''}
      onUpdate={(id: any, value: any) => {
        onSelect?.(factory)
      }}
      key={id}
    />
  )
}
export default ChooseActiveFactories
