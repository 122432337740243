import { BulkChangeResponseType } from '../../types/BulkChangeResponseType'
import { ItemDetailsType } from '../../types/ItemDetailsType'
import { ReviewStatus } from '../../utils/Constants'

export const filterItemsWithPendingCRs = (itemDetails: ItemDetailsType[]) => {
  const tcinsWithPendingCRs: string[] = []
  const itemsWithoutCRs: ItemDetailsType[] = []
  itemDetails.forEach((itemDetail) => {
    const hasCR = itemDetail.change_requests?.filter(
      (changeRequest) => changeRequest.review_status === ReviewStatus.INITIATED,
    )
    if (hasCR && hasCR.length > 0) {
      tcinsWithPendingCRs.push(itemDetail.tcin)
    } else {
      itemsWithoutCRs.push(itemDetail)
    }
  })
  return { tcinsWithPendingCRs, itemsWithoutCRs }
}

export const groupItemsByVendor = (itemDetails: ItemDetailsType[]) => {
  return itemDetails.reduce(
    (itemsByVendor: any, currentItemDetail: ItemDetailsType) => {
      const vendors = currentItemDetail.vendors
      vendors.forEach((vendor) => {
        if (itemsByVendor[vendor.id]) {
          itemsByVendor[vendor.id].push(currentItemDetail)
        } else {
          itemsByVendor[vendor.id] = [currentItemDetail]
        }
      })
      return itemsByVendor
    },
    {},
  )
}

export const processChangeRequestResponse = (
  bulkChangeResponse: BulkChangeResponseType[],
) => {
  const response: {
    success: BulkChangeResponseType[]
    failure: BulkChangeResponseType[]
  } = { success: [], failure: [] }

  bulkChangeResponse.forEach((resp) => {
    if (resp.error_message) {
      response.failure.push(resp)
    } else {
      response.success.push(resp)
    }
  })

  return response
}
