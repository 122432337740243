import React, { useState, useEffect, FunctionComponent } from 'react'
import { LookupValue } from '../../types/LookupValue'
import { lookupFactoryId } from '../../services/LookupService'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useUserInfo } from '../../context/UserInfoContext'
import { useBusinessPartnerInfo } from '../../context/BusinessPartnerInfoContext'
import { UserType } from '../../utils/Constants'

interface FactoryIdSearchProps {
  FactorySearchInitialState: string
  onFactoryChange(factoryId: string): void
  onFactoryNameChange(factoryName: string): void
}
const FactoryIdSearch: FunctionComponent<FactoryIdSearchProps> = ({
  FactorySearchInitialState,
  onFactoryChange,
  onFactoryNameChange,
}) => {
  const [factoryId, setFactoryIds] = useState<LookupValue>()
  const { userInfo } = useUserInfo()
  const { businessPartner } = useBusinessPartnerInfo()
  const [factoryIdLookUpValueForExternal, setFactoryIdLookupForExternal] =
    useState<LookupValue[]>([])

  useEffect(() => {
    if (FactorySearchInitialState) {
      if (userInfo.user_type === UserType.INTERNAL) {
        lookupFactoryId(FactorySearchInitialState).then(
          (lookupValues: LookupValue[]) => {
            if (lookupValues && lookupValues.length > 0) {
              setFactoryIds(
                lookupValues.find(
                  (lookup) => lookup.id === FactorySearchInitialState,
                ),
              )
            }
          },
        )
      } else {
        if (businessPartner?.locations) {
          const factoryLookup: LookupValue[] = businessPartner.locations?.map(
            (location) => {
              return {
                id: location.location_id,
                value: location.location_name,
                label: location.location_id,
              }
            },
          )
          const exterinitlookup = factoryLookup.find(
            (lookup) => lookup.id === FactorySearchInitialState,
          )
          setFactoryIds(exterinitlookup)
        }
      }
    }
  }, [FactorySearchInitialState, businessPartner, userInfo.user_type])

  useEffect(() => {
    if (factoryId && factoryId.value) {
      onFactoryNameChange(factoryId.value)
      onFactoryChange(factoryId.id)
    } else {
      onFactoryNameChange('')
      onFactoryChange('')
    }
  }, [onFactoryChange, factoryId, onFactoryNameChange])

  useEffect(() => {
    if (userInfo.user_type === UserType.EXTERNAL && businessPartner) {
      const factoryLookup: LookupValue[] = businessPartner.locations
        ? businessPartner.locations?.map((location) => {
            return {
              id: location.location_id,
              value: location.location_name,
              label: location.location_id,
            }
          })
        : []
      setFactoryIdLookupForExternal(factoryLookup)
    }
  }, [userInfo.user_type, businessPartner])

  const factoryIdLookUpValues = async (value: any) => {
    if (userInfo.user_type === UserType.EXTERNAL) {
      if (value) {
        return factoryIdLookUpValueForExternal.filter(
          (factoryId) => factoryId.id.indexOf(value) === 0,
        )
      }
      return factoryIdLookUpValueForExternal
    } else {
      if (!value) return []
      if (factoryId && value === factoryId.value) return [factoryId]
      return await lookupFactoryId(value)
    }
  }

  return (
    <Autocomplete
      label="Factory Id"
      id="factoryId"
      location="left"
      filter={Autocomplete.filters.none}
      onUpdate={(id: any, value: any) => setFactoryIds(value)}
      value={factoryId}
      options={factoryIdLookUpValues}
    />
  )
}

export default FactoryIdSearch
