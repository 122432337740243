import { AxiosUtil } from '../utils/AxiosUtil'
import { getBaseUrl } from '../config/AppConfig'
import { LookupValue } from '../types/LookupValue'

export const lookupDeptClasses = async (
  deptClass: string,
): Promise<LookupValue[]> => {
  const url =
    getBaseUrl() +
    '/lookup_values?lookup_type=dept_class&lookup_value=' +
    deptClass
  return AxiosUtil.get(url).then((resp) => resp.data)
}

export const lookupVendors = async (
  vendorIdOrName: string,
): Promise<LookupValue[]> => {
  const url =
    getBaseUrl() +
    '/lookup_values?lookup_type=vendor&lookup_value=' +
    vendorIdOrName
  return AxiosUtil.get(url).then((resp) => resp.data)
}

export const lookupCycleDesc = async (): Promise<LookupValue[]> => {
  const url =
    getBaseUrl() + '/lookup_values?lookup_type=cycle_desc&lookup_value='
  return AxiosUtil.get(url).then((resp) => resp.data)
}

export const lookupFactoryName = async (
  factoryName: String,
): Promise<LookupValue[]> => {
  const url =
    getBaseUrl() +
    '/lookup_values?lookup_type=factory&lookup_value=' +
    factoryName
  return AxiosUtil.get(url).then((resp) => resp.data)
}
export const lookupFactoryId = async (
  factoryId: String,
): Promise<LookupValue[]> => {
  const url =
    getBaseUrl() +
    '/lookup_values?lookup_type=factory_id&lookup_value=' +
    factoryId
  return AxiosUtil.get(url).then((resp) => resp.data)
}

export const lookupVendorIdDept = async (
  vendorDept: String,
): Promise<LookupValue[]> => {
  const url =
    getBaseUrl() +
    '/lookup_values?lookup_type=dept_vendorid&lookup_value=' +
    vendorDept
  return AxiosUtil.get(url).then((resp) => resp.data)
}
