export interface ChangeRequestQueryType {
  tcins?: string[]
  vendor_id?: string
  review_status?: ReviewStatusType[]
  internal_contacts?: string[]
  external_contacts?: string[]
  change_request_initiator_email?: string
}

export enum ReviewStatusType {
  Approved = 'APPROVED',
  Initiated = 'INITIATED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
}
