import React, { useState, useEffect } from 'react'
// WARNING: Canvas v7 - Component "Contaom '@enterprise-ui/canvas-ui-react'
import { ItemDetailsType, ItemVendor } from '../../types/ItemDetailsType'
import ItemDetails from './ItemDetails'
import './ItemDetails.scss'
import { AutoCompleteOptionType } from '../../types/AutoCompleteOptionType'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { ChangeRequestType } from '../../types/ChangeRequestType'
import { useReload } from '../../hooks/useForceReload'
import { useItemDetailsModal } from '../../context/ItemDetailsModalContext'
import FactoryDetailsCard from '../FactoryDetailsCard/FactoryDetailsCard'
import { Modal, Grid, Divider, Card } from '@enterprise-ui/canvas-ui-react'

const getFactoryTimelinesForVendor = (
  itemDetails: ItemDetailsType,
  vendorId: string,
) => {
  const vendorFactoryTimelines = itemDetails.vendor_factory_timelines.filter(
    (factoryTimelineItem) => {
      return factoryTimelineItem.vendor_id === vendorId
    },
  )
  return {
    factoryTimelineItems: vendorFactoryTimelines[0]
      ? vendorFactoryTimelines[0].factory_timelines
      : [],
  }
}
interface ItemDetailsModalProps {
  itemDetails?: ItemDetailsType | undefined
}
const ItemDetailsModal: React.FC<ItemDetailsModalProps> = (
  props: ItemDetailsModalProps,
) => {
  const itemDetails = props.itemDetails
  const [selectedVendors, setSelectedVendors] = useState<
    AutoCompleteOptionType[]
  >([])
  const { showItemDetailsModal, setShowItemDetailsModal } =
    useItemDetailsModal()
  const [reload, forceReload] = useReload()
  useEffect(() => {
    if (itemDetails && itemDetails.vendors && itemDetails.vendors.length > 0) {
      setSelectedVendors(
        itemDetails.vendors
          .slice()
          .sort((vendor: ItemVendor) => {
            //Sort vendors if vendor have an open change request
            return itemDetails.change_requests
              ?.map((change: ChangeRequestType) => {
                return change.gms_vendor_number
              })
              .includes(vendor.id)
              ? -1
              : 0
          })
          .filter((v: ItemVendor) => {
            return v.id !== '0'
          })
          .map((vendor: ItemVendor) => {
            return {
              id: vendor.id,
              value: vendor.name,
              label: vendor.name + ' - ' + vendor.id,
            }
          }),
      )
    }
  }, [itemDetails, reload])
  return (
    <Modal
      isVisible={showItemDetailsModal}
      onRefuse={() => setShowItemDetailsModal(false)}
      className="itemDetailsModal"
    >
      <div className="hc-pa-normal">
        <Grid.Container className="hc-pl-expanded hc-pr-expanded">
          <Grid.Item xs={4}>
            {itemDetails && <ItemDetails itemDetails={itemDetails} />}
          </Grid.Item>
          <Divider direction="vertical" />
          <Grid.Item xs={8}>
            <Grid.Container>
              <Grid.Item xs={12} className="hc-pa-dense">
                {itemDetails && (
                  <Autocomplete
                    id="selectedVendors"
                    options={
                      itemDetails && itemDetails.vendors
                        ? itemDetails.vendors
                            .filter((v: ItemVendor) => {
                              return v.id !== '0'
                            })
                            .map((vendor: ItemVendor) => {
                              return {
                                id: vendor.id,
                                value: vendor.name,
                                label: vendor.name + ' - ' + vendor.id,
                              }
                            })
                        : []
                    }
                    label="Vendors"
                    value={selectedVendors}
                    onUpdate={(id, value) => {
                      setSelectedVendors(value)
                    }}
                    showSearchIcon={true}
                    multiselect
                  />
                )}
              </Grid.Item>
              {itemDetails && selectedVendors.length > 0 ? (
                selectedVendors.map(
                  (selectedVendor: AutoCompleteOptionType) => {
                    return (
                      <Grid.Item xs={12} className="hc-pa-dense">
                        <Card elevation={1} className="hc-ma-none hc-pa-dense">
                          <FactoryDetailsCard
                            factoryTimeline={getFactoryTimelinesForVendor(
                              itemDetails,
                              selectedVendor.id,
                            )}
                            changeRequest={itemDetails.change_requests?.filter(
                              (changeReq: ChangeRequestType) => {
                                return (
                                  changeReq.gms_vendor_number ===
                                  selectedVendor.id
                                )
                              },
                            )}
                            selectedVendor={selectedVendor}
                            itemDetails={itemDetails}
                            onFactoryAction={forceReload}
                          />
                        </Card>
                      </Grid.Item>
                    )
                  },
                )
              ) : (
                <Grid.Item xs={12}>
                  <div>No vendor(s) selected.</div>
                </Grid.Item>
              )}
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
      </div>
    </Modal>
  )
}

export default ItemDetailsModal
