import React, { useState, useEffect, useCallback } from 'react'
import { Form } from '@enterprise-ui/canvas-ui-react'

interface PIDSearchProps {
  pidsInitialState?: string[]
  onPidsChange(pids: string[]): void
  handleKeyPress: (e: React.KeyboardEvent) => void
}
const PIDSearch: React.FC<PIDSearchProps> = ({
  pidsInitialState,
  onPidsChange,
  handleKeyPress,
}) => {
  const [pids, setPids] = useState<string[]>([])

  const onInputChange = useCallback(
    (value: string) => {
      const inputValue = value
      if (inputValue.length > 0) {
        const pidsTemp: string[] = []
        inputValue.split(/[ ,]+/).forEach((val) => {
          pidsTemp.push(val)
        })
        setPids(pidsTemp)
        onPidsChange(pidsTemp)
      } else {
        setPids([])
        onPidsChange([])
      }
    },
    [setPids, onPidsChange],
  )

  useEffect(() => {
    if (pidsInitialState && pidsInitialState.length > 0) {
      setPids([...pidsInitialState])
      onInputChange(pidsInitialState.join())
    }
  }, [pidsInitialState, setPids, onInputChange])

  return (
    <Form.Field
      label="Spark PID"
      type="text"
      id={'sparkpid'}
      onChange={(event: any) => onInputChange(event.target.value)}
      value={pids}
      onKeyPress={handleKeyPress}
    />
  )
}

export default PIDSearch
