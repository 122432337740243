import React, { useEffect, FunctionComponent, useState } from 'react'
import { useAuth } from '@praxis/component-auth'
import { addAxiosInterceptors, initAxios } from '../../utils/AxiosUtil'
import { UserType } from '../../utils/Constants'
import { getAppConfig } from '../../config/AppConfig'
import { getBusinessPartnerDetails } from '../../services/BusinessPartnerService'
import { useUserInfo } from '../../context/UserInfoContext'
import { useBusinessPartnerInfo } from '../../context/BusinessPartnerInfoContext'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'

interface WithAuthUserProps {
  children: React.ReactNode
}

const WithAuthUser: FunctionComponent<WithAuthUserProps> = ({ children }) => {
  const auth = useAuth()
  const { businessPartner, setBusinessPartner } = useBusinessPartnerInfo()
  const { setUserInfo } = useUserInfo()
  const makeToast = ToastProvider.useToaster()
  const { isAuthorized, login, session } = auth
  const isUserAuthorized = isAuthorized()
  const hostname = window && window.location && window.location.hostname
  const [isAxiosConfigured, setIsAxiosConfigured] = useState(false)
  useEffect(() => {
    if (isUserAuthorized && session?.userInfo) {
      const identity: any = session && session.userInfo
      const userInfo = {
        user_id: session && session.userInfo.lanId,
        user_name: session && session.userInfo.fullName,
        email_id: session && session.userInfo.email,
        company: identity && identity['company'],
        user_type:
          hostname === getAppConfig().internalHostName
            ? UserType.INTERNAL
            : UserType.EXTERNAL,
      }
      setUserInfo(userInfo)
      initAxios(makeToast)
      addAxiosInterceptors()
      setIsAxiosConfigured(true)
    }
  }, [
    session,
    isUserAuthorized,
    makeToast,
    setUserInfo,
    hostname,
    setIsAxiosConfigured,
  ])

  useEffect(() => {
    //Get BP Details for External users
    if (hostname !== getAppConfig().internalHostName && !businessPartner) {
      getBusinessPartnerDetails().then((res: any) => {
        setBusinessPartner(res)
      })
    }
  }, [businessPartner, setBusinessPartner, hostname])

  return <>{isUserAuthorized ? isAxiosConfigured && children : login()}</>
}

export default WithAuthUser
