import { ProgressBar } from '@enterprise-ui/canvas-ui-react'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { initializeAppConfig } from '../../config/AppConfig'
import { AppConfigType } from '../../types/AppConfigType'

interface ConfigProviderProps {
  children: React.ReactNode
}

const ConfigProvider: FunctionComponent<ConfigProviderProps> = ({
  children,
}) => {
  const [config, setConfig] = useState<AppConfigType | any>()

  useEffect(() => {
    initializeAppConfig().then((cnfg) => setConfig(cnfg))
  }, [])

  return <>{config ? children : <ProgressBar indeterminate />}</>
}
export default ConfigProvider
