import React, { useState } from 'react'
import { ItemDetailsType, LeanFactory } from '../../types/ItemDetailsType'
import { AutoCompleteOptionType } from '../../types/AutoCompleteOptionType'
import { EditVOPForm } from './EditVOPForm'

interface ChangeVOPProps {
  selectedFactory?: LeanFactory
  onChangeVOP: (vopChgFactory: LeanFactory) => void
  selectedVendor: AutoCompleteOptionType
  itemDetails: ItemDetailsType
}

export const ChangeVOP: React.FC<ChangeVOPProps> = ({
  selectedFactory,
  onChangeVOP,
  selectedVendor,
  itemDetails,
}) => {
  const [showSelectionError, setShowSelectionError] = useState<boolean>(false)

  const [errorMessage] = useState<string>('')

  if (selectedFactory && showSelectionError) {
    setShowSelectionError(false)
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        {selectedFactory && (
          <EditVOPForm
            currFactory={selectedFactory}
            onEditVOP={(vopChgfactory: LeanFactory) => {
              onChangeVOP(vopChgfactory)
            }}
            showForm={true}
            selectedVendor={selectedVendor}
            itemDetails={itemDetails}
          />
        )}
      </div>
      <div>
        {showSelectionError && <p className="hc-clr-error">{errorMessage}</p>}
      </div>
    </>
  )
}
