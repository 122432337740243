import React, { useEffect, useState } from 'react'
import ItemDetails from '../components/ItemDetails/ItemDetails'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { getItemDetails } from '../services/ItemService'
import { ItemDetailsType } from '../types/ItemDetailsType'
import {
  Grid,
  Button,
  Card,
  Divider,
  ProgressBar,
} from '@enterprise-ui/canvas-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { AutoCompleteOptionType } from '../types/AutoCompleteOptionType'
import { useReload } from '../hooks/useForceReload'
import {
  createAndSortVendorOptions,
  createVendorOptions,
} from '../utils/VendorsUtils'
import { FactoryDetailsCards } from '../components/FactoryDetailsCard/FactoryDetailsCards'

const ItemFactoryDetails: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [itemDetails, setItemDetails] = useState<ItemDetailsType>()
  const [selectedVendors, setSelectedVendors] = useState<
    AutoCompleteOptionType[]
  >([])
  const [fetchInProgress, setFetchInProgress] = useState<boolean>(false)
  const [reload, forceReload] = useReload()

  useEffect(() => {
    if (location.pathname === '/item-factory-details') {
      const urlParams = new URLSearchParams(location.search)
      const tcin: string = urlParams.get('tcin') || ''
      if (tcin) {
        setFetchInProgress(true)
        getItemDetails({ tcins: [tcin] })
          .then((itemDetailsRes: ItemDetailsType[]) => {
            setFetchInProgress(false)
            if (itemDetailsRes && itemDetailsRes.length > 0) {
              setItemDetails(itemDetailsRes[0])
              setSelectedVendors(
                createAndSortVendorOptions(
                  itemDetailsRes[0].vendors,
                  itemDetailsRes[0].change_requests,
                ),
              )
            }
          })
          .catch(() => {
            setFetchInProgress(false)
          })
      }
    }
  }, [setItemDetails, setFetchInProgress, location, setSelectedVendors, reload])

  return (
    <Grid.Container key={reload}>
      {fetchInProgress && <ProgressBar indeterminate />}
      <Grid.Item xs={12} className="hc-pb-none">
        <Grid.Container>
          <Grid.Item xs={1} className="hc-pb-none hc-pr-none hc-pt-expanded">
            <Button onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} size="1x" />
              <span style={{ marginLeft: '4px' }}>Back</span>
            </Button>
          </Grid.Item>
          <Grid.Item xs={11} className="hc-pb-none hc-pl-none">
            <Autocomplete
              id="selectedVendors"
              options={createVendorOptions(itemDetails?.vendors)}
              label="Vendors"
              value={selectedVendors}
              onUpdate={(id, value) => {
                setSelectedVendors(value)
              }}
              showSearchIcon={true}
              multiselect
            />
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
      <Grid.Item xs={12} className="hc-pt-none">
        {itemDetails && !fetchInProgress && itemDetails.tcin && (
          <Card className="hc-pa-expanded" elevation={3}>
            <Grid.Container>
              <Grid.Item xs={4}>
                <ItemDetails itemDetails={itemDetails} />
              </Grid.Item>
              <Divider direction="vertical" />
              <Grid.Item xs={8}>
                <FactoryDetailsCards
                  selectedVendors={selectedVendors}
                  itemDetails={itemDetails}
                  onFactoryAction={forceReload}
                />
              </Grid.Item>
            </Grid.Container>
          </Card>
        )}
        {!fetchInProgress && !(itemDetails && itemDetails.tcin) && (
          <div>Item factory details not available.</div>
        )}
      </Grid.Item>
    </Grid.Container>
  )
}

export default ItemFactoryDetails
