import { Button, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { FunctionComponent } from 'react'
import { captureChangeRequestExportEvent } from '../../services/analytics/events-service'
import EnterpriseIcon, { DownloadIcon } from '@enterprise-ui/icons'

interface ExportGridProps {
  getGridApi: () => any
  getColumnApi: () => any
  fileName: string
  sheetName: string
  additionalColumns?: string[]
}

const ExportGrid: FunctionComponent<ExportGridProps> = ({
  getGridApi,
  getColumnApi,
  fileName,
  sheetName,
}) => {
  const generateColumnsForExcel = () => {
    const keys = getColumnApi()
      .getColumns()
      .map((column: any) => column.getColId())
    return keys
  }
  const exportExcel = () => {
    captureChangeRequestExportEvent()
    getGridApi().exportDataAsExcel({
      columnKeys: generateColumnsForExcel(),
      columnWidth: 150,
      sheetName: sheetName,
      fileName: fileName,
      processCellCallback: (params: any) => {
        const colDef = params.column.getColDef()
        if (colDef.valueFormatter) {
          const valueFormatterParams = {
            ...params,
            data: params.node.data,
            node: params.node!,
            colDef: params.column.getColDef(),
          }
          return colDef.valueFormatter(valueFormatterParams)
        }
        return params.value
      },
    })
  }
  return (
    <div>
      <Tooltip content="Export data to excel">
        <Button onClick={exportExcel} type="ghost">
          <EnterpriseIcon icon={DownloadIcon} />
        </Button>
      </Tooltip>
    </div>
  )
}

export { ExportGrid }
