import React, { useEffect, useState } from 'react'
import { ItemDetailsType, ItemVendor } from '../../../types/ItemDetailsType'
import { useGrid } from '../../../hooks/useGrid'
import { gridOptions } from './GridOptions'
import { BulkChangeRequestColumDefs } from './ColumnDefinition'
import FactorialGrid from '../../FactorialGrid/FactorialGrid'
import { AutoCompleteOptionType } from '../../../types/AutoCompleteOptionType'
import { Dialog } from '@enterprise-ui/canvas-ui-react'
import ItemDetailsModal from '../ItemDetailsModal'
import { useItemDetailsModal } from '../../../context/ItemDetailsModalContext'
import ClearAllFilters from '../../FactorialGrid/ClearAllFilters'

interface BulkChangeRequestItemDetailsGridProps {
  itemDetails: ItemDetailsType[]
  selectedVendor?: AutoCompleteOptionType
  setSelectedVendor: (selectedVendor: AutoCompleteOptionType) => void
}
const BulkChangeRequestItemDetailsGrid: React.FC<
  BulkChangeRequestItemDetailsGridProps
> = (props: BulkChangeRequestItemDetailsGridProps) => {
  const { gridReady, onGridReady, getGridApi } = useGrid()
  const { selectedTcin } = useItemDetailsModal()

  const [gridItems, setGridItems] = useState<ItemDetailsType[]>([])
  const [showWarning, setShowWarning] = useState(false)
  const [disableClearFilter, setDisableClearFilter] = useState(true)

  const gridWidth = '100%'
  const bulkChangeRequestItemDetailsGridOptions = {
    ...gridOptions,
    onGridReady: onGridReady,
    onFilterChanged: (filter: any) => {
      Object.keys(filter.api.getFilterModel()).length === 0
        ? setDisableClearFilter(true)
        : setDisableClearFilter(false)
    },
  }

  useEffect(() => {
    if (gridReady) {
      getGridApi().setColumnDefs(BulkChangeRequestColumDefs)
    }
  }, [gridReady, getGridApi])

  useEffect(() => {
    setGridItems(
      props.itemDetails.filter((item: ItemDetailsType) => {
        return props.selectedVendor && props.selectedVendor.id
          ? item.vendors
              .map((v: ItemVendor) => v.id)
              .includes(props.selectedVendor?.id)
          : true
      }),
    )

    if (
      props.itemDetails.length !== gridItems.length &&
      gridItems.length !== 0 &&
      props.selectedVendor &&
      props.itemDetails.length - gridItems.length > 0
    ) {
      setShowWarning(true)
    }
  }, [props.itemDetails, props.selectedVendor, gridItems.length])
  useEffect(() => {
    if (gridReady) {
      getGridApi().setRowData(Object.values(gridItems))
    }
  }, [gridReady, gridItems, getGridApi])
  return (
    <div
      style={{
        padding: '1px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'left',
        background: '#efefef',
      }}
    >
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <div>
          <ClearAllFilters gridApi={getGridApi} disable={disableClearFilter} />
        </div>
      </div>
      <FactorialGrid
        gridOptions={bulkChangeRequestItemDetailsGridOptions}
        gridWidth={gridWidth}
      />
      <Dialog
        headingText={
          props.itemDetails.length -
          gridItems.length +
          ' items removed because selected vendor doesn’t have those items. Are you sure you want to proceed?'
        }
        isVisible={
          showWarning && props.itemDetails.length - gridItems.length > 0
        }
        refuseButtonText="Cancel"
        onRefuse={() => {
          props.setSelectedVendor({ id: '', value: '', label: '' })
          setShowWarning(false)
        }}
        approveButtonText="Yes"
        onApprove={() => {
          setShowWarning(false)
        }}
      />
      <ItemDetailsModal
        itemDetails={
          props.itemDetails.filter((item: ItemDetailsType) => {
            return item.tcin === selectedTcin
          })[0]
        }
      />
    </div>
  )
}

export default BulkChangeRequestItemDetailsGrid
