export const distinctStr = (deptList: string[]) => {
  const deptL: string[] = []
  deptList.forEach((dept) => {
    deptL.push(' ' + dept.substring(0, dept.indexOf('-')).trim())
  })
  return deptL.filter(onlyUnique)
}
function onlyUnique(value: any, index: any, self: string | any[]) {
  return self.indexOf(value) === index
}
