import React, { ReactNode, useCallback, useEffect } from 'react'
import { useState } from 'react'

const TourContext = React.createContext<any>(null)

interface Props {
  children: ReactNode
}

const TourContextProvider = ({ children }: Props) => {
  const [tourSteps, setTourSteps] = useState<number[]>([])
  const [isTourEnabled, setIsTourEnabled] = useState<boolean>(false)
  const [currentTourStep, setCurrentTourStep] = useState<number>(tourSteps[0])
  const [currentStep, setCurrentStep] = useState<number>(0)

  useEffect(() => {
    setCurrentTourStep(tourSteps[0])
  }, [tourSteps])

  const startTour = useCallback(
    (tourSteps: number[]) => {
      setCurrentStep(0)
      setTourSteps(tourSteps)
      setIsTourEnabled(true)
    },
    [setCurrentStep, setTourSteps, setIsTourEnabled],
  )

  useEffect(() => {
    if (tourSteps[currentStep] > -1) {
      setCurrentTourStep(tourSteps[currentStep])
    } else {
      setIsTourEnabled(false)
    }
  }, [tourSteps, currentStep, setCurrentTourStep])

  const nextTourStep = useCallback(() => {
    setCurrentStep((prev: number) => {
      return prev + 1
    })
  }, [setCurrentStep])

  return (
    <TourContext.Provider
      value={{
        tourSteps,
        setTourSteps,
        startTour,
        isTourEnabled,
        setIsTourEnabled,
        currentTourStep,
        nextTourStep,
      }}
    >
      {children}
    </TourContext.Provider>
  )
}

const useTour = (): {
  tourSteps: number
  setTourSteps: (view: number) => void
  startTour: (steps: number[]) => void
  isTourEnabled: boolean
  setIsTourEnabled: (isTourEnabled: boolean) => void
  currentTourStep: number
  nextTourStep: () => void
} => {
  return React.useContext(TourContext)
}

export { TourContextProvider, useTour }
