import React from 'react'
import { Tooltip, Button } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { FilterIcon } from '@enterprise-ui/icons'

interface ClearAllFiltersProps {
  gridApi: any
  disable: boolean
}
const ClearAllFilters: React.FC<ClearAllFiltersProps> = ({
  gridApi,
  disable,
}) => {
  const clearFilter = () => {
    gridApi().setFilterModel(null)
    gridApi().onFilterChanged()
  }

  return (
    <Tooltip location="top" content="Clear All Filters">
      <Button
        type="ghost"
        onClick={() => {
          clearFilter()
        }}
        disabled={disable}
      >
        <EnterpriseIcon icon={FilterIcon} />
      </Button>
    </Tooltip>
  )
}

export default ClearAllFilters
