import localConfigInternal from './local/localConfigInternal'
import { AppConfigType, AuthConfigType } from '../types/AppConfigType'

let appConfig: AppConfigType = {
  internalBaseUrl: '',
  externalBaseUrl: '',
  apiKey: '',
  internalAuth: {
    clientId: '',
    authorizationUrl: '',
    logoutUrl: '',
  },
  externalAuth: {
    clientId: '',
    authorizationUrl: '',
    logoutUrl: '',
  },
  internalADGroupUser: '',
  internalADGroupAdmin: '',
  externalVdsGroup: '',
  internalHostName: '',
  analyticsTrackingId: '',
  firefly: {
    internalUrl: '',
    externalUrl: '',
  },
  smartAssist: {
    baseUrl: '',
    confluenceSpace: '',
    environment: 'dev',
  },
  version: 'V1.0',
  idleTimeout: 1000 * 60 * 240,
  releaseNotes: {
    recentReleaseDate: '01-01-9999',
    releaseNotesContentId: '308348427',
    releaseNotesTitle: 'Release Notes',
  },
}

const hostname = window && window.location && window.location.hostname

const setAppConfig = (appCnfg: AppConfigType) => {
  appConfig = appCnfg
}

const getAppConfig = (): AppConfigType => {
  return appConfig
}

const getAuthConfig = (): AuthConfigType => {
  return hostname === appConfig.internalHostName
    ? appConfig.internalAuth
    : appConfig.externalAuth
}

const getBaseUrl = (): string => {
  return hostname === appConfig.internalHostName
    ? appConfig.internalBaseUrl
    : appConfig.externalBaseUrl
}

const getFireflyUrl = (): string => {
  return hostname === appConfig.internalHostName
    ? appConfig.firefly.internalUrl
    : appConfig.firefly.externalUrl
}

const initializeAppConfig = async () => {
  const appEnv = process?.env?.REACT_APP_FACTORIAL_ENV
  if (appEnv === 'local' || window.location.href.indexOf('localhost') > -1) {
    return Promise.resolve(localConfigInternal).then((config) => {
      setAppConfig(config)
      return config
    })
  } else {
    // call /app-environment
    return fetch('/app-environment')
      .then((response) => response.json())
      .then((data) => buidAppConfigFromResponse(data))
      .then((appCnfg) => {
        setAppConfig(appCnfg)
        return appCnfg
      })
  }
}

const buidAppConfigFromResponse = (cnfg: any): AppConfigType => {
  return {
    internalBaseUrl: cnfg['internalBaseUrl'],
    externalBaseUrl: cnfg['externalBaseUrl'],
    apiKey: cnfg['apiKey'],
    internalAuth: {
      clientId: cnfg['internalAuth']['clientId'],
      authorizationUrl: cnfg['internalAuth']['authorizationUrl'],
      logoutUrl: cnfg['internalAuth']['logoutUrl'],
    },
    externalAuth: {
      clientId: cnfg['externalAuth']['clientId'],
      authorizationUrl: cnfg['externalAuth']['authorizationUrl'],
      logoutUrl: cnfg['externalAuth']['logoutUrl'],
    },
    internalADGroupUser: cnfg['internalADGroupUser'],
    internalADGroupAdmin: cnfg['internalADGroupAdmin'],
    externalVdsGroup: cnfg['externalVdsGroup'],
    internalHostName: cnfg['internalHostName'],
    analyticsTrackingId: cnfg['analyticsTrackingId'],
    firefly: {
      internalUrl: cnfg['firefly']['internalUrl'],
      externalUrl: cnfg['firefly']['externalUrl'],
    },
    smartAssist: {
      baseUrl: cnfg['smartAssist']['baseUrl'],
      confluenceSpace: cnfg['smartAssist']['confluenceSpace'],
      environment: cnfg['smartAssist']['environment'],
    },
    version: cnfg['version'],
    idleTimeout: cnfg['idleTimeout'],
    releaseNotes: {
      recentReleaseDate: cnfg['releaseNotes']['recentReleaseDate'],
      releaseNotesContentId: cnfg['releaseNotes']['releaseNotesContentId'],
      releaseNotesTitle: cnfg['releaseNotes']['releaseNotesTitle'],
    },
  }
}

export {
  initializeAppConfig,
  getAppConfig,
  setAppConfig,
  getAuthConfig,
  getBaseUrl,
  getFireflyUrl,
}
