import { AutoCompleteOptionType } from '../types/AutoCompleteOptionType'
import { Location } from '../types/BusinessPartnerType'
import {
  FactoryTimelineItem,
  ItemDetailsType,
  LeanFactory,
  VendorFactoryTimelines,
} from '../types/ItemDetailsType'
import _ from 'lodash'

export const convertToFactoryOptions = (
  locations: Location[],
): AutoCompleteOptionType[] => {
  return (
    locations &&
    locations.map((location: Location) => {
      return {
        id: location.location_id,
        value: location.location_name,
        label: location.location_id + ' - ' + location.location_name,
      }
    })
  )
}

export const getCop = (factoryId: string, locations?: Location[]) => {
  const location =
    locations &&
    locations.filter((location: Location) => {
      return location.location_id === factoryId
    })[0]
  return location
    ? location.country_name + ' (' + location.country_code + ')'
    : ''
}

export const getValidFactoryOptionsFromItemDetails = (
  itemDetails: ItemDetailsType[],
  startDate: Date,
  endDate: Date,
  vendorNumber: String,
): AutoCompleteOptionType[] => {
  const validLocations: AutoCompleteOptionType[] = []
  itemDetails.forEach((itemDetail: ItemDetailsType) => {
    const vendorFactoryTimelines = itemDetail.vendor_factory_timelines.filter(
      (vendorFactoryTimeline: VendorFactoryTimelines) => {
        return vendorFactoryTimeline.vendor_id === vendorNumber
      },
    )
    if (vendorFactoryTimelines && vendorFactoryTimelines.length > 0) {
      vendorFactoryTimelines[0].factory_timelines.forEach(
        (factoryTimeline: FactoryTimelineItem) => {
          if (
            factoryTimeline.effective_start_date <= startDate &&
            factoryTimeline.effective_end_date >= endDate
          ) {
            //valid factory
            factoryTimeline.factories.forEach((factory: LeanFactory) => {
              validLocations.push({
                id: factory.factory_id,
                value: factory.factory_name,
                label: factory.factory_id + ' - ' + factory.factory_name,
              })
            })
          }
        },
      )
    }
  })
  return _.uniqBy(validLocations, 'id')
}
