import React, { ReactNode } from 'react'
import { useState } from 'react'
import { UserType } from '../utils/Constants'
import { UserInfoType } from '../types/UserInfoType'

const UserInfoContext = React.createContext<any>(null)

const initialUserInfoState: UserInfoType = {
  user_id: '',
  user_name: '',
  email_id: '',
  company: '',
  user_type: UserType.EXTERNAL,
}

interface Props {
  children: ReactNode
}

const UserInfoContextProvider = ({ children }: Props) => {
  const [userInfo, setUserInfo] = useState<UserInfoType>(initialUserInfoState)

  return (
    <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </UserInfoContext.Provider>
  )
}

const useUserInfo = (): {
  userInfo: UserInfoType
  setUserInfo: (userInfo: UserInfoType) => void
} => {
  return React.useContext(UserInfoContext)
}

export { UserInfoContextProvider, useUserInfo }
