import { useState, useRef, useCallback } from 'react'

interface ResponseType {
  gridReady: boolean
  onGridReady(params: any): void
  getGridApi(): any
  getColumnApi(): any
}

export const useGrid = (): ResponseType => {
  const gridApi = useRef()
  const columnApi = useRef()
  const [gridReady, setGridReady] = useState<boolean>(false)

  const onGridReady = (params: any) => {
    gridApi.current = params.api
    columnApi.current = params.columnApi
    setGridReady(true)
  }

  const getColumnApi = useCallback(() => {
    return columnApi.current
  }, [])

  const getGridApi = useCallback(() => {
    return gridApi.current
  }, [])

  return {
    gridReady,
    onGridReady,
    getGridApi,
    getColumnApi,
  }
}
