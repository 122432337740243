import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useItemDetailsModal } from '../../context/ItemDetailsModalContext'

interface ItemFactoryDetailsButtonProps {
  tcin: string
  openModal?: boolean
}
const ItemFactoryDetailsButton: React.FC<ItemFactoryDetailsButtonProps> = (
  props: ItemFactoryDetailsButtonProps,
) => {
  const navigate = useNavigate()
  const { setShowItemDetailsModal, setSelectedTcin } = useItemDetailsModal()

  const openItemFactoryDetailsPage = (tcin: string) => {
    navigate('/item-factory-details?tcin=' + tcin)
  }

  return (
    <>
      <p
        style={{
          marginLeft: '10px',
          fontWeight: 'bold',
          textDecoration: 'underline',
          color: 'blue',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (props.openModal) {
            setSelectedTcin(props.tcin)
            setShowItemDetailsModal(true)
          } else {
            openItemFactoryDetailsPage(props.tcin)
          }
        }}
      >
        {props.tcin}
      </p>
    </>
  )
}

export default ItemFactoryDetailsButton
