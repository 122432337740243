import {
  Button,
  Form,
  Grid,
  Modal,
  ProgressBar,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import React, { useEffect, useState } from 'react'
import { useUserInfo } from '../../context/UserInfoContext'
import { AutoCompleteOptionType } from '../../types/AutoCompleteOptionType'
import { ReasonForChangeOptions, UserType } from '../../utils/Constants'
import EmailField from '../Common/EmailField'
import { CycleDescDropDown } from '../CycleDescDropDown/CycleDescDropDown'
interface CreateChangeRequestModalProps {
  isOpen: boolean
  currentUserEmail: string
  showFactorySelection?: boolean
  getFactoryList?: () => Promise<any>
  onClose: (isOpen: boolean) => void
  onSubmit: (
    reasonForChange: string,
    changeDescription: string,
    cycleDescription?: string[],
    internalContacts?: string[],
    externalContacts?: string[],
    vopChange?: Boolean,
  ) => Promise<any>
}
const SubmitChangeRequestModal: React.FC<CreateChangeRequestModalProps> = ({
  isOpen,
  currentUserEmail,
  onClose,
  onSubmit,
}) => {
  const { userInfo } = useUserInfo()
  const [reasonForChange, setReasonForChange] =
    useState<AutoCompleteOptionType>()
  const [changeDescription, setChangeDescription] = useState<string>()
  const [cycleDescription, setCycleDescription] = useState<string[]>()
  const [internalContacts, setInternalContacts] = useState<string[]>([])
  const [externalContacts, setExternalContacts] = useState<string[]>([
    currentUserEmail,
  ])
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [showErrors, setShowErrors] = useState<boolean>(false)
  const [vopChange] = useState<boolean>(false)

  const onSubmitRequest = () => {
    if (!isFormValid) {
      setShowErrors(true)
    } else {
      setInProgress(true)
      onSubmit(
        reasonForChange ? reasonForChange.id : '',
        changeDescription ?? '',
        cycleDescription,
        internalContacts,
        externalContacts,
        vopChange,
      ).then((resp) => {
        setInProgress(false)
      })
    }
  }

  useEffect(() => {
    let isFormValidTemp = true

    if (!reasonForChange || !changeDescription) {
      isFormValidTemp = false
    }

    if (userInfo.user_type === UserType.EXTERNAL) {
      if (internalContacts.length === 0 || externalContacts.length === 0) {
        isFormValidTemp = false
      }
    }

    setIsFormValid(isFormValidTemp)
  }, [
    reasonForChange,
    changeDescription,
    internalContacts,
    externalContacts,
    userInfo,
  ])

  return (
    <Modal headingText="Submit Changes" isVisible={isOpen} onRefuse={onClose}>
      {inProgress && <ProgressBar indeterminate />}
      <div className="hc-pa-normal">
        <Grid.Container className="hc-pl-expanded hc-pr-expanded">
          <Grid.Item xs={6} className="hc-pt-none hc-pb-none">
            <Autocomplete
              id="reasonForChange"
              options={ReasonForChangeOptions.map((value: string) => {
                return { id: value, value, label: value }
              })}
              required
              label="Reason for Change"
              errorText="Please select a reason."
              error={showErrors ? (reasonForChange ? false : true) : false}
              value={reasonForChange}
              onUpdate={(id, value) => {
                setReasonForChange(value)
              }}
            />
          </Grid.Item>
          <Grid.Item xs={6} className="hc-pt-none hc-pb-none">
            <Form.Field
              id="reasonForChangeDescription"
              label="Change Description"
              type="textarea"
              required
              size="dense"
              errorText="Please describe the reason for change."
              error={showErrors ? (changeDescription ? false : true) : false}
              value={changeDescription}
              onChange={(e: any) => {
                setChangeDescription(e.target.value)
              }}
            />
          </Grid.Item>
          <Grid.Item xs={6} className="hc-pt-none hc-pb-none">
            <CycleDescDropDown onCycleDescChange={setCycleDescription} />
          </Grid.Item>

          {userInfo.user_type === UserType.EXTERNAL && (
            <>
              <Grid.Item xs={12} className="hc-pt-none hc-pb-none">
                <EmailField
                  emails={internalContacts}
                  setEmails={setInternalContacts}
                  id="internalContacts"
                  label="Target Approver Email(s)"
                  errorText="Please enter a valid Target E-mail(@target.com) and press enter."
                  checkTargetDomain={true}
                  showErrorMessage={showErrors}
                />
              </Grid.Item>
              <Grid.Item xs={12} className="hc-pt-dense hc-pb-dense">
                <EmailField
                  emails={externalContacts}
                  setEmails={setExternalContacts}
                  id="externalContacts"
                  label="Vendor Contact Email(s)"
                  errorText="Please enter a valid E-mail and press enter."
                  checkTargetDomain={false}
                  showErrorMessage={showErrors}
                />
              </Grid.Item>
            </>
          )}
          <Grid.Item xs={12}>
            <Grid.Container direction="row-reverse" spacing="dense">
              <Grid.Item>
                <Button
                  type="primary"
                  onClick={onSubmitRequest}
                  disabled={inProgress}
                >
                  Submit
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button onClick={onClose} disabled={inProgress}>
                  CANCEL
                </Button>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
      </div>
    </Modal>
  )
}

export default SubmitChangeRequestModal
