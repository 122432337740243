import {
  Button,
  Grid,
  Modal,
  ProgressBar,
  Stepper,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { faCopy } from '@fortawesome/free-regular-svg-icons/faCopy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useState } from 'react'
import { useBusinessPartnerInfo } from '../../context/BusinessPartnerInfoContext'
import { useUserInfo } from '../../context/UserInfoContext'
import { submitChangeRequest } from '../../services/ChangeRequestService'
import { getItemDetails } from '../../services/ItemService'
import { AutoCompleteOptionType } from '../../types/AutoCompleteOptionType'
import { BulkChangeRequestType } from '../../types/BulkChangeRequestType'
import { Vendor } from '../../types/BusinessPartnerType'
import { ChangeRequestType } from '../../types/ChangeRequestType'
import {
  ItemDetailsType,
  ItemVendor,
  TcinVopChangeDetail,
} from '../../types/ItemDetailsType'
import { ItemVendorFactoryTimeline } from '../../types/ItemVendorFactoryType'
import { ReviewStatus } from '../../utils/Constants'
import { createEditableFactoryTimeline } from '../../utils/FactoriesTimelineUtil'
import {
  filterItemsWithPendingCRs,
  groupItemsByVendor,
} from '../BulkChangeRequest/BulkChangeRequestUtil'
import { cleanTimeline } from '../ChangeRequest/ChangeRequestUtil'
import './BulkEditVOPModal.scss'
import ChooseActiveFactories from './ChooseActiveFactories'
import ReviewVOPChangesGrid from './ReviewVOPChangesGrid'

interface BulkEditVOPModalProps {
  isOpen: boolean
  onClose: () => void
  tcins: string[]
  dept: string
}

const BulkEditVOPModal: React.FC<BulkEditVOPModalProps> = ({
  isOpen,
  onClose,
  tcins,
  dept,
}) => {
  const [activeStep, setActiveStep] = useState<string>('factory')
  const [isFetchingItemDetails, setIsFetchingItemDetails] =
    useState<boolean>(false)
  const [itemVendorFactoryTimelines, setItemVendorFactoryTimelines] = useState<
    ItemVendorFactoryTimeline[]
  >([])
  const [itemsByVendor, setItemsByVendor] = useState<{
    [key: string]: ItemDetailsType[]
  }>({})
  const [reviewVOPChangedForTcins, setreviewVOPChangedForTcins] = useState<
    TcinVopChangeDetail[]
  >([])
  const [tcinsWithoutCRs, setTcinsWithoutCRs] = useState<string[]>()
  const [itemDetails, setItemDetails] = useState<ItemDetailsType[]>([])
  let itemDetail: ItemDetailsType = itemDetails[0]

  const [fetchInProgress] = useState<boolean>(false)
  const [selectedVendor, setSelectedVendor] = useState<
    AutoCompleteOptionType[]
  >([])

  useEffect(() => {
    if (tcins && tcins.length > 0 && isOpen) {
      setIsFetchingItemDetails(true)
      getItemDetails({ tcins: tcins })
        .then((itemDetailsRes: ItemDetailsType[]) => {
          setIsFetchingItemDetails(false)
          setItemDetails(itemDetailsRes)
          if (itemDetailsRes && itemDetailsRes.length > 0) {
            const { itemsWithoutCRs } =
              filterItemsWithPendingCRs(itemDetailsRes)
            setTcinsWithoutCRs(itemsWithoutCRs.map((it) => it.tcin))
            setItemsByVendor(groupItemsByVendor(itemsWithoutCRs))
          }
        })
        .catch(() => {
          setIsFetchingItemDetails(false)
        })
    }
  }, [tcins, isOpen])

  useEffect(() => {
    if (itemDetail && itemDetail.vendors && itemDetail.vendors.length > 0) {
      setSelectedVendor(
        itemDetail.vendors
          .slice()
          .sort((vendor: ItemVendor) => {
            //Sort vendors if vendor have an open change request
            return itemDetail?.change_requests
              ?.map((change: ChangeRequestType) => {
                return change.gms_vendor_number
              })
              .includes(vendor.id)
              ? -1
              : 0
          })
          .filter((v: ItemVendor) => {
            return v.id !== '0'
          })
          .map((vendor: ItemVendor) => {
            return {
              id: vendor.id,
              value: vendor.name,
              label: vendor.name + ' - ' + vendor.id,
            }
          }),
      )
    }
  }, [itemDetail])

  const onModalClose = () => {
    onClose()
  }

  const showReview = () => {
    setActiveStep('Review')
  }

  const onBulkEditVOP = useCallback(
    (vopForTcinsChanged: TcinVopChangeDetail[]) => {
      setreviewVOPChangedForTcins(vopForTcinsChanged)
    },
    [],
  )
  const { userInfo } = useUserInfo()
  const { businessPartner } = useBusinessPartnerInfo()

  const onSubmitRequest = () => {
    setActiveStep('')
    const vendor = businessPartner.vendors.filter((vendor: Vendor) => {
      return vendor.gms_vendor_number === selectedVendor[0]!.id
    })
    const baseChangeRequest = {
      bp_id: businessPartner.bp_id,
      bp_name: businessPartner.bp_name,
      bulk_req_id: businessPartner.bp_id + Date.now(),
      cycle_description: [],
      reason_for_change: '',
      reason_for_change_description: '',
      change_request_initiator: { ...userInfo, company: undefined },
      review_status: ReviewStatus.APPROVED,
      internal_contacts: [],
      external_contacts: [],
    }

    const changeRequests: BulkChangeRequestType[] =
      itemVendorFactoryTimelines.map((itemVendorFactoryTimeline) => {
        const newTimeline = createEditableFactoryTimeline({
          factoryTimelineItems: itemVendorFactoryTimeline.factoryTimelineItems,
        })

        return {
          ...baseChangeRequest,
          tcin: itemVendorFactoryTimeline.tcin,
          dpci: itemVendorFactoryTimeline.dpci,
          vendor_id: vendor[0].vendor_id ?? '',
          vendor_name: vendor[0].vendor_name ?? '',
          gms_vendor_number: vendor[0].gms_vendor_number ?? '',
          has_vop_changed: true,
          factory_timelines: cleanTimeline(newTimeline.factoryTimelineItems),
          diff_factory_timelines: cleanTimeline(
            itemVendorFactoryTimeline.factoryTimelineItems,
          ),
          vop_timelines: cleanTimeline(
            itemVendorFactoryTimeline.factoryTimelineItems,
          ),
        }
      })

    submitChangeRequest(changeRequests).then((resp: any) => {
      onModalClose()
    })
  }
  return (
    <Modal
      headingText={`Bulk Edit - Vendor Order Point`}
      isVisible={isOpen}
      onRefuse={(e: any) => {
        if (e.target.nodeName !== 'DIV') {
          onModalClose()
        }
      }}
      className="bulk-edit-vop-modal"
    >
      <div className="hc-pa-normal">
        {isFetchingItemDetails && <ProgressBar indeterminate />}
        <Grid.Container justify="center">
          <Grid.Item xs={12}>
            <div style={{ overflowY: 'auto' }}>
              <div>
                <Stepper activeStep={activeStep}>
                  <Stepper.Item index={1} name="factory" completed>
                    Choose Factory and VOP
                  </Stepper.Item>
                  <Stepper.Item index={2} name="review and submit" completed>
                    Review
                  </Stepper.Item>
                  <Stepper.Item index={3} name="submit" completed>
                    Submit
                  </Stepper.Item>
                </Stepper>
                <div className="hc-pa-normal">
                  <p className="hc-ma-normal">
                    You have selected {tcinsWithoutCRs?.length} TCINS to edit
                    <span>
                      <Tooltip location="top" content="copy tcins to clipboard">
                        <Button
                          size="dense"
                          onClick={() =>
                            navigator.clipboard.writeText(
                              tcinsWithoutCRs?.join() ?? '',
                            )
                          }
                        >
                          <FontAwesomeIcon
                            className="hc-mr-dense"
                            icon={faCopy}
                            size="lg"
                          />
                        </Button>
                      </Tooltip>
                    </span>
                  </p>
                  {activeStep === 'factory' && (
                    <ChooseActiveFactories
                      onReview={showReview}
                      onBulkEditVOP={onBulkEditVOP}
                      onBulkChangeReq={(
                        itemVendorFactoryTimeline: ItemVendorFactoryTimeline[],
                      ) => {
                        setItemVendorFactoryTimelines(itemVendorFactoryTimeline)
                      }}
                      itemDetails={itemDetails}
                      vendorIds={Object.keys(itemsByVendor)}
                      dept={dept}
                    />
                  )}
                  {activeStep === 'Review' && (
                    <ReviewVOPChangesGrid
                      reviewVOPChangedForTcins={reviewVOPChangedForTcins}
                      onNext={() => {
                        onSubmitRequest()
                      }}
                      onPrev={() => {
                        setActiveStep('factory')
                      }}
                    />
                  )}
                  {!fetchInProgress && !itemDetails && (
                    <div>Item factory details not available.</div>
                  )}
                </div>
              </div>
            </div>
          </Grid.Item>
        </Grid.Container>
      </div>
    </Modal>
  )
}
export default BulkEditVOPModal
