export const getUrlSearchParams = (urlSearchQuery: string) => {
  const urlParams = new URLSearchParams(urlSearchQuery)
  //tcins
  const tcinsUrlParamsString = urlParams.get('tcins')
  const tcinsUrlParams =
    tcinsUrlParamsString && tcinsUrlParamsString.length > 0
      ? tcinsUrlParamsString.split(',')
      : []

  //dpcis
  const dpcisUrlParamsString = urlParams.get('dpcis')
  const dpcisUrlParams =
    dpcisUrlParamsString && dpcisUrlParamsString.length > 0
      ? dpcisUrlParamsString.split(',')
      : []

  //deptClass
  const deptClassUrlParam = urlParams.get('dept-class') ?? ''

  //vendorId
  const vendorIdsUrlParamsString = urlParams.get('vendor-ids')
  const vendorIdsUrlParam =
    vendorIdsUrlParamsString && vendorIdsUrlParamsString.length > 0
      ? vendorIdsUrlParamsString.split(',')
      : []

  //factoryId
  const factoryIdUrlParam = urlParams.get('factory-id') ?? ''

  //factoryName
  const factoryNameUrlParam = urlParams.get('factory-name') ?? ''

  //manufacturer_styles
  const manufacturerStylesUrlParamsString = urlParams.get('manufacturer-styles')
  const manufacturerStylesUrlParam =
    manufacturerStylesUrlParamsString &&
    manufacturerStylesUrlParamsString.length > 0
      ? manufacturerStylesUrlParamsString.split(',')
      : []

  //PIDS
  const pidsUrlParamsString = urlParams.get('pids')
  const pidsUrlParam =
    pidsUrlParamsString && pidsUrlParamsString.length > 0
      ? pidsUrlParamsString.split(',')
      : []

  //no factory only
  const noFactoryOnlyUrlParamsString = urlParams.get('no-factory-only')
  const noFactoryOnlyParam = noFactoryOnlyUrlParamsString
    ? noFactoryOnlyUrlParamsString
    : false

  //invalid Factory only
  const invalidFactoryOnlyUrlParamsString = urlParams.get(
    'invalid-factory-only',
  )
  const invalidFactoryOnlyParam = invalidFactoryOnlyUrlParamsString
    ? invalidFactoryOnlyUrlParamsString
    : false

  //no vop only
  const noVopOnlyUrlParamsString = urlParams.get('no-vop-only')
  const noVopOnlyParam = noVopOnlyUrlParamsString
    ? noVopOnlyUrlParamsString
    : false

  // pcn event ids
  const pcnEventIdsUrlParamUrlParamsString = urlParams.get('pcn-event-ids')
  const pcnEventIdsUrlParam =
    pcnEventIdsUrlParamUrlParamsString &&
    pcnEventIdsUrlParamUrlParamsString.length > 0
      ? pcnEventIdsUrlParamUrlParamsString.split(',')
      : []

  // show all tcins from pcn event
  const showAllPcnEventTcinsUrlParamsString = urlParams.get(
    'show-all-pcn-event-tcins',
  )
  const showAllPcnEventTcinsUrlParam =
    showAllPcnEventTcinsUrlParamsString &&
    showAllPcnEventTcinsUrlParamsString.length > 0
      ? showAllPcnEventTcinsUrlParamsString
      : false

  return {
    tcinsUrlParams,
    dpcisUrlParams,
    deptClassUrlParam,
    vendorIdsUrlParam,
    manufacturerStylesUrlParam,
    pidsUrlParam,
    factoryIdUrlParam,
    factoryNameUrlParam,
    noFactoryOnlyParam,
    invalidFactoryOnlyParam,
    noVopOnlyParam,
    pcnEventIdsUrlParam,
    showAllPcnEventTcinsUrlParam,
  }
}

export const createUrlSearchQuery = (
  tcins: string[] = [],
  dpcis: string[] = [],
  deptClass: string = '',
  vendorIds: string[] = [],
  manufacturerStyles: string[] = [],
  pids: string[] = [],
  factoryId: string = '',
  factoryName: string = '',
  noFactoryOnly: boolean,
  invalidFactoryOnly: boolean,
  noVopOnly: boolean,
  pcnEventIds: string[] = [],
  showAllPcnEventTcins: boolean | null,
) => {
  let searchQueryStringTemp = ''
  if (tcins && tcins.length > 0) {
    searchQueryStringTemp = searchQueryStringTemp + '?tcins=' + tcins
  }
  if (dpcis && dpcis.length > 0) {
    searchQueryStringTemp = searchQueryStringTemp
      ? searchQueryStringTemp + '&dpcis=' + dpcis
      : searchQueryStringTemp + '?dpcis=' + dpcis
  }
  if (deptClass) {
    searchQueryStringTemp = searchQueryStringTemp
      ? searchQueryStringTemp + '&dept-class=' + deptClass
      : searchQueryStringTemp + '?dept-class=' + deptClass
  }

  if (vendorIds && vendorIds.length > 0) {
    searchQueryStringTemp = searchQueryStringTemp
      ? searchQueryStringTemp + '&vendor-ids=' + vendorIds
      : searchQueryStringTemp + '?vendor-ids=' + vendorIds
  }
  if (manufacturerStyles && manufacturerStyles.length > 0) {
    searchQueryStringTemp = searchQueryStringTemp
      ? searchQueryStringTemp + '&manufacturer-styles=' + manufacturerStyles
      : searchQueryStringTemp + '?manufacturer-styles=' + manufacturerStyles
  }
  if (pids && pids.length > 0) {
    searchQueryStringTemp = searchQueryStringTemp
      ? searchQueryStringTemp + '&pids=' + pids
      : searchQueryStringTemp + '?pids=' + pids
  }
  if (factoryId) {
    searchQueryStringTemp = searchQueryStringTemp
      ? searchQueryStringTemp + '&factory-id=' + factoryId
      : searchQueryStringTemp + '?factory-id=' + factoryId
  }
  if (factoryName) {
    searchQueryStringTemp = searchQueryStringTemp
      ? searchQueryStringTemp +
        '&factory-name=' +
        encodeURIComponent(factoryName)
      : searchQueryStringTemp +
        '?factory-name=' +
        encodeURIComponent(factoryName)
  }

  if (noFactoryOnly) {
    searchQueryStringTemp = searchQueryStringTemp
      ? searchQueryStringTemp + '&no-factory-only=' + true
      : searchQueryStringTemp + '?no-factory-only=' + true
  }

  if (invalidFactoryOnly) {
    searchQueryStringTemp = searchQueryStringTemp
      ? searchQueryStringTemp + '&invalid-factory=' + true
      : searchQueryStringTemp + '?invalid-factory=' + true
  }
  if (noVopOnly) {
    searchQueryStringTemp = searchQueryStringTemp
      ? searchQueryStringTemp + '&no-vop-only=' + true
      : searchQueryStringTemp + '?no-vop-only=' + true
  }

  if (pcnEventIds && pcnEventIds.length > 0) {
    searchQueryStringTemp = searchQueryStringTemp
      ? searchQueryStringTemp + '&pcn-event-ids=' + pcnEventIds
      : searchQueryStringTemp + '?pcn-event-ids=' + pcnEventIds
  }

  if (showAllPcnEventTcins) {
    searchQueryStringTemp = searchQueryStringTemp
      ? searchQueryStringTemp + '&show-all-pcn-event-tcins=' + true
      : searchQueryStringTemp + '?show-all-pcn-event-tcins=' + true
  }

  return searchQueryStringTemp
}

export const getUrlParamsForAllChangeRequests = (urlQuery: string) => {
  const urlParams = new URLSearchParams(urlQuery)

  const assignedTo = urlParams.get('assignedTo')
  const pendingOnly: boolean =
    urlParams.get('pendingOnly') === 'false' ? false : true

  return {
    assignedTo,
    pendingOnly,
  }
}

export const createUrlQueryForAllChangeRequests = (
  assignedToMeOnly: boolean,
  pendingOnly: boolean,
) => {
  let changeRequestsUrlQuery = ''

  if (!assignedToMeOnly) {
    changeRequestsUrlQuery = '?assignedTo=all'
  }

  if (!pendingOnly) {
    if (changeRequestsUrlQuery.length > 0) {
      changeRequestsUrlQuery = changeRequestsUrlQuery + '&pendingOnly=false'
    } else {
      changeRequestsUrlQuery = changeRequestsUrlQuery + '?pendingOnly=false'
    }
  }

  return changeRequestsUrlQuery
}
