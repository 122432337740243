import React, { FunctionComponent, useEffect, useState } from 'react'
import { useGrid } from '../../../hooks/useGrid'
import FactorialGrid from '../../FactorialGrid/FactorialGrid'
import { gridOptions } from './GridOptions'
import { AllChangeRequestsColumDefs } from './ColumnDefintions'
import { ChangeRequestType } from '../../../types/ChangeRequestType'
import './AllChangeRequestsGrid.scss'
import ClearAllFilters from '../../FactorialGrid/ClearAllFilters'
import FactorialSpotlight from '../../Tour/FactorialSpotlight'
import { SpotlightIds } from '../../Tour/SpotlightIds'
import { ExportGrid } from '../../Export/ExportGrid'

type PendingApprovalsGridPropsType = {
  pendingOnly: boolean
  pendingApprovalsData: ChangeRequestType[]
  setSelectedChangeRequests: (
    selectedChangeRequests: ChangeRequestType[],
  ) => void
}

const AllChangeRequestsGrid: FunctionComponent<
  PendingApprovalsGridPropsType
> = ({ pendingOnly, pendingApprovalsData, setSelectedChangeRequests }) => {
  const { gridReady, onGridReady, getGridApi, getColumnApi } = useGrid()
  const [disableClearFilter, setDisableClearFilter] = useState(true)
  const gridWidth = '100%'
  const pendingApprovalsGridOptions = {
    ...gridOptions,
    onGridReady: onGridReady,
    onSelectionChanged: (params: any) => {
      const selectedChangeRequests = params.api.getSelectedRows()
      setSelectedChangeRequests(selectedChangeRequests)
    },
    onFilterChanged: (filter: any) => {
      Object.keys(filter.api.getFilterModel()).length === 0
        ? setDisableClearFilter(true)
        : setDisableClearFilter(false)
    },
  }

  useEffect(() => {
    if (gridReady) {
      getGridApi().setColumnDefs(AllChangeRequestsColumDefs(pendingOnly))
    }
  }, [gridReady, getGridApi, pendingOnly])

  useEffect(() => {
    if (gridReady) {
      getGridApi().setRowData(Object.values(pendingApprovalsData))
    }
  }, [gridReady, pendingApprovalsData, getGridApi])

  return (
    <>
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <div>
          <ExportGrid
            getGridApi={getGridApi}
            getColumnApi={getColumnApi}
            sheetName="Change Requests"
            fileName="Change Requests.xlsx"
          />
        </div>
        <div>
          <FactorialSpotlight
            spotlightId={SpotlightIds.ClearAllFilters2}
            contentWidth="sm"
            contentLocation="bottom-left"
          />
          <ClearAllFilters gridApi={getGridApi} disable={disableClearFilter} />
        </div>
      </div>
      <div className="all-change-requests-grid">
        <FactorialSpotlight
          spotlightId={SpotlightIds.TcinClickForChangeRequestDetails}
          contentWidth="lg"
          contentLocation="bottom-left"
          key="tour_tcin_click"
        />
        <FactorialSpotlight
          spotlightId={SpotlightIds.AgGridFilters2}
          contentWidth="lg"
          contentLocation="bottom-right"
          key="tour_filters"
        />
        <FactorialGrid
          gridOptions={pendingApprovalsGridOptions}
          gridWidth={gridWidth}
        />
      </div>
    </>
  )
}
export default AllChangeRequestsGrid
