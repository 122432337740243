import React from 'react'
import { FactoryTimeline } from '../../../types/ItemDetailsType'
import { Timeline } from '@enterprise-ui/canvas-ui-react'
import { Location } from '../../../types/BusinessPartnerType'
import FactoryList from '../Helpers/FactoryList'
import { sortFactoryTimelineItems } from '../../../utils/FactoriesTimelineUtil'

interface ViewFactoriesTimelineProps {
  factoryTimeline: FactoryTimeline
  locations?: Location[]
  changeReason?: string
  changeRequestedBy?: string
  isDateAdjusted?: boolean
}

export const ViewFactoriesTimeline: React.FC<ViewFactoriesTimelineProps> = ({
  factoryTimeline,
  locations,
  changeReason,
  changeRequestedBy,
  isDateAdjusted = false,
}) => {
  const hasEmptyTimeline = factoryTimeline.factoryTimelineItems.length === 0
  const sortedFactoryTimelineItems = sortFactoryTimelineItems(
    factoryTimeline.factoryTimelineItems,
  )
  return (
    <>
      {!hasEmptyTimeline && (
        <Timeline>
          {sortedFactoryTimelineItems.map((factoryTimelineItem) => (
            <Timeline.Item
              key={factoryTimelineItem.effective_start_date.getTime()}
            >
              <FactoryList
                factories={factoryTimelineItem.factories}
                effectiveStartDate={factoryTimelineItem.effective_start_date}
                effectiveEndDate={factoryTimelineItem.effective_end_date}
                locations={locations}
                editable={false}
                changeReason={changeReason}
                changeRequestedBy={changeRequestedBy}
                isDateAdjusted={isDateAdjusted}
                vopChanged={false}
              />
            </Timeline.Item>
          ))}
        </Timeline>
      )}
      {hasEmptyTimeline && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '20px',
          }}
        >
          <p>No Factories to display. Click Edit to Add Factories</p>
        </div>
      )}
    </>
  )
}
