import React, { FunctionComponent } from 'react'
import '../../styles/grid.scss'
import './applyLicense'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { AgGridReact } from '@ag-grid-community/react'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'

export const defaultGridModules = [
  ClientSideRowModelModule,
  StatusBarModule,
  SetFilterModule,
  MultiFilterModule,
  ClipboardModule,
  RowGroupingModule,
  ColumnsToolPanelModule,
  ExcelExportModule,
  MenuModule,
]

interface FactorialGridProps {
  gridOptions: any
  gridWidth: string
}

const FactorialGrid: FunctionComponent<FactorialGridProps> = ({
  gridOptions,
  gridWidth,
}) => {
  const statusBar = {
    statusPanels: [
      {
        statusPanel: 'agSelectedRowCountComponent',
        align: 'left',
      },
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'left',
      },
    ],
  }

  return (
    <>
      <div
        className="ag-theme-material ag-grid-react non-editable"
        style={{ height: 'calc(100vh - 420px)', width: gridWidth }}
      >
        <AgGridReact
          modules={[...defaultGridModules]}
          gridOptions={gridOptions}
          statusBar={statusBar}
        ></AgGridReact>
      </div>
    </>
  )
}

export default FactorialGrid
