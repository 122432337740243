import { SpotlightIds } from './SpotlightIds'

const SpotlightContent: { [key: number]: any } = {}

SpotlightContent[SpotlightIds.NavigateToSearch] = [
  {
    copy: 'Click Menu icon to see navigation links. Then click on Search link',
    title: 'Navigate to Search page',
  },
]

SpotlightContent[SpotlightIds.NavigateToChangeRequests] = [
  {
    copy: 'Click Menu icon to see navigation links. Then click on Change Requests link',
    title: 'Navigate to Change Requests page',
  },
]

SpotlightContent[SpotlightIds.NavigateToItemFactoryDetails] = [
  {
    copy: 'To Edit factories, you should be on Item Factory Details page. To navigate to Item Factory Details page, click on TCIN in Search Results ',
    title: 'Navigate to Item Factory Details',
  },
]

SpotlightContent[SpotlightIds.ClearAllFilters] = [
  {
    copy: 'Clears all filters applied on Grid columns in one click',
    title: 'Clear All Filters',
  },
]

SpotlightContent[SpotlightIds.TcinSearchBox] = [
  {
    copy: 'You can search for multiple TCINs or DPCIs by separating them by comma. ',
    title: 'Search By Tcins or DPCIs',
  },
]
SpotlightContent[SpotlightIds.TcinClickForFactoryDetails] = [
  {
    copy: 'Click on TCIN to see factory details for the TCIN . ',
    image: {
      alt: 'Image showing TCIN link',
      src: '/tour_tcin_click.png',
    },
    title: 'To see factories',
  },
]
SpotlightContent[SpotlightIds.AgGridFilters] = [
  {
    copy: 'Hover over the header and click menu icon to apply filters ',
    image: {
      alt: 'Image showing how to apply filters',
      src: '/tour_filters.png',
    },
    title: 'Filter rows',
  },
]

SpotlightContent[SpotlightIds.ChangeRequestsFilter] = [
  {
    copy: 'Filter by Status/Assigned settings lets you limit the data that you want to load on to the screen. ',
    title: 'Control data you want to load',
  },
]

SpotlightContent[SpotlightIds.TcinClickForChangeRequestDetails] = [
  {
    copy: 'Click on TCIN to see what is changed for this TCIN . ',
    image: {
      alt: 'Image showing TCIN link',
      src: '/tour_tcin_click.png',
    },
    title: 'To see detailed Change Request',
  },
]

SpotlightContent[SpotlightIds.EditMode] = [
  {
    copy: 'Factories can be edited in Edit mode only',
    title: 'Switch to Edit mode',
  },
]

SpotlightContent[SpotlightIds.AddNewFactory] = [
  {
    copy: 'Click on Add Factory to add factory to the timeline',
    title: 'To Add factory',
  },
]

SpotlightContent[SpotlightIds.EditFactory] = [
  {
    copy: 'Click on pencil icon to remove/replace this factory',
    title: 'Replace/Remove factory',
  },
]

SpotlightContent[SpotlightIds.ClearAllFilters2] =
  SpotlightContent[SpotlightIds.ClearAllFilters]

SpotlightContent[SpotlightIds.AgGridFilters2] =
  SpotlightContent[SpotlightIds.AgGridFilters]

export default SpotlightContent
