import { LicenseManager } from '@ag-grid-enterprise/core'

export const AG_GRID_LICENSE_KEY =
  'Using_this_AG_Grid_Enterprise_key_( AG-047189 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Target Corporation )_is_granted_a_( Multiple Applications )_Developer_License_for_( 20 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 15 September 2025 )____[v2]_MTc1Nzg5MDgwMDAwMA==7193053fffe8ee74ef38be9fa147268d'

var licenseApplied = false

export const applyLicense = (licenseKey?: string) => {
  if (!licenseApplied) {
    LicenseManager.setLicenseKey(licenseKey ?? AG_GRID_LICENSE_KEY)
    // eslint-disable-next-line no-console
    console.log('ag-grid license applied')
    licenseApplied = true
  }
}

applyLicense()
