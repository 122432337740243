import { AppConfigType } from '../../types/AppConfigType'

const localConfigInternal: AppConfigType = {
  // internalBaseUrl: 'http://localhost:8080/item_factories/v1',
  internalBaseUrl: 'https://factorial-api.dev.target.com/item_factories/v1',
  externalBaseUrl: 'https://factorial-api.dev.target.com/item_factories/v1',
  apiKey: '9e96e98eb1797d84445f3f4f0a526b4f9c79823b',
  internalAuth: {
    clientId: 'factorial_npe_im',
    authorizationUrl:
      'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
    logoutUrl:
      'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
  },
  externalAuth: {
    clientId: 'factorial_npe_im',
    authorizationUrl:
      'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/we2w/1',
    logoutUrl:
      'https://logonservices.oauth.iam.perf.partnersonline.com/login/responses/logoff.html',
  },
  internalADGroupUser: 'APP-OAUTH2-Factorial-USER-STG',
  internalADGroupAdmin: 'APP-OAUTH2-Factorial-ADMN-STG',
  externalVdsGroup: 'APP-PCN',
  analyticsTrackingId: 'UA-170245681-1',
  firefly: {
    internalUrl: 'https://stgapi-internal.target.com/internal_app_analytics/v3',
    externalUrl: 'https://stgapi-internal.target.com/internal_app_analytics/v3', // TEMPORARY only for local development to avoid CORS issue
  },
  // For Internal Local Development
  internalHostName: 'localhost',
  // For External Local Development
  // internalHostName: 'factorial-external.dev.target.com',
  smartAssist: {
    baseUrl:
      'https://smartassistservice-dev.dev.target.com/smart_assist_documents/v1',
    confluenceSpace: 'FACTHELP',
    environment: 'dev',
  },
  version: 'V1.0',
  idleTimeout: 1000 * 60 * 240, //4hrs
  releaseNotes: {
    // intentionally set to past date so that the release notes modal do not show up during cypress tests
    recentReleaseDate: '01-01-1900',
    releaseNotesContentId: '308348427',
    releaseNotesTitle: 'Release Notes',
  },
}

export default localConfigInternal
