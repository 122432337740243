import { Grid, Image } from '@enterprise-ui/canvas-ui-react'
import React from 'react'
import { Brand, ItemDetailsType } from '../../types/ItemDetailsType'
import LabelValue from '../Common/LabelValue'

interface ItemDetailsProps {
  itemDetails: ItemDetailsType
}
const ItemDetails: React.FC<ItemDetailsProps> = (props: ItemDetailsProps) => {
  const itemDetails = props.itemDetails

  return (
    itemDetails && (
      <div className="hc-pa-dense">
        <Grid.Container>
          <Grid.Item xs={12}>
            {itemDetails.images ? (
              <Image
                width="50%"
                height="50%"
                src={
                  itemDetails.images &&
                  itemDetails.images.base_url + itemDetails.images.primary_image
                }
                alt={itemDetails.description}
              />
            ) : (
              'No Image Found'
            )}
          </Grid.Item>
          <Grid.Item xs={3} className="hc-pl-none">
            <LabelValue label="TCIN" value={itemDetails.tcin} />
          </Grid.Item>
          <Grid.Item xs={3} className="hc-pl-none hc-pr-none">
            <LabelValue label="DPCI" value={itemDetails.dpci} />
          </Grid.Item>
          <Grid.Item xs={6} className="hc-pl-none">
            <LabelValue
              label="Brand"
              value={
                itemDetails.brands &&
                itemDetails.brands.filter((brand: Brand) => {
                  return brand.is_primary === true
                })[0]?.name
              }
            />
          </Grid.Item>
          <Grid.Item xs={6} className="hc-pl-none hc-pr-none">
            <LabelValue
              label="Department"
              value={
                itemDetails.department_id + '-' + itemDetails.department_name
              }
            />
          </Grid.Item>
          <Grid.Item xs={6} className="hc-pl-none">
            <LabelValue
              label="Class"
              value={itemDetails.class_id + '-' + itemDetails.class_name}
            />
          </Grid.Item>
          <Grid.Item xs={6} className="hc-pl-none">
            <LabelValue
              label="PIDS"
              value={
                itemDetails.pids && itemDetails.pids.length > 0
                  ? itemDetails.pids.join()
                  : 'Not Available'
              }
            />
          </Grid.Item>
          <Grid.Item xs={12} className="hc-pl-none">
            <LabelValue label="Description" value={itemDetails.description} />
          </Grid.Item>
          <Grid.Item xs={6} className="hc-pl-none">
            <LabelValue label="Item State" value={itemDetails.item_state} />
          </Grid.Item>
          <Grid.Item xs={6} className="hc-pl-none">
            <LabelValue label="Item Status" value={itemDetails.item_status} />
          </Grid.Item>
        </Grid.Container>
      </div>
    )
  )
}

export default ItemDetails
