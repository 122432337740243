import React, { useEffect, useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Home from '../views/Home'
import FactorialView from '../views/FactorialView'
import ItemFactoryDetails from '../views/ItemFactoryDetails'
import AllChangeRequestsExternal from '../views/AllChangeRequests/AllChangeRequestsExternal'
import Logout from '../components/Logout/Logout'
import BulkChangeRequest from '../views/BulkChangeRequest'
import { UserType } from '../utils/Constants'
import AllChangeRequests from '../views/AllChangeRequests/AllChangeRequests'
import { ProtectedElement } from '@praxis/component-auth'
import { getAppConfig } from '../config/AppConfig'
import { useUserInfo } from '../context/UserInfoContext'

const AppRoutes = () => {
  const { userInfo } = useUserInfo()
  const [defaultHomePage, setDefaultHomePage] = useState<string>('/')

  useEffect(() => {
    if (userInfo.user_id) {
      setDefaultHomePage(
        userInfo.user_type === UserType.INTERNAL
          ? '/all-change-requests'
          : '/search',
      )
    }
  }, [userInfo.user_type, userInfo.user_id])

  const AllChangeRequestsPage =
    userInfo.user_type === UserType.INTERNAL
      ? AllChangeRequests
      : AllChangeRequestsExternal

  const allowedGroups = [
    getAppConfig().internalADGroupUser,
    getAppConfig().internalADGroupAdmin,
    getAppConfig().externalVdsGroup,
  ]

  return (
    <Routes>
      <Route element={<ProtectedElement allowed={allowedGroups} />}>
        <Route path="/" element={<Navigate to={defaultHomePage} />} />

        <Route
          path="/all-change-requests"
          element={
            <FactorialView view="Change Requests">
              {(viewProps: any) => <AllChangeRequestsPage {...viewProps} />}
            </FactorialView>
          }
        />

        <Route
          path="/search"
          element={
            <FactorialView view="Search">
              {(viewProps: any) => <Home {...viewProps} />}
            </FactorialView>
          }
        />

        <Route
          path="/item-factory-details"
          element={
            <FactorialView view="Item Factory Details">
              {(viewProps: any) => <ItemFactoryDetails {...viewProps} />}
            </FactorialView>
          }
        />

        <Route
          path="/bulk-change-request"
          element={
            <FactorialView view="Bulk Change Request">
              {(viewProps: any) => <BulkChangeRequest {...viewProps} />}
            </FactorialView>
          }
        />

        <Route path="/logout" element={<Logout />} />
      </Route>
    </Routes>
  )
}
export default AppRoutes
