import { ItemVendor } from '../types/ItemDetailsType'
import { ChangeRequestType } from '../types/ChangeRequestType'

export const createAndSortVendorOptions = (
  vendors: ItemVendor[],
  changeRequests?: ChangeRequestType[],
) => {
  return vendors
    .slice()
    .sort((vendor: ItemVendor) => {
      //Sort vendors if vendor have an open change request
      return changeRequests
        ?.map((change: ChangeRequestType) => {
          return change.gms_vendor_number
        })
        .includes(vendor.id)
        ? -1
        : 0
    })
    .filter((v: ItemVendor) => {
      return v.id !== '0'
    })
    .map((vendor: ItemVendor) => {
      return {
        id: vendor.id,
        value: vendor.name,
        label: vendor.name + ' - ' + vendor.id,
      }
    })
}

export const createVendorOptions = (vendors?: ItemVendor[]) => {
  return vendors
    ? vendors
        .filter((v: ItemVendor) => {
          return v.id !== '0'
        })
        .map((vendor: ItemVendor) => {
          return {
            id: vendor.id,
            value: vendor.name,
            label: vendor.name + ' - ' + vendor.id,
          }
        })
    : []
}
