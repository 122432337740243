import { SearchCriteriaType } from '../../types/SearchCriteriaType'
import { analyticsService, EventArgs } from './analytics-service'

export const captureSearchEvent = (searchCriteria: SearchCriteriaType) => {
  if (searchCriteria.dept_class && searchCriteria.dept_class?.length > 0) {
    const eventArgs: EventArgs = {
      category: 'Search Query',
      action: 'Dept Class',
      label: searchCriteria.dept_class,
    }
    analyticsService.event(eventArgs)
  }
  if (searchCriteria.dpcis && searchCriteria.dpcis?.length > 0) {
    const eventArgs: EventArgs = {
      category: 'Search Query',
      action: 'DPCI',
      label: searchCriteria.dpcis.join(),
    }
    analyticsService.event(eventArgs)
  }
  if (searchCriteria.tcins && searchCriteria.tcins?.length > 0) {
    const eventArgs: EventArgs = {
      category: 'Search Query',
      action: 'TCIN',
      label: searchCriteria.tcins.join(),
    }
    analyticsService.event(eventArgs)
  }
  if (
    searchCriteria.gms_vendor_numbers &&
    searchCriteria.gms_vendor_numbers?.length > 0
  ) {
    const eventArgs: EventArgs = {
      category: 'Search Query',
      action: 'Vendor Id/Name',
      label: searchCriteria.gms_vendor_numbers.join(),
    }
    analyticsService.event(eventArgs)
  }
  if (searchCriteria.factory_id) {
    const eventArgs: EventArgs = {
      category: 'Search Query',
      action: 'Factory Id',
      label: searchCriteria.factory_id,
    }
    analyticsService.event(eventArgs)
  }
}

export const captureSearchResultsExportEvent = (rowCount: number) => {
  const eventArgs: EventArgs = {
    category: 'Search Results Export',
    action: 'Row Count',
    label: rowCount + '',
  }
  analyticsService.event(eventArgs)
}

export const captureChangeRequestExportEvent = () => {
  const eventArgs: EventArgs = {
    category: 'Change Request Export',
    action: 'Change Request Export',
    label: 'Change Request Export',
  }
  analyticsService.event(eventArgs)
}

export const captureButtonClickEvent = (
  buttonName: string,
  label: string = '',
) => {
  const eventArgs: EventArgs = {
    category: 'Button Clicks',
    action: buttonName,
    label: label,
  }
  analyticsService.event(eventArgs)
}
