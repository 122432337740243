import { getBaseUrl } from '../config/AppConfig'
import { AxiosUtil } from '../utils/AxiosUtil'
import { BusinessPartnerType } from '../types/BusinessPartnerType'

const getBusinessPartnerByGmsVendor = async (
  gmsVendorNumber: string,
): Promise<BusinessPartnerType> => {
  const url =
    getBaseUrl() +
    '/business_partners/search?gms_vendor_number=' +
    gmsVendorNumber

  return AxiosUtil.get(url)
    .then((response) => response.data)
    .then((data) => {
      return data
    })
}

const getBusinessPartnerDetails = async (): Promise<BusinessPartnerType> => {
  const url = getBaseUrl() + '/business_partners'
  return AxiosUtil.get(url)
    .then((response) => response.data)
    .then((data) => {
      return data
    })
}

export { getBusinessPartnerByGmsVendor, getBusinessPartnerDetails }
