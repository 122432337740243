import React, {
  useState,
  useEffect,
  FunctionComponent,
  useCallback,
} from 'react'
import { ProgressBar, Grid } from '@enterprise-ui/canvas-ui-react'
import { getChangeRequests } from '../../services/ChangeRequestService'
import { ChangeRequestType } from '../../types/ChangeRequestType'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  getUrlParamsForAllChangeRequests,
  createUrlQueryForAllChangeRequests,
} from '../../utils/UrlParams'
import {
  ChangeRequestQueryType,
  ReviewStatusType,
} from '../../types/ChangeRequestQueryType'
import { InternalUserActions } from '../../components/AllChangeRequests/Actions/Actions'
import AllChangeRequestsFilters from '../../components/AllChangeRequests/Filters/Filters'
import AllChangeRequestsGrid from '../../components/AllChangeRequests/Grid/AllChangeRequestsGrid'
import { useUserInfo } from '../../context/UserInfoContext'
import { useReload } from '../../hooks/useForceReload'
import { SpotlightIds } from '../../components/Tour/SpotlightIds'
import FactorialSpotlight from '../../components/Tour/FactorialSpotlight'
import { FactorialReleaseNotes } from '../../components/ReleaseNotes'

const AllChangeRequests: FunctionComponent = () => {
  const [fetchInProgress, setFetchInProgress] = useState<boolean>(false)
  const [pendingApprovals, setPendingApprovals] = useState<ChangeRequestType[]>(
    [],
  )
  const [selectedChangeRequests, setSelectedChangeRequests] = useState<
    ChangeRequestType[]
  >([])
  const [navigateTo, setNavigateTo] = useState({
    pathname: '/all-change-requests',
    search: '',
  })
  const navigate = useNavigate()
  const { userInfo } = useUserInfo()
  const location = useLocation()

  const { assignedTo, pendingOnly } = getUrlParamsForAllChangeRequests(
    location.search,
  )
  const [reload, forceReload] = useReload()

  const onFilterUpdate = useCallback(
    (_pendingOnly: boolean, _assignedToMeOnly: boolean): void => {
      const searchQueryPath = createUrlQueryForAllChangeRequests(
        _assignedToMeOnly,
        _pendingOnly,
      )
      setNavigateTo({
        pathname: '/all-change-requests',
        search: searchQueryPath,
      })
    },
    [],
  )

  useEffect(() => {
    navigate(navigateTo)
  }, [navigate, navigateTo])

  useEffect(() => {
    if (location.pathname === '/all-change-requests') {
      setSelectedChangeRequests([])
      const changeRequestsQuery: ChangeRequestQueryType = {}

      if (assignedTo !== 'all') {
        changeRequestsQuery.internal_contacts = [userInfo.email_id]
      }

      if (pendingOnly) {
        changeRequestsQuery.review_status = [ReviewStatusType.Initiated]
      }

      setFetchInProgress(true)
      getChangeRequests(changeRequestsQuery)
        .then((changeRequests: ChangeRequestType[]) => {
          setPendingApprovals(changeRequests)
          setFetchInProgress(false)
        })
        .catch(() => {
          setFetchInProgress(false)
        })
    }
  }, [
    location.search,
    location.pathname,
    userInfo.email_id,
    assignedTo,
    pendingOnly,
    reload,
  ])

  return (
    <>
      {fetchInProgress && <ProgressBar indeterminate />}
      <Grid.Container justify="space-between" align="flex-end">
        <Grid.Item className="hc-pt-dense hc-pb-dense">
          <InternalUserActions
            selectedChangeRequests={selectedChangeRequests}
            onActionCompleted={forceReload}
          />
        </Grid.Item>
        <Grid.Item className="hc-pt-dense hc-pb-dense">
          <FactorialSpotlight
            spotlightId={SpotlightIds.ChangeRequestsFilter}
            contentWidth="lg"
            contentLocation="bottom-left"
          />
          <AllChangeRequestsFilters
            pendingOnly={pendingOnly}
            assignedToMeOnly={assignedTo !== 'all'}
            onFilterUpdate={onFilterUpdate}
          />
        </Grid.Item>
        <Grid.Item xs={12} className="hc-pt-none">
          <AllChangeRequestsGrid
            pendingOnly={pendingOnly}
            pendingApprovalsData={pendingApprovals}
            setSelectedChangeRequests={setSelectedChangeRequests}
          />
        </Grid.Item>
      </Grid.Container>
      <FactorialReleaseNotes />
    </>
  )
}

export default AllChangeRequests
