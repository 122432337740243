import React from 'react'

export const TcinsList: React.FC<{ tcins: string[]; tcinsPerRow?: number }> = ({
  tcins,
  tcinsPerRow = 4,
}) => {
  const tcinChunks = []
  for (var i = 0; i < tcins.length; i += tcinsPerRow) {
    tcinChunks.push(tcins.slice(i, i + tcinsPerRow))
  }
  return (
    <>
      {tcinChunks.map((tcinChunk) => {
        return <p key={tcinChunk[0]}>{tcinChunk.join(',')}</p>
      })}
    </>
  )
}
