import ItemFactoryDetailsButton from '../ItemDetails/ItemFactoryDetailsButton'
export const gridOptions = {
  defaultColDef: {
    filter: 'agMultiColumnFilter',
    menuTabs: ['filterMenuTab'],
    resizable: true,
    editable: false,
    sortable: true,
  },
  pagination: true,
  headerHeight: 48,
  rowHeight: 28,
  suppressContextMenu: true,
  stopEditingWhenCellsLoseFocus: true,
  enterNavigatesVertically: true,
  undoRedoCellEditing: true,
  components: {
    itemFactoryDetailsButton: ItemFactoryDetailsButton,
  },
  rowSelection: 'multiple',
  suppressRowClickSelection: true,
}
