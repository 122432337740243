import { SearchIcon, InProgressIcon, LogoutIcon } from '@enterprise-ui/icons'
const internalSideNavLinks = [
  {
    label: 'Change Requests',
    icon: InProgressIcon,
    navigationPath: '/all-change-requests',
  },
  {
    label: 'Search',
    icon: SearchIcon,
    navigationPath: '/search',
  },
]

const externalSideNavLinks = [
  {
    label: 'Search',
    icon: SearchIcon,
    navigationPath: '/search',
  },
  {
    label: 'Change Requests',
    icon: InProgressIcon,
    navigationPath: '/all-change-requests',
  },
]

const signOutLink = {
  label: 'Logout',
  icon: LogoutIcon,
  navigationPath: '/logout',
}

export { internalSideNavLinks, externalSideNavLinks, signOutLink }
