import React, { useState } from 'react'
import { ItemDetailsType, LeanFactory } from '../../types/ItemDetailsType'
import { Location } from '../../types/BusinessPartnerType'
import { AddFactoryButton } from './AddFactoryButton'
import { AddFactoryForm } from './AddFactoryForm'
import { captureButtonClickEvent } from '../../services/analytics/events-service'
import { ReplaceFactoryButton } from './ReplaceFactoryButton'
import { ReplaceFactoryForm } from './ReplaceFactoryForm'
import { DeleteFactoryButton } from './DeleteFactoryButton'
import { getDefaultEndDate } from '../../utils/Constants'
import { AutoCompleteOptionType } from '../../types/AutoCompleteOptionType'
import {
  ToastProvider,
  ToastProviderProps,
} from '@enterprise-ui/canvas-ui-react'

interface EditFactoryActionsProps {
  selectedFactory?: LeanFactory
  onAddFactory: (factoryToAdd: LeanFactory) => void
  onReplaceFactory: (
    factoryToRemove: LeanFactory,
    factoryToAdd: LeanFactory,
  ) => void
  onDeleteFactory: (factoryToRemove: LeanFactory) => void
  locations: Location[]
  hideDates: boolean
  factoryWithDefEndDt: number
  selectedVendor: AutoCompleteOptionType
  itemDetails: ItemDetailsType
}
const deleteToast: ToastProviderProps = {
  heading: 'Delete Alert',
  message:
    'Unable to Delete Factory - Deleting this Factory will create an end in the timeline, please edit date range',
  type: 'alert',
}

export const EditFactoryActions: React.FC<EditFactoryActionsProps> = ({
  selectedFactory,
  onAddFactory,
  onReplaceFactory,
  onDeleteFactory,
  locations,
  hideDates,
  factoryWithDefEndDt,
  selectedVendor,
  itemDetails,
}) => {
  const [showAddFactoryForm, setShowAddFactoryForm] = useState<boolean>(false)
  const [showReplaceFactoryForm, setShowReplaceFactoryForm] =
    useState<boolean>(false)

  const [showSelectionError, setShowSelectionError] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<string>('')
  const makeToast = ToastProvider.useToaster()

  if (selectedFactory && showSelectionError) {
    setShowSelectionError(false)
  }

  const isFormVisible = showAddFactoryForm || showReplaceFactoryForm

  return (
    <>
      <div style={{ display: 'flex' }}>
        <AddFactoryButton
          showButton={!isFormVisible}
          disabled={selectedFactory ? true : false}
          onAddFactoryButtonClick={() => {
            captureButtonClickEvent('Add Factory')
            setShowAddFactoryForm(true)
          }}
        />
        <ReplaceFactoryButton
          showButton={!isFormVisible}
          onReplaceFactoryButtonClick={() => {
            captureButtonClickEvent('Replace Factory')
            selectedFactory && setShowReplaceFactoryForm(true)
            if (!selectedFactory) {
              setShowSelectionError(true)
              setErrorMessage(
                '*Please select a factory and then click on REPLACE FACTORY',
              )
            }
          }}
        />
        <DeleteFactoryButton
          showButton={!isFormVisible}
          onDeleteFactoryButtonClick={() => {
            captureButtonClickEvent('Delete Factory')
            if (!selectedFactory) {
              setShowSelectionError(true)
              setErrorMessage(
                '*Please select a factory and then click on DELETE FACTORY',
              )
            }
            if (
              selectedFactory?.effective_end_date.getFullYear() ===
                getDefaultEndDate().getFullYear() &&
              factoryWithDefEndDt === 1
            ) {
              makeToast(deleteToast)
            } else {
              selectedFactory && onDeleteFactory(selectedFactory)
            }
          }}
        />
        <AddFactoryForm
          onAddFactory={(factory: LeanFactory) => {
            onAddFactory(factory)
            setShowAddFactoryForm(false)
          }}
          onCancel={() => setShowAddFactoryForm(false)}
          locations={locations}
          showForm={showAddFactoryForm}
          hideDates={hideDates}
          selectedVendor={selectedVendor}
          itemDetails={itemDetails}
        />
        {selectedFactory && (
          <ReplaceFactoryForm
            factoryToRemove={selectedFactory}
            onReplaceFactory={(
              factoryToRemove: LeanFactory,
              factoryToAdd: LeanFactory,
            ) => {
              onReplaceFactory(factoryToRemove, factoryToAdd)
              setShowReplaceFactoryForm(false)
            }}
            onCancel={() => setShowReplaceFactoryForm(false)}
            locations={locations}
            showForm={showReplaceFactoryForm}
            selectedVendor={selectedVendor}
            itemDetails={itemDetails}
          />
        )}
      </div>
      <div>
        {showSelectionError && <p className="hc-clr-error">{errorMessage}</p>}
      </div>
    </>
  )
}
