import {
  Button,
  ProgressBar,
  ToastProvider,
  ToasterToastProps,
} from '@enterprise-ui/canvas-ui-react'
import { FunctionComponent, useState } from 'react'
import { useSideNav } from '../../../context/SideNavContext'
import { useUserInfo } from '../../../context/UserInfoContext'
import { updateChangeRequests } from '../../../services/ChangeRequestService'
import { captureButtonClickEvent } from '../../../services/analytics/events-service'
import { ReviewStatusType } from '../../../types/ChangeRequestQueryType'
import { ChangeRequestType } from '../../../types/ChangeRequestType'
import './Actions.scss'
import ReassignModal from './ReassignModal'
import RejectConfirmModal from './RejectConfirmModal'
import WithdrawConfirmModal from './WithdrawConfirmModal'

interface ActionsProps {
  selectedChangeRequests: ChangeRequestType[]
  onActionCompleted: () => void
}
const successToast: ToasterToastProps = {
  heading: 'Action Completed',
  message: 'Email sent to BP regarding this action',
  type: 'success',
}
const withdrawSuccessToast: ToasterToastProps = {
  heading: 'Action Completed',
  message: 'Change request withdraw action completed.',
  type: 'success',
}

const reassignSuccessToast: ToasterToastProps = {
  heading: 'Action Completed',
  message: 'Reassign action completed.',
  type: 'success',
}

const alertToast: ToasterToastProps = {
  heading: 'Action failed',
  message: 'Failed to perform action',
  type: 'alert',
}

const ReassignAction: FunctionComponent<ActionsProps> = ({
  selectedChangeRequests,
  onActionCompleted,
}) => {
  const makeToast = ToastProvider.useToaster()

  const [isReassignInProgress, setIsReassignInProgress] =
    useState<boolean>(false)
  const [showReassignModal, setShowReassignModal] = useState(false)
  const isDisabled = () => {
    return selectedChangeRequests.length === 0 || isReassignInProgress
  }

  const allChangeRequestsSameContact = () => {
    let allContactsSame = true

    if (selectedChangeRequests && selectedChangeRequests.length > 0) {
      const firstRequestInternalContacts =
        selectedChangeRequests[0].internal_contacts?.reduce(
          (contacts: any, contact) => {
            contacts[contact] = 1
            return contacts
          },
          {},
        )

      for (const selectedChangeRequest of selectedChangeRequests) {
        for (const contact of selectedChangeRequest.internal_contacts!!) {
          if (!firstRequestInternalContacts[contact]) {
            allContactsSame = false
            break
          }
        }
      }
    }

    return allContactsSame
  }

  const onReassignClicked = (internalContacts: string[]) => {
    setShowReassignModal(false)
    setIsReassignInProgress(true)
    updateChangeRequests({
      change_request_ids: selectedChangeRequests.map((cr) => {
        return cr.change_request_id
      }),
      internal_contacts: internalContacts,
    })
      .then(() => {
        setIsReassignInProgress(false)
        onActionCompleted()
        makeToast(reassignSuccessToast)
      })
      .catch(() => {
        makeToast(alertToast)
        setIsReassignInProgress(false)
      })
  }

  return (
    <div style={{ width: '110px', marginBottom: '5px' }}>
      {isReassignInProgress && (
        <div style={{ position: 'absolute', width: '110px' }}>
          <ProgressBar indeterminate className="progress-button" />
        </div>
      )}
      <Button
        disabled={isDisabled()}
        type="secondary"
        onClick={() => {
          captureButtonClickEvent('Reassign')
          setShowReassignModal(true)
        }}
      >
        Reassign
      </Button>
      {showReassignModal && (
        <ReassignModal
          showModal={showReassignModal}
          setShowModal={setShowReassignModal}
          onReassignClicked={onReassignClicked}
          existingInternalContacts={
            allChangeRequestsSameContact()
              ? selectedChangeRequests?.[0]?.internal_contacts
              : []
          }
        />
      )}
    </div>
  )
}

const PendingApprovalActions: FunctionComponent<ActionsProps> = ({
  selectedChangeRequests,
  onActionCompleted,
}) => {
  const makeToast = ToastProvider.useToaster()

  const [isApprovalInProgress, setIsApprovalInProgress] =
    useState<boolean>(false)
  const [isRejectionInProgress, setIsRejectionInProgress] =
    useState<boolean>(false)
  const [showRejectConfirmModal, setShowRejectConfirmModal] =
    useState<boolean>(false)
  const { userInfo } = useUserInfo()

  const isDisabled = () => {
    return (
      selectedChangeRequests.length === 0 ||
      isApprovalInProgress ||
      isRejectionInProgress
    )
  }

  const onApproveClicked = () => {
    setIsApprovalInProgress(true)
    updateChangeRequests({
      change_request_ids: selectedChangeRequests.map((cr) => {
        return cr.change_request_id
      }),
      review_status: ReviewStatusType.Approved,
      reviewer: {
        email_id: userInfo.email_id,
        user_id: userInfo.user_id,
        user_name: userInfo.user_name,
        user_type: userInfo.user_type,
      },
    })
      .then(() => {
        setIsApprovalInProgress(false)
        makeToast(successToast)
        onActionCompleted()
      })
      .catch(() => {
        makeToast(alertToast)
        setIsApprovalInProgress(false)
      })
  }

  const onRejectClicked = (
    reasonForRejection: string,
    commentsForRejection: string,
  ) => {
    setIsRejectionInProgress(true)
    updateChangeRequests({
      change_request_ids: selectedChangeRequests.map((cr) => {
        return cr.change_request_id
      }),
      review_status: ReviewStatusType.Rejected,
      reason_for_rejection: reasonForRejection,
      comments_for_rejection: commentsForRejection,
      reviewer: {
        email_id: userInfo.email_id,
        user_id: userInfo.user_id,
        user_name: userInfo.user_name,
        user_type: userInfo.user_type,
      },
    })
      .then(() => {
        setIsRejectionInProgress(false)
        onActionCompleted()
        makeToast(successToast)
        setShowRejectConfirmModal(false)
      })
      .catch(() => {
        makeToast(alertToast)
        setIsRejectionInProgress(false)
      })
  }

  return (
    <div className="actions-container">
      <>
        <div style={{ width: '88px' }}>
          {isRejectionInProgress && (
            <div style={{ position: 'absolute', width: '88px' }}>
              <ProgressBar indeterminate className="progress-button" />
            </div>
          )}
          <Button
            disabled={isDisabled()}
            type="destructive"
            onClick={() => {
              captureButtonClickEvent('Reject')
              setShowRejectConfirmModal(true)
            }}
          >
            Reject
          </Button>
          <RejectConfirmModal
            showModal={showRejectConfirmModal}
            setShowModal={setShowRejectConfirmModal}
            onRejectClicked={onRejectClicked}
          />
        </div>
        <div style={{ marginLeft: '10px' }}></div>
        <div style={{ width: '105px' }}>
          {isApprovalInProgress && (
            <div style={{ position: 'absolute', width: '105px' }}>
              <ProgressBar indeterminate className="progress-button" />
            </div>
          )}
          <Button
            disabled={isDisabled()}
            type="secondary"
            onClick={() => {
              captureButtonClickEvent('Approve')
              onApproveClicked()
            }}
          >
            Approve
          </Button>
        </div>
        <div style={{ marginLeft: '10px' }}></div>
      </>
    </div>
  )
}

const WithdrawAction: FunctionComponent<ActionsProps> = ({
  selectedChangeRequests,
  onActionCompleted,
}) => {
  const makeToast = ToastProvider.useToaster()

  const [isWithdrawInProgress, setIsWithdrawInProgress] =
    useState<boolean>(false)
  const [showWithdrawConfirmModal, setShowWithdrawConfirmModal] =
    useState(false)
  const isDisabled = () => {
    return selectedChangeRequests.length === 0 || isWithdrawInProgress
  }
  const { refreshSideNav } = useSideNav()

  const onWithdrawClicked = () => {
    setShowWithdrawConfirmModal(false)
    setIsWithdrawInProgress(true)
    updateChangeRequests({
      change_request_ids: selectedChangeRequests.map((cr) => {
        return cr.change_request_id
      }),
      review_status: ReviewStatusType.Cancelled,
    })
      .then(() => {
        setIsWithdrawInProgress(false)
        onActionCompleted()
        makeToast(withdrawSuccessToast)
        refreshSideNav()
      })
      .catch(() => {
        makeToast(alertToast)
        setIsWithdrawInProgress(false)
      })
  }

  return (
    <div className="actions-container" style={{ width: '111.23' }}>
      <div style={{ position: 'absolute', width: '111.23px' }}>
        {isWithdrawInProgress && (
          <ProgressBar indeterminate className="progress-button" />
        )}
      </div>
      <Button
        disabled={isDisabled()}
        type="destructive"
        onClick={() => {
          captureButtonClickEvent('Withdraw')
          setShowWithdrawConfirmModal(true)
        }}
      >
        Withdraw
      </Button>
      <WithdrawConfirmModal
        showModal={showWithdrawConfirmModal}
        setShowModal={setShowWithdrawConfirmModal}
        onWithdrawClicked={onWithdrawClicked}
      />
    </div>
  )
}

const VendorActions: FunctionComponent<ActionsProps> = ({
  selectedChangeRequests,
  onActionCompleted,
}) => {
  return (
    <div className="actions-container">
      <WithdrawAction
        selectedChangeRequests={selectedChangeRequests}
        onActionCompleted={onActionCompleted}
      />
      <ReassignAction
        selectedChangeRequests={selectedChangeRequests}
        onActionCompleted={onActionCompleted}
      ></ReassignAction>
    </div>
  )
}

const InternalUserActions: FunctionComponent<ActionsProps> = ({
  selectedChangeRequests,
  onActionCompleted,
}) => {
  return (
    <div className="actions-container">
      <PendingApprovalActions
        selectedChangeRequests={selectedChangeRequests}
        onActionCompleted={onActionCompleted}
      />
      <ReassignAction
        selectedChangeRequests={selectedChangeRequests}
        onActionCompleted={onActionCompleted}
      ></ReassignAction>
    </div>
  )
}

export { InternalUserActions, VendorActions }
