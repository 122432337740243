import React from 'react'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import {
  getDefaultEndDate,
  getDefaultStartDate,
} from '../../../utils/Constants'

interface TimelineDateFlagProps {
  effectiveStartDate: Date
  effectiveEndDate: Date
  isDateAdjusted?: boolean
}

const defaultStartDate = getDefaultStartDate()
const defaultEndDate = getDefaultEndDate()

const TimelineDateFlag: React.FC<TimelineDateFlagProps> = ({
  effectiveStartDate,
  effectiveEndDate,
  isDateAdjusted = false,
}) => {
  return (
    <div
      style={{
        marginBottom: '15px',
        fontWeight: 'bold',
      }}
    >
      {defaultStartDate.getTime() === effectiveStartDate.getTime() && (
        <ShowUntilDate effectiveEndDate={effectiveEndDate} />
      )}
      {defaultStartDate.getTime() !== effectiveStartDate.getTime() &&
        defaultEndDate.getTime() === effectiveEndDate.getTime() && (
          <ShowAfterDate effectiveStartDate={effectiveStartDate} />
        )}
      {defaultStartDate.getTime() !== effectiveStartDate.getTime() &&
        defaultEndDate.getTime() !== effectiveEndDate.getTime() && (
          <ShowBothDates
            effectiveStartDate={effectiveStartDate}
            effectiveEndDate={effectiveEndDate}
          />
        )}
      {isDateAdjusted && ' (current day when no factory present)'}
    </div>
  )
}

const ShowBothDates: React.FC<{
  effectiveStartDate: Date
  effectiveEndDate: Date
}> = ({ effectiveStartDate, effectiveEndDate }) => {
  return (
    <>
      <DateFormatter date={effectiveStartDate} timezone="UTC" />
      {' - '}
      <DateFormatter date={effectiveEndDate} timezone="UTC" />
    </>
  )
}

const ShowAfterDate: React.FC<{ effectiveStartDate: Date }> = ({
  effectiveStartDate,
}) => {
  return (
    <>
      <span>Beginning </span>
      <DateFormatter date={effectiveStartDate} timezone="UTC" />
    </>
  )
}

const ShowUntilDate: React.FC<{ effectiveEndDate: Date }> = ({
  effectiveEndDate,
}) => {
  return (
    <>
      {' '}
      <span>Until </span>
      <DateFormatter date={effectiveEndDate} timezone="UTC" />
    </>
  )
}

export default TimelineDateFlag
