export enum SpotlightIds {
  //nav bar
  NavigateToSearch,
  NavigateToChangeRequests,
  NavigateToItemFactoryDetails,

  //search
  TcinSearchBox,
  TcinClickForFactoryDetails,
  AgGridFilters,
  ClearAllFilters,

  //change request
  AllChangeRequestsGrid,
  ChangeRequestsFilter,
  TcinClickForChangeRequestDetails,
  AgGridFilters2,
  ClearAllFilters2,

  //factory details
  EditMode,
  AddNewFactory,
  EditFactory,
}
