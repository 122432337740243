import { Button, Grid } from '@enterprise-ui/canvas-ui-react'
import React from 'react'

interface ItemsDroppedWarningProps {
  tcinsWithCRs: string[]
  tcinsWithoutCRs: string[]
  onCancel: () => void
  onContinue: () => void
}

const ItemsDroppedWarning: React.FC<ItemsDroppedWarningProps> = ({
  tcinsWithCRs,
  tcinsWithoutCRs,
  onCancel,
  onContinue,
}) => {
  return (
    <>
      <div className="hc-pa-normal">
        {tcinsWithoutCRs.length !== 0 && (
          <>
            <p className="hc-clr-error">
              Below {tcinsWithCRs.length} TCINs have pending change requests and
              cannot be edited. Would you like to continue editing other{' '}
              {tcinsWithoutCRs.length} items you have selected?
            </p>
            <p className="hc-bg-grey06 hc-ml-normal hc-pa-normal">
              {tcinsWithCRs.join()}
            </p>
          </>
        )}
        {tcinsWithoutCRs.length === 0 && (
          <p className="hc-clr-error">
            All selected TCINs have pending change requests and cannot be
            edited. Please review existing change requests
          </p>
        )}
      </div>
      <Grid.Container justify="flex-end">
        <Grid.Item>
          <Button onClick={onCancel}>Cancel</Button>
        </Grid.Item>
        {tcinsWithoutCRs.length !== 0 && (
          <Grid.Item>
            <Button type="primary" onClick={onContinue}>
              Continue
            </Button>
          </Grid.Item>
        )}
      </Grid.Container>
    </>
  )
}

export default ItemsDroppedWarning
