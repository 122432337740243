import React, { FunctionComponent, useEffect } from 'react'
import { ReleaseNotes } from 'smart-assist-canvas'
import { getAppConfig } from '../../config/AppConfig'
import { useLocalStorage } from '../../context/LocalStorageContext'
import { useReleaseNotes } from '../../context/ReleaseNotesContext'
import { useUserInfo } from '../../context/UserInfoContext'
import { UserType } from '../../utils/Constants'

export const FactorialReleaseNotes: FunctionComponent = () => {
  const { userInfo } = useUserInfo()
  const config = getAppConfig()
  const apiKey = config.apiKey
  const baseUrl = config.smartAssist ? config.smartAssist.baseUrl : ''
  const userType = userInfo.user_type === UserType.INTERNAL ? 'I' : 'E'
  const confluenceSpace = config.smartAssist
    ? config.smartAssist.confluenceSpace
    : ''
  const environment = config.smartAssist?.environment
  let token = localStorage.getItem('access_token') ?? ''

  const {
    isReleaseNotesOpen,
    setIsReleaseNotesOpen,
    dismissedReleaseNotes,
    setDismissedReleaseNotes,
  } = useReleaseNotes()
  const { releaseNotesSeenAt, setReleaseNotesSeenAt } = useLocalStorage()

  useEffect(() => {
    const recentReleaseNotesPublishedAt = new Date(
      config.releaseNotes.recentReleaseDate,
    )
    if (
      recentReleaseNotesPublishedAt > releaseNotesSeenAt &&
      !dismissedReleaseNotes
    ) {
      setIsReleaseNotesOpen(true)
    }
  }, [
    releaseNotesSeenAt,
    setIsReleaseNotesOpen,
    config.releaseNotes.recentReleaseDate,
    dismissedReleaseNotes,
  ])

  return (
    <ReleaseNotes
      baseUrl={baseUrl}
      confluenceSpace={confluenceSpace}
      contentId={config.releaseNotes.releaseNotesContentId}
      pageTitle={config.releaseNotes.releaseNotesTitle}
      apiKey={apiKey}
      token={token}
      isOpen={isReleaseNotesOpen}
      userType={userType}
      onClose={() => {
        setIsReleaseNotesOpen(false)
        setDismissedReleaseNotes(true)
        setReleaseNotesSeenAt(new Date())
      }}
      environment={environment}
    />
  )
}
