import React, { ReactNode } from 'react'
import { useState } from 'react'

const ReleaseNotesContext = React.createContext<any>(null)

interface Props {
  children: ReactNode
}

const ReleaseNotesContextProvider = ({ children }: Props) => {
  const [isReleaseNotesOpen, setIsReleaseNotesOpen] = useState<boolean>(false)
  const [dismissedReleaseNotes, setDismissedReleaseNotes] =
    useState<boolean>(false)

  return (
    <ReleaseNotesContext.Provider
      value={{
        isReleaseNotesOpen,
        setIsReleaseNotesOpen,
        dismissedReleaseNotes,
        setDismissedReleaseNotes,
      }}
    >
      {children}
    </ReleaseNotesContext.Provider>
  )
}

const useReleaseNotes = (): {
  isReleaseNotesOpen: boolean
  setIsReleaseNotesOpen: (isReleaseNotesOpen: boolean) => void
  dismissedReleaseNotes: boolean
  setDismissedReleaseNotes: (dismissedReleaseNotes: boolean) => void
} => {
  return React.useContext(ReleaseNotesContext)
}

export { ReleaseNotesContextProvider, useReleaseNotes }
