import { Button, Dialog } from '@enterprise-ui/canvas-ui-react'
import { captureButtonClickEvent } from '../../services/analytics/events-service'
import { checkDept } from '../../utils/CheckDept'
import { Actions } from '../BulkChangeRequest/Actions'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import EnterpriseIcon, { TrashIcon } from '@enterprise-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import BulkChangeRequestModal from '../BulkChangeRequest/BulkChangeRequestModal'
import { distinctStr } from '../../utils/UniqueString'

interface EditFactoriesProps {
  showEditFactoriesButton: Boolean
  selectedTcins: string[]
  selectedDepts: string[]
}

export const EditFactoriesButton: React.FC<EditFactoriesProps> = ({
  showEditFactoriesButton,
  selectedTcins,
  selectedDepts,
}) => {
  const [isBulkRequestModalOpen, setIsBulkRequestModalOpen] =
    useState<boolean>(false)
  const [bulkAction, setBulkAction] = useState<Actions>(Actions.AddFactory)
  const [bulkRequestModalKey, setBulkRequestModalKey] = useState<number>()
  useEffect(() => {
    if (!isBulkRequestModalOpen) {
      setBulkRequestModalKey(Date.now())
    }
  }, [isBulkRequestModalOpen])
  const navigateToBulkChangeRequestPage = (action: Actions) => {
    if (selectedTcins.length > 1) {
      setIsBulkRequestModalOpen(true)
      setBulkAction(action)
    } else {
      //single tcin selected
      navigate('/item-factory-details?tcin=' + selectedTcins[0])
    }
  }
  const navigate = useNavigate()
  const [deptDialog, setDeptDialog] = useState<boolean>(false)
  const [showAddFactoryDialog, setShowAddFactoryDialog] =
    useState<boolean>(false)

  return (
    <>
      {showEditFactoriesButton && (
        <>
          <Button
            onClick={() => {
              setShowAddFactoryDialog(true)
            }}
          >
            <FontAwesomeIcon
              className="hc-mr-dense"
              icon={faPlusCircle}
              size="lg"
            />
            Add Factory
          </Button>
          <Button
            onClick={() => {
              captureButtonClickEvent(
                'Bulk Replace Factory',
                selectedTcins.length + '',
              )
              if (!checkDept(selectedDepts)) {
                setDeptDialog(true)
              } else {
                navigateToBulkChangeRequestPage(Actions.ReplaceFactory)
              }
            }}
          >
            <FontAwesomeIcon
              className="hc-mr-dense"
              icon={faExchangeAlt}
              size="lg"
            />
            Replace Factory
          </Button>
          <Button
            onClick={() => {
              captureButtonClickEvent(
                'Bulk Delete Factory',
                selectedTcins.length + '',
              )
              navigateToBulkChangeRequestPage(Actions.DeleteFactory)
            }}
          >
            <EnterpriseIcon
              className="hc-mr-dense"
              icon={TrashIcon}
              size="lg"
            />
            Delete Factory
          </Button>
        </>
      )}
      <BulkChangeRequestModal
        isOpen={isBulkRequestModalOpen}
        onClose={() => setIsBulkRequestModalOpen(false)}
        tcins={selectedTcins}
        dept={selectedDepts[0]}
        action={bulkAction}
        key={bulkRequestModalKey}
      ></BulkChangeRequestModal>
      <Dialog
        headingText={
          'Selected DPCIs/TCINs are from multiple departments [ ' +
          '\n' +
          distinctStr(selectedDepts) +
          ' ' +
          ' ] .Please only select items from the same department to make a factory change'
        }
        approveButtonText="OK"
        onApprove={() => {
          setDeptDialog(false)
        }}
        isVisible={deptDialog}
      />
      <Dialog
        headingText="Please note, this will ADD a factory to the timeline, implying production at two locations at the same time. Please use REPLACE Factory if you want to change from one location to another."
        approveButtonText="Ok"
        onApprove={() => {
          setShowAddFactoryDialog(false)
          captureButtonClickEvent('Bulk Add Factory', selectedTcins.length + '')
          if (!checkDept(selectedDepts)) {
            setDeptDialog(true)
          } else {
            navigateToBulkChangeRequestPage(Actions.AddFactory)
          }
        }}
        onRefuse={() => {
          setShowAddFactoryDialog(false)
        }}
        refuseButtonText="Cancel"
        isVisible={showAddFactoryDialog}
      />
    </>
  )
}
