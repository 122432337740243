import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useReload } from '../hooks/useForceReload'
import { getItemDetails } from '../services/ItemService'
import { ItemDetailsType } from '../types/ItemDetailsType'
import BulkChangeRequestItemDetailsGrid from '../components/ItemDetails/Grid/BulkChangeRequestItemDetailsGrid'
import {
  Grid,
  ProgressBar,
  Button,
  Card,
  Divider,
} from '@enterprise-ui/canvas-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { AutoCompleteOptionType } from '../types/AutoCompleteOptionType'
import { useSideNav } from '../context/SideNavContext'

const BulkChangeRequest: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [reload] = useReload()
  const { setIsSideNavVisible } = useSideNav()
  const [itemDetails, setItemDetails] = useState<ItemDetailsType[]>([])
  const [fetchInProgress, setFetchInProgress] = useState<boolean>(false)
  const [selectedVendor, setSelectedVendor] = useState<AutoCompleteOptionType>()
  useEffect(() => {
    if (location.pathname === '/bulk-change-request') {
      const urlParams = new URLSearchParams(location.search)
      const tcins: string = urlParams.get('tcins') || ''
      if (tcins) {
        setFetchInProgress(true)
        setIsSideNavVisible(false)
        getItemDetails({ tcins: tcins.split(',') })
          .then((itemDetailsRes: ItemDetailsType[]) => {
            setFetchInProgress(false)
            setItemDetails(itemDetailsRes)
          })
          .catch(() => {
            setFetchInProgress(false)
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, reload])
  return (
    <Grid.Container>
      {fetchInProgress && <ProgressBar indeterminate />}
      <Grid.Item xs={12} className="hc-pb-none">
        <Button onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faArrowLeft} size="1x" />
          <span style={{ marginLeft: '4px' }}>Back</span>
        </Button>
      </Grid.Item>
      <Grid.Item xs={12} className="hc-pt-none">
        {itemDetails.length > 0 ? (
          <Card className="hc-pa-expanded" elevation={3}>
            <Grid.Container>
              <Grid.Item xs={7}>
                <BulkChangeRequestItemDetailsGrid
                  itemDetails={itemDetails}
                  selectedVendor={selectedVendor}
                  setSelectedVendor={setSelectedVendor}
                />
              </Grid.Item>
              <Divider direction="vertical" />
            </Grid.Container>
          </Card>
        ) : (
          <div>Item factory details not available.</div>
        )}
      </Grid.Item>
    </Grid.Container>
  )
}

export default BulkChangeRequest
