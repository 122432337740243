import {
  Button,
  Card,
  Form,
  Grid,
  ProgressBar,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import React, { useEffect, useState } from 'react'
import { useBusinessPartnerInfo } from '../../context/BusinessPartnerInfoContext'
import { useUserInfo } from '../../context/UserInfoContext'
import { submitChangeRequest } from '../../services/ChangeRequestService'
import { AutoCompleteOptionType } from '../../types/AutoCompleteOptionType'
import { BulkChangeRequestType } from '../../types/BulkChangeRequestType'
import { BulkChangeResponseType } from '../../types/BulkChangeResponseType'
import { ItemVendorFactoryTimeline } from '../../types/ItemVendorFactoryType'
import {
  ReasonForChangeOptions,
  ReviewStatus,
  UserType,
} from '../../utils/Constants'
import { previewNewTimeline } from '../../utils/FactoriesTimelineUtil'
import { cleanTimeline } from '../ChangeRequest/ChangeRequestUtil'
import EmailField from '../Common/EmailField'
import { CycleDescDropDown } from '../CycleDescDropDown/CycleDescDropDown'
interface SubmitChangeRequestFormProps {
  itemVendorFactoryTimelines: ItemVendorFactoryTimeline[]
  onSubmit: (bulkChangeResponse: BulkChangeResponseType[]) => void
  onPrev: () => void
}
const SubmitChangeRequestForm: React.FC<SubmitChangeRequestFormProps> = ({
  itemVendorFactoryTimelines,
  onSubmit,
  onPrev,
}) => {
  const { userInfo } = useUserInfo()
  const { businessPartner } = useBusinessPartnerInfo()
  const [reasonForChange, setReasonForChange] =
    useState<AutoCompleteOptionType>()
  const [changeDescription, setChangeDescription] = useState<string>()
  const [cycleDescription, setCycleDescription] = useState<string[]>()
  const [internalContacts, setInternalContacts] = useState<string[]>([])
  const [externalContacts, setExternalContacts] = useState<string[]>([
    userInfo.email_id,
  ])
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [showErrors, setShowErrors] = useState<boolean>(false)

  const onSubmitRequest = () => {
    if (!isFormValid) {
      setShowErrors(true)
    } else {
      setInProgress(true)
      const baseChangeRequest = {
        bp_id: businessPartner.bp_id,
        bp_name: businessPartner.bp_name,
        bulk_req_id: businessPartner.bp_id + Date.now(),
        cycle_description: cycleDescription,
        reason_for_change: reasonForChange?.id ?? '',
        reason_for_change_description: changeDescription ?? '',
        change_request_initiator: { ...userInfo, company: undefined },
        review_status: ReviewStatus.INITIATED,
        internal_contacts: internalContacts,
        external_contacts: externalContacts,
      }

      const changeRequests: BulkChangeRequestType[] =
        itemVendorFactoryTimelines.map((itemVendorFactoryTimeline) => {
          const newTimeline = previewNewTimeline({
            factoryTimelineItems:
              itemVendorFactoryTimeline.factoryTimelineItems,
          })
          const vendor = itemVendorFactoryTimeline.vendor
          return {
            ...baseChangeRequest,
            tcin: itemVendorFactoryTimeline.tcin,
            dpci: itemVendorFactoryTimeline.dpci,
            vendor_id: vendor?.vendor_id ?? '',
            vendor_name: vendor?.vendor_name ?? '',
            gms_vendor_number: vendor?.gms_vendor_number ?? '',
            has_vop_changed: false,
            factory_timelines: cleanTimeline(newTimeline.factoryTimelineItems),
            diff_factory_timelines: cleanTimeline(
              itemVendorFactoryTimeline.factoryTimelineItems,
            ),
          }
        })

      submitChangeRequest(changeRequests).then((resp: any) => {
        setInProgress(false)
        onSubmit(resp)
      })
    }
  }

  useEffect(() => {
    let isFormValidTemp = true

    if (!reasonForChange || !changeDescription) {
      isFormValidTemp = false
    }

    if (userInfo.user_type === UserType.EXTERNAL) {
      if (internalContacts.length === 0 || externalContacts.length === 0) {
        isFormValidTemp = false
      }
    }

    setIsFormValid(isFormValidTemp)
  }, [
    reasonForChange,
    changeDescription,
    internalContacts,
    externalContacts,
    userInfo,
  ])

  return (
    <Card style={{ width: '40vw', height: '60vh' }}>
      {inProgress && <ProgressBar indeterminate />}
      <div className="hc-pa-normal">
        <Grid.Container className="hc-pl-expanded hc-pr-expanded">
          <Grid.Item>
            <p style={{ fontWeight: 'bold' }}>
              Please fill in below fields and hit Submit to submit change
              request.
            </p>
          </Grid.Item>
          <Grid.Item xs={6} className="hc-pt-none hc-pb-none">
            <Autocomplete
              id="reasonForChange"
              options={ReasonForChangeOptions.map((value: string) => {
                return { id: value, value, label: value }
              })}
              required
              label="Reason for Change"
              errorText="Please select a reason."
              error={showErrors ? (reasonForChange ? false : true) : false}
              value={reasonForChange}
              onUpdate={(id, value) => {
                setReasonForChange(value)
              }}
            />
          </Grid.Item>
          <Grid.Item xs={6} className="hc-pt-none hc-pb-none">
            <Form.Field
              id="reasonForChangeDescription"
              label="Change Description"
              type="textarea"
              required
              size="dense"
              errorText="Please describe the reason for change."
              error={showErrors ? (changeDescription ? false : true) : false}
              value={changeDescription}
              onChange={(e: any) => {
                setChangeDescription(e.target.value)
              }}
            />
          </Grid.Item>
          <Grid.Item xs={6} className="hc-pt-none hc-pb-none">
            <CycleDescDropDown onCycleDescChange={setCycleDescription} />
          </Grid.Item>
          {userInfo.user_type === UserType.EXTERNAL && (
            <>
              <Grid.Item xs={12} className="hc-pt-none hc-pb-none">
                <EmailField
                  emails={internalContacts}
                  setEmails={setInternalContacts}
                  id="internalContacts"
                  label="Target Approver Email(s)"
                  errorText="Please enter a valid Target E-mail(@target.com) and press enter."
                  checkTargetDomain={true}
                  showErrorMessage={showErrors}
                />
              </Grid.Item>
              <Grid.Item xs={12} className="hc-pt-dense hc-pb-dense">
                <EmailField
                  emails={externalContacts}
                  setEmails={setExternalContacts}
                  id="externalContacts"
                  label="Vendor Contact Email(s)"
                  errorText="Please enter a valid E-mail and press enter."
                  checkTargetDomain={false}
                  showErrorMessage={showErrors}
                />
              </Grid.Item>
            </>
          )}
          <Grid.Item xs={12}>
            <Grid.Container justify="flex-end">
              <Grid.Item>
                <Button type="secondary" onClick={() => onPrev()} size="dense">
                  <span style={{ textTransform: 'capitalize' }}>
                    Back: Review
                  </span>
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  type="primary"
                  onClick={onSubmitRequest}
                  disabled={inProgress}
                  size="dense"
                >
                  <span style={{ textTransform: 'capitalize' }}>Submit</span>
                </Button>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
      </div>
    </Card>
  )
}

export default SubmitChangeRequestForm
