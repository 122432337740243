import React from 'react'
import { Card, Grid } from '@enterprise-ui/canvas-ui-react'

interface FactoryDetailsCardHeaderProps {
  vendorName: string
  bpId: string
  mfgStyle: string
}

const VerticalDivider = () => {
  return (
    <div
      style={{
        marginTop: 5,
        borderLeft: '2px solid white',
        height: 50,
      }}
    ></div>
  )
}

const FactoryDetailsCardHeader: React.FC<FactoryDetailsCardHeaderProps> = ({
  vendorName,
  bpId,
  mfgStyle,
}) => {
  return (
    <Card className="hc-pa-normal hc-mb-expanded hc-bg-grey05">
      <Grid.Container justify="space-between">
        <Grid.Item>
          <p>Vendor</p>
          <h4>{vendorName}</h4>
        </Grid.Item>

        <Grid.Item>
          <Grid.Container>
            <Grid.Item>
              <VerticalDivider />
            </Grid.Item>
            <Grid.Item>
              <p>BP ID</p>
              <h4>{bpId}</h4>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>

        <Grid.Item>
          <Grid.Container>
            <Grid.Item>
              <VerticalDivider />
            </Grid.Item>
            <Grid.Item>
              <p>Mfg Style#</p>
              <h4>{mfgStyle}</h4>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
    </Card>
  )
}

export default FactoryDetailsCardHeader
