import React, { ReactNode } from 'react'
import { SideNavContextProvider } from './SideNavContext'
import { UserInfoContextProvider } from './UserInfoContext'
import { BusinessPartnerInfoContextProvider } from './BusinessPartnerInfoContext'
import { PageInfoContextProvider } from './PageInfoContext'
import { HelpContextProvider } from './HelpContext'
import { ItemDetailsModalContextProvider } from './ItemDetailsModalContext'
import { TourContextProvider } from './TourContext'
import { FeedbackContextProvider } from './FeedbackContext'
import { ReleaseNotesContextProvider } from './ReleaseNotesContext'
import { LocalStorageContextProvider } from './LocalStorageContext'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'

interface Props {
  children: ReactNode
}

const AllContextProviders = ({ children }: Props) => {
  //TODO need to come up with a logic to nest these providers automatically
  return (
    <SideNavContextProvider>
      <ToastProvider location="top">
        <UserInfoContextProvider>
          <BusinessPartnerInfoContextProvider>
            <PageInfoContextProvider>
              <ItemDetailsModalContextProvider>
                <HelpContextProvider>
                  <FeedbackContextProvider>
                    <ReleaseNotesContextProvider>
                      <LocalStorageContextProvider>
                        <TourContextProvider>{children}</TourContextProvider>
                      </LocalStorageContextProvider>
                    </ReleaseNotesContextProvider>
                  </FeedbackContextProvider>
                </HelpContextProvider>
              </ItemDetailsModalContextProvider>
            </PageInfoContextProvider>
          </BusinessPartnerInfoContextProvider>
        </UserInfoContextProvider>
      </ToastProvider>
    </SideNavContextProvider>
  )
}

export { AllContextProviders }
