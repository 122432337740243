import React, { ReactNode } from 'react'
import { useState } from 'react'
import { useReload } from '../hooks/useForceReload'

const SideNavContext = React.createContext<any>(null)

interface Props {
  children: ReactNode
}

const SideNavContextProvider = ({ children }: Props) => {
  const [isSideNavVisible, setIsSideNavVisible] = useState<boolean>(true)
  const [isRefresh, refreshSideNav] = useReload()

  return (
    <SideNavContext.Provider
      value={{
        isSideNavVisible,
        setIsSideNavVisible,
        isRefresh,
        refreshSideNav,
      }}
    >
      {children}
    </SideNavContext.Provider>
  )
}

const useSideNav = (): {
  isSideNavVisible: boolean
  setIsSideNavVisible: (isSideNavVisible: boolean) => void
  isRefresh: number
  refreshSideNav: () => void
} => {
  return React.useContext(SideNavContext)
}

export { SideNavContextProvider, useSideNav }
