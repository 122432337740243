import {
  ItemDetailsType,
  ItemDetailsSearchType,
  FactoryTimelineItem,
} from '../types/ItemDetailsType'
import { SearchCriteriaType } from '../types/SearchCriteriaType'
import { ItemSearchResultType } from '../types/ItemSearchResultType'
import { getBaseUrl } from '../config/AppConfig'
import { AxiosUtil } from '../utils/AxiosUtil'
import { debounce } from 'lodash'
import { saveAs } from 'file-saver'
const he = require('he')

const searchItems = (
  searchCriteria: SearchCriteriaType,
): Promise<ItemSearchResultType[]> => {
  const url = getBaseUrl() + '/items/search'
  return AxiosUtil.post(url, searchCriteria)
    .then((response) => response.data)
    .then((data) => decodeHtml(data))
}
const debounceSearchItems = debounce((key, resolve, reject) => {
  searchItems(key).then(resolve).catch(reject)
}, 500)

const exportItemFactories = async (searchCriteria: SearchCriteriaType) => {
  const url = getBaseUrl() + '/reports/item_factories_report'
  return AxiosUtil.post(url, searchCriteria, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  }).then((res) => {
    const blob = new Blob([res.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    saveAs(blob, 'item-factories.xlsx')
  })
}

const decodeHtml = (itemDataRows: any) => {
  itemDataRows.forEach((itemDataRow: any) => {
    itemDataRow.description = he.decode(itemDataRow.description)
  })
  return itemDataRows
}

const getItemDetails = async (
  tcins: ItemDetailsSearchType,
): Promise<ItemDetailsType[]> => {
  const url = getBaseUrl() + '/items/item_factory_details'
  return AxiosUtil.post(url, tcins)
    .then((response) => response.data)
    .then((resp) => {
      const itemDetails: ItemDetailsType[] = resp
      itemDetails.forEach((itemDetail) => {
        datesMapper(itemDetail)
      })
      return resp
    })
    .then((data) => decodeHtml(data))
}

const getItemsWithNoFactoryCount = async (
  vendors: String[],
): Promise<number> => {
  const url =
    getBaseUrl() +
    '/reports/items_without_factories_count?vendor_ids=' +
    vendors
  return AxiosUtil.get(url)
    .then((response) => response.data)
    .then((data) => {
      return data.count
    })
}

const getItemsWithInvalidFactoryCount = async (
  vendors: String[],
): Promise<number> => {
  const url =
    getBaseUrl() + '/reports/invalid_factories_count?vendor_ids=' + vendors
  return AxiosUtil.get(url)
    .then((response) => response.data)
    .then((data) => {
      return data.count
    })
}

const getItemsWithNoVopCount = async (vendors: String[]): Promise<number> => {
  const url =
    getBaseUrl() + '/reports/factory_with_no_vop?vendor_ids=' + vendors
  return AxiosUtil.get(url)
    .then((response) => response.data)
    .then((data) => {
      return data.count
    })
}

const datesMapper = (itemDetail: ItemDetailsType) => {
  itemDetail.vendor_factory_timelines.forEach((factoryTimeline) =>
    factoryTimelineItemsDateMapper(factoryTimeline.factory_timelines),
  )
  if (itemDetail.change_requests && itemDetail.change_requests[0]) {
    itemDetail.change_requests.forEach((changeRequest) =>
      factoryTimelineItemsDateMapper(changeRequest.diff_factory_timelines),
    )
  }
  return itemDetail
}

const factoryTimelineItemsDateMapper = (
  factoryTimelineItems: FactoryTimelineItem[],
) => {
  factoryTimelineItems.forEach((factoryTimeline) => {
    factoryTimeline.effective_start_date = new Date(
      new Date(factoryTimeline.effective_start_date),
    )
    factoryTimeline.effective_end_date = new Date(
      new Date(factoryTimeline.effective_end_date),
    )

    factoryTimeline.factories.forEach((factory) => {
      factory.effective_start_date = new Date(
        factoryTimeline.effective_start_date,
      )
      factory.effective_end_date = new Date(factoryTimeline.effective_end_date)
    })
  })
}

export {
  searchItems,
  debounceSearchItems,
  getItemDetails,
  getItemsWithNoFactoryCount,
  getItemsWithInvalidFactoryCount,
  getItemsWithNoVopCount,
  exportItemFactories,
}
