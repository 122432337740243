import { Form, Input } from '@enterprise-ui/canvas-ui-react'
import { FunctionComponent, useEffect, useState } from 'react'
import { useUserInfo } from '../../../context/UserInfoContext'
import { captureButtonClickEvent } from '../../../services/analytics/events-service'
import { UserType } from '../../../utils/Constants'
import './Filters.scss'

type AllChangeRequestsFiltersProps = {
  pendingOnly: boolean
  assignedToMeOnly?: boolean
  onFilterUpdate: (pendingOnly: boolean, assignedToUserOnly: boolean) => void
}

const AllChangeRequestsFilters: FunctionComponent<
  AllChangeRequestsFiltersProps
> = ({ pendingOnly, assignedToMeOnly, onFilterUpdate }) => {
  const [statusFilter, setStatusFilter] = useState<string>(
    pendingOnly ? 'PendingOnly' : 'AllRequests',
  )
  const [assignedToFilter, setAssignedToFilter] = useState<string>(
    assignedToMeOnly ? 'Me' : 'AllOfTarget',
  )
  const { userInfo } = useUserInfo()

  useEffect(() => {
    onFilterUpdate(
      statusFilter === 'PendingOnly' ? true : false,
      assignedToFilter === 'Me' ? true : false,
    )
  }, [statusFilter, assignedToFilter, onFilterUpdate])

  return (
    <div className="filters-container">
      <div className="filter-radio-button">
        <p className="radio-button-name">Filter by Status</p>
        <Form.Field
          id="StatusFilter"
          type="radio"
          value={statusFilter}
          options={[
            { value: 'PendingOnly', label: 'Pending Only' },
            { value: 'AllRequests', label: 'All Requests' },
          ]}
          onUpdate={(id: any, value: string) => {
            setStatusFilter(value)
            captureButtonClickEvent(
              'Change Request Status Filter',
              value === 'PendingOnly' ? 'PendingOnly' : 'All',
            )
          }}
        />
      </div>
      {userInfo.user_type === UserType.INTERNAL && (
        <div className="filter-radio-button">
          <p className="radio-button-name">Filter by Assigned To</p>
          <Input.Radio
            id="AssignedToFilter"
            value={assignedToFilter}
            options={[
              { value: 'Me', label: 'Me' },
              { value: 'AllOfTarget', label: 'All of Target' },
            ]}
            onUpdate={(id: any, value: string) => {
              setAssignedToFilter(value)
              captureButtonClickEvent(
                'Change Request AssignedTo Filter',
                value === 'Me' ? 'Me' : 'All of Target',
              )
            }}
          />
        </div>
      )}
    </div>
  )
}

export default AllChangeRequestsFilters
