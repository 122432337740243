import { Brand } from '../../../types/ItemDetailsType'

export const BulkChangeRequestColumDefs = [
  {
    headerName: 'TCIN',
    field: 'tcin',
    flex: 1,
    cellRenderer: 'itemFactoryDetailsButton',
    cellRendererParams: (params: any) => {
      return { tcin: params.data.tcin, openModal: true }
    },
  },
  {
    headerName: 'DPCI',
    field: 'dpci',
    flex: 1,
  },
  {
    headerName: 'Description',
    field: 'description',
    flex: 1,
  },
  {
    headerName: 'Dept',
    valueGetter: function (params: any) {
      return params.data.department_id + ' - ' + params.data.department_name
    },
    flex: 1,
  },
  {
    headerName: 'Class',
    valueGetter: function (params: any) {
      return params.data.class_id + ' - ' + params.data.class_name
    },
    flex: 1,
  },
  {
    headerName: 'Brand',
    valueGetter: function (params: any) {
      return (
        params.data.brands &&
        params.data.brands.filter((brand: Brand) => {
          return brand.is_primary === true
        })[0]?.name
      )
    },
    flex: 1,
  },
]
