export const getSearchResultsColumDefs = (isInternal: boolean = false) => {
  let searchColumnDefs: any[] = [
    {
      headerName: 'TCIN',
      field: 'tcin',
      flex: 1,
      cellRenderer: 'itemFactoryDetailsButton',
      checkboxSelection: !isInternal,
      cellRendererParams: (params: any) => {
        return { tcin: params.data.tcin }
      },
    },
    {
      headerName: 'DPCI',
      field: 'dpci',
      flex: 1,
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: 1,
    },
    {
      headerName: 'Department',
      field: 'department',
      flex: 1,
    },
    {
      headerName: 'Class',
      field: 'clazz',
      flex: 1,
    },
    {
      headerName: 'Status',
      field: 'item_status',
      flex: 1,
    },
    {
      headerName: 'Mfg Style#',
      valueGetter: function (params: any) {
        const mfgStyles = new Set()
        params.data.vendors.forEach((v: any) =>
          mfgStyles.add(v['manufacturer_style']),
        )
        return Array.from(mfgStyles).join()
      },
      flex: 1,
    },
    {
      headerName: 'Spark PID',
      field: 'pids',
      valueFormatter: (params: any) => params.value.join(', '),
      flex: 1,
    },
  ]

  if (isInternal) {
    searchColumnDefs.push({
      headerName: 'Vendor Count',
      valueGetter: function (params: any) {
        return params.data.vendors.length
      },
      flex: 1,
    })
  }

  searchColumnDefs.push({
    headerName: 'Factory Count',
    field: 'vendor_factories_count',
    flex: 1,
  })

  return searchColumnDefs
}

export const BulkVopUpdateReviewColumDefs = () => {
  const columnDefs: any[] = [
    {
      headerName: 'TCIN',
      field: 'tcin',
      width: 75,
    },
    {
      headerName: 'DPCI',
      field: 'dpci',
      width: 90,
    },
    {
      headerName: 'Description',
      field: 'description',
      width: 200,
    },
    {
      headerName: 'Active Factory',
      field: 'activeFactory',
      width: 70,
    },
    {
      headerName: 'Selected Factory',
      field: 'selectedFactory',
      width: 150,
    },
    {
      headerName: 'VOP',
      field: 'vop',
      width: 51,
    },
    {
      headerName: 'Edit Status',
      cellClassRules: {
        'ag-grid-cell-warning': function (parms: any) {
          if (parms.value !== 'Successful') {
            return true
          } else {
            return false
          }
        },
        'ag-grid-check-mark': function (parms: any) {
          if (parms.value === 'Successful') {
            return true
          } else {
            return false
          }
        },
      },
      field: 'vopUpdateResult',
      flex: 1,
    },
  ]

  return columnDefs
}
