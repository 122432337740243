import React, { useEffect } from 'react'
import { Layout, Grid, Button, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { AuthProvider, useAuth } from '@praxis/component-auth'
import { getAuthConfig, getAppConfig } from '../config/AppConfig'
import { BrowserRouter as Router, NavLink } from 'react-router-dom'
import { FactorialSideNav } from '../components/FactorialSideNav/FactorialSideNav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIndustry } from '@fortawesome/free-solid-svg-icons'
import { useSideNav } from '../context/SideNavContext'
import { useUserInfo } from '../context/UserInfoContext'
import { usePageInfo } from '../context/PageInfoContext'
import { UserType } from '../utils/Constants'
import { WithAuthUser } from '../components/UserProvider'
import { isSupportedBrowser } from '../utils/BrowserUtil'
import { useIdleTimer } from 'react-idle-timer'
import AppRoutes from '../routes/AppRoutes'
import Feedback from '../components/Feedback/Feedback'
import { Help } from '../components/Help'
import './AppLayout.scss'

import {
  faAngry,
  faFrownOpen,
  faMeh,
  faSmile,
  faLaughBeam,
} from '@fortawesome/free-regular-svg-icons'
import EnterpriseIcon, { HuddleIcon, MenuIcon } from '@enterprise-ui/icons'
import { trackCustomEvent } from '../services/firefly/firefly-service'
import { useFeedback } from '../context/FeedbackContext'

const AppLayout = () => {
  const auth = getAuthConfig()
  const { isSideNavVisible } = useSideNav()
  return (
    <AuthProvider {...auth}>
      <Router>
        <Layout theme="target" darkMode={false} fullWidth={!isSideNavVisible}>
          <WithAuthUser>
            <FactorialSideNav />
            <Header />
          </WithAuthUser>
          <Body />
        </Layout>
      </Router>
    </AuthProvider>
  )
}

const icons = [
  {
    iconId: 'angry',
    iconName: 'mad',
    icon: (
      <Tooltip location="bottom" content="Mad">
        <FontAwesomeIcon icon={faAngry} size="3x" />
      </Tooltip>
    ),
    rating: 1,
    selected: false,
  },
  {
    iconId: 'frownopen',
    iconName: 'sad',
    icon: (
      <Tooltip location="bottom" content="Sad">
        <FontAwesomeIcon icon={faFrownOpen} size="3x" />
      </Tooltip>
    ),
    rating: 2,
    selected: false,
  },
  {
    iconId: 'meh',
    iconName: 'meh',
    icon: (
      <Tooltip location="bottom" content="Meh">
        <FontAwesomeIcon icon={faMeh} size="3x" />
      </Tooltip>
    ),
    rating: 3,
    selected: false,
  },
  {
    iconId: 'smile',
    iconName: 'good',
    icon: (
      <Tooltip location="bottom" content="Good">
        <FontAwesomeIcon icon={faSmile} size="3x" />
      </Tooltip>
    ),
    rating: 4,
    selected: false,
  },
  {
    iconId: 'laughbeam',
    iconName: 'great',
    icon: (
      <Tooltip location="bottom" content="Great">
        <FontAwesomeIcon icon={faLaughBeam} size="3x" />
      </Tooltip>
    ),
    rating: 5,
    selected: false,
  },
]

const Header = () => {
  const { pageTitle } = usePageInfo()
  const { userInfo } = useUserInfo()
  const { isFeedbackOpen, setIsFeedbackOpen, feedbackTitle, setFeedbackTitle } =
    useFeedback()
  const { isSideNavVisible, setIsSideNavVisible } = useSideNav()
  useEffect(() => {
    trackCustomEvent('visitor', 'visitor', 'visitor')
  }, [])
  return (
    <Layout.Header includeRail>
      <Grid.Container justify="space-between" align="center">
        <Grid.Item>
          <Grid.Container justify="flex-start" align="center" spacing="dense">
            <Grid.Item>
              <Button
                size="dense"
                type="ghost"
                onClick={() => {
                  setIsSideNavVisible(!isSideNavVisible)
                }}
              >
                <EnterpriseIcon icon={MenuIcon} color="white" size="lg" />
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button size="dense" type="ghost" as={NavLink} to="/">
                <FontAwesomeIcon icon={faIndustry} color="white" size="2x" />
              </Button>
            </Grid.Item>

            <Grid.Item>
              <p
                style={{
                  color: 'white',
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
              >
                {pageTitle}
              </p>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        {!isSupportedBrowser() && (
          <Grid.Item>
            Please use Google Chrome for better user experience.
          </Grid.Item>
        )}
        <Grid.Item>
          <Grid.Container justify="flex-end" align="center">
            <Grid.Item className="header-options">
              <Button
                type="ghost"
                onClick={() => {
                  setFeedbackTitle('Send feedback to the Factorial team')
                  setIsFeedbackOpen(true)
                }}
              >
                <EnterpriseIcon icon={HuddleIcon} color="white" size="lg" />
                <span
                  style={{
                    marginLeft: '6px',
                    color: 'white',
                    textTransform: 'capitalize',
                  }}
                >
                  Send Feedback
                </span>
              </Button>
            </Grid.Item>
            <Grid.Item className="header-options">
              <Help />
            </Grid.Item>
            <Grid.Item>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: 'white',
                  marginRight: '25px',
                }}
              >
                <p
                  style={{
                    color: 'white',
                  }}
                >
                  Welcome, {userInfo.user_name}!
                </p>
                {userInfo.user_type === UserType.EXTERNAL && (
                  <p
                    style={{
                      color: 'white',
                      fontSize: '10px',
                    }}
                  >
                    {userInfo.company}
                  </p>
                )}
              </div>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
      {isFeedbackOpen && (
        <Grid.Item>
          <Feedback
            setShowFeedback={setIsFeedbackOpen}
            showFeedback={isFeedbackOpen}
            headingText={feedbackTitle}
            iconsInfo={icons}
          />
        </Grid.Item>
      )}
    </Layout.Header>
  )
}

const Body = React.memo(() => {
  const { userInfo } = useUserInfo()
  const auth = useAuth()
  const onIdle = () => {
    if (userInfo.user_type === UserType.INTERNAL) {
      //logout for internal user
      const { logout } = auth
      logout()
    } else {
      //Navigate to home page for External users. This is to avoid SSO logoff.
      window.location.href = '/'
    }
  }

  useIdleTimer({
    element: document,
    onIdle: onIdle,
    timeout: getAppConfig().idleTimeout,
  })

  return (
    <Layout.Body includeRail>
      <AppRoutes />
    </Layout.Body>
  )
})

export default AppLayout
