import React, { useState } from 'react'
import { Button, Dialog } from '@enterprise-ui/canvas-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { captureButtonClickEvent } from '../../services/analytics/events-service'

interface AddFactoryButtonProps {
  showButton: boolean
  disabled: boolean
  onAddFactoryButtonClick: () => void
}

export const AddFactoryButton: React.FC<AddFactoryButtonProps> = ({
  showButton,
  disabled,
  onAddFactoryButtonClick,
}) => {
  const [showAddFactoryDialog, setShowAddFactoryDialog] =
    useState<boolean>(false)
  return (
    <>
      {showButton && (
        <Button
          disabled={disabled}
          onClick={() => {
            setShowAddFactoryDialog(true)
          }}
        >
          <FontAwesomeIcon
            className="hc-mr-dense"
            icon={faPlusCircle}
            size="lg"
          />
          Add Factory
        </Button>
      )}
      <Dialog
        headingText="Please note, this will ADD a factory to the timeline, implying production at two locations at the same time. Please use REPLACE Factory if you want to change from one location to another."
        approveButtonText="Ok"
        onApprove={() => {
          setShowAddFactoryDialog(false)
          captureButtonClickEvent('Add Factory')
          onAddFactoryButtonClick()
        }}
        onRefuse={() => {
          setShowAddFactoryDialog(false)
        }}
        refuseButtonText="Cancel"
        isVisible={showAddFactoryDialog}
      />
    </>
  )
}
