import { Button, Form, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import React, { useEffect, useState } from 'react'
import { ReasonForRejection } from '../../../utils/Constants'

interface RejectConfirmModalProps {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  onRejectClicked: (
    reasonForRejection: string,
    commentsForRejection: string,
  ) => void
}
const RejectConfirmModal: React.FC<RejectConfirmModalProps> = (
  props: RejectConfirmModalProps,
) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [commentsForRejection, setCommentsForRejection] = useState<string>('')
  const [reasonForRejection, setReasonForRejection] = useState<string>()
  const [isFormValid, setIsFormValid] = useState<boolean>()

  const onRejectClicked = () => {
    if (!isFormValid) {
      setShowErrorMessage(true)
    } else if (reasonForRejection) {
      props.onRejectClicked(reasonForRejection, commentsForRejection)
    }
  }

  useEffect(() => {
    let isFormValidTemp = true

    if (!reasonForRejection) {
      isFormValidTemp = false
    }

    if (reasonForRejection === 'Other' && !commentsForRejection) {
      isFormValidTemp = false
    }

    setIsFormValid(isFormValidTemp)
  }, [commentsForRejection, reasonForRejection])

  return (
    <Modal
      headingText="Confirm factory change rejection"
      isVisible={props.showModal}
      onRefuse={() => props.setShowModal(false)}
    >
      <div className="hc-pa-normal">
        <Grid.Container direction="column">
          <Grid.Item xs={12}>
            <p>
              Rejecting the factory change request will send an email to the
              Business Partner who requested the change.
            </p>
            <p>Are you sure you want to reject the request?</p>
          </Grid.Item>
          <Grid.Item xs={6}>
            <Form.Field
              type="select"
              id="reasonForRejection"
              onUpdate={(e: any, value: any) => {
                setReasonForRejection(value)
              }}
              label="Reason"
              errorText="Please select a reason"
              error={!reasonForRejection && showErrorMessage}
              required
              options={ReasonForRejection.map((reason: string) => {
                return { value: reason, label: reason }
              })}
            />
          </Grid.Item>
          <Grid.Item xs={6} className="hc-pt-none hc-pb-none">
            <Form.Field
              id="rejectionDetails"
              label="Comments"
              type="textarea"
              size="dense"
              errorText="Comments field is mandatory when you chose Other as reason for rejection"
              error={
                showErrorMessage ? (commentsForRejection ? false : true) : false
              }
              value={commentsForRejection}
              onChange={(e: any) => {
                setCommentsForRejection(e.target.value)
              }}
            />
          </Grid.Item>
        </Grid.Container>

        <Grid.Container direction="row-reverse" spacing="dense">
          <Grid.Item>
            <Button type="primary" onClick={onRejectClicked}>
              Yes, Reject
            </Button>
          </Grid.Item>
          <Grid.Item>
            <Button
              onClick={() => {
                props.setShowModal(false)
              }}
            >
              No, Cancel
            </Button>
          </Grid.Item>
        </Grid.Container>
      </div>
    </Modal>
  )
}

export default RejectConfirmModal
