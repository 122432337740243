import React from 'react'
import { ChangeRequestType } from '../../types/ChangeRequestType'
import ChangeHistoryCard from './ChangeHistoryCard'

interface ChangeHistoryCardsProps {
  changeRequests: ChangeRequestType[]
}

const ChangeHistoryCards: React.FC<ChangeHistoryCardsProps> = ({
  changeRequests,
}) => {
  return (
    <div style={{ height: '60vh', overflowY: 'auto' }}>
      {changeRequests.map((changeRequest) => (
        <ChangeHistoryCard
          changeRequest={changeRequest}
          key={changeRequest.change_request_id}
        />
      ))}
    </div>
  )
}

export default ChangeHistoryCards
