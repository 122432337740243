import React, { useEffect, FunctionComponent } from 'react'

import FactorialGrid from '../FactorialGrid/FactorialGrid'
import { useGrid } from '../../hooks/useGrid'
import { TcinVopChangeDetail } from '../../types/ItemDetailsType'
import { vopGridOptions } from './VopGridOptions'
import { Button, Grid } from '@enterprise-ui/canvas-ui-react'
import { BulkVopUpdateReviewColumDefs } from '../SearchResultsGrid/ColumnDefintions'

type ReviewVOPChangesPropsType = {
  reviewVOPChangedForTcins: TcinVopChangeDetail[]
  onNext: () => void
  onPrev: () => void
}

const ReviewVOPChangesGrid: FunctionComponent<ReviewVOPChangesPropsType> = ({
  reviewVOPChangedForTcins,
  onNext,
  onPrev,
}) => {
  const gridWidth = '100%'
  const { gridReady, onGridReady, getGridApi } = useGrid()
  const vopReviewForTcinGrid = {
    ...vopGridOptions,
    onGridReady: onGridReady,
  }
  useEffect(() => {
    if (gridReady) {
      getGridApi().setColumnDefs(BulkVopUpdateReviewColumDefs())
    }
  }, [getGridApi, gridReady])

  useEffect(() => {
    if (gridReady) {
      getGridApi().setRowData(Object.values(reviewVOPChangedForTcins))
    }
  }, [getGridApi, gridReady, reviewVOPChangedForTcins])

  return (
    <>
      <Grid.Item className="hc-bg-grey06 hc-ma-normal">
        <div
          style={{ width: '110%', display: '100%', justifyContent: 'flex-end' }}
        >
          <FactorialGrid
            gridOptions={vopReviewForTcinGrid}
            gridWidth={gridWidth}
          />
        </div>
      </Grid.Item>
      <Grid.Container justify="flex-end">
        <Grid.Item>
          <Button type="secondary" onClick={() => onPrev()} size="dense">
            <span style={{ textTransform: 'capitalize' }}>
              Back: Choose VOP
            </span>
          </Button>
        </Grid.Item>
        <Grid.Item>
          <Button type="primary" onClick={() => onNext()} size="dense">
            <span style={{ textTransform: 'capitalize' }}>Submit</span>
          </Button>
        </Grid.Item>
      </Grid.Container>
    </>
  )
}

export default ReviewVOPChangesGrid
