import { Button, Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import React, { useEffect, useState } from 'react'
import { lookupVendorIdDept } from '../../services/LookupService'
import { AutoCompleteOptionType } from '../../types/AutoCompleteOptionType'
import { Location } from '../../types/BusinessPartnerType'
import { ItemDetailsType, LeanFactory } from '../../types/ItemDetailsType'
import { LookupValue } from '../../types/LookupValue'
import {
  getTodaysDatestring,
  getTodaysDateFormatted,
} from '../../utils/DateUtil'
import { convertToFactoryOptions } from '../../utils/FactoryLocationsUtil'
interface AddFactoryFormProps {
  onAddFactory: (factoryToAdd: LeanFactory) => void
  onCancel: () => void
  locations: Location[]
  showForm: boolean
  hideDates?: boolean
  selectedVendor: AutoCompleteOptionType
  itemDetails: ItemDetailsType
}

const endDateDefault = '9999/12/31 GMT'

export const AddFactoryForm: React.FC<AddFactoryFormProps> = ({
  onAddFactory,
  onCancel,
  locations,
  showForm,
  hideDates = false,
  selectedVendor,
  itemDetails,
}) => {
  const [newFactoryLocationOption, setNewFactoryLocationOption] =
    useState<AutoCompleteOptionType>()
  const [newVOP, setNewVOP] = useState<AutoCompleteOptionType>()
  const [newFactoryEffectiveStartDate, setNewFactoryEffectiveStartDate] =
    useState<string>()
  const [newFactoryEffectiveEndDate, setNewFactoryEffectiveEndDate] =
    useState<string>(endDateDefault)
  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  useEffect(() => {
    if (hideDates) {
      setNewFactoryEffectiveStartDate(getTodaysDatestring())
      setNewFactoryEffectiveEndDate(endDateDefault)
    }
  }, [hideDates])

  useEffect(() => {
    const isValidNewFactoryInput =
      newVOP &&
      newFactoryLocationOption &&
      newFactoryEffectiveStartDate &&
      newFactoryEffectiveEndDate &&
      new Date(newFactoryEffectiveEndDate) >=
        new Date(newFactoryEffectiveStartDate)
        ? true
        : false

    setIsFormValid(isValidNewFactoryInput)
  }, [
    newFactoryLocationOption,
    newVOP,
    newFactoryEffectiveStartDate,
    newFactoryEffectiveEndDate,
    setIsFormValid,
  ])

  const [vendeorIdDeptOptionState, setvendeorIdDeptOptionState] = useState<
    LookupValue[]
  >([])

  //UseEffect ->
  useEffect(() => {
    const fetchLookUpVendorId = async () => {
      const result = await lookupVendorIdDept(
        selectedVendor.id + '-' + itemDetails.department_id,
      )
      setvendeorIdDeptOptionState(result)
    }
    fetchLookUpVendorId()
  }, [selectedVendor.id, itemDetails.department_id])

  // When add f load fetch the list vop and set them state varible use
  const addNewFactory = () => {
    if (
      newFactoryLocationOption &&
      newVOP &&
      newFactoryEffectiveStartDate &&
      newFactoryEffectiveEndDate
    ) {
      onAddFactory({
        factory_id: newFactoryLocationOption.id,
        factory_name: newFactoryLocationOption.value,
        vendor_order_spec_id: newVOP.id,
        effective_start_date: new Date(
          newFactoryEffectiveStartDate + ' 00:00:00 GMT',
        ),
        effective_end_date: new Date(
          newFactoryEffectiveEndDate + ' 00:00:00 GMT',
        ),
        factory_active: true,
      })
    }
  }

  return (
    <>
      {showForm && (
        <Grid.Container
          direction="column"
          className="hc-bg-grey07 hc-ma-dense hc-pa-dense"
        >
          <Grid.Item xs={12}>
            <Autocomplete
              id="factory"
              label="Factory"
              options={convertToFactoryOptions(locations)}
              required
              value={newFactoryLocationOption}
              errorText="Please select a factory"
              // error={!changeRequest.factory_id && showErrorMessage}
              onUpdate={(id, value) => {
                setNewFactoryLocationOption(value)
              }}
            />
            <Autocomplete
              label="VOP"
              id="vop"
              required
              filter={Autocomplete.filters.none}
              onUpdate={(id, value) => {
                setNewVOP(value)
              }}
              value={newVOP}
              options={vendeorIdDeptOptionState}
            />
          </Grid.Item>

          <Grid.Item>
            <Grid.Container align="center">
              <Grid.Item>
                <DatePicker
                  id="effectiveStartDate"
                  label="Effective Start Date"
                  disableDates={{
                    before: getTodaysDateFormatted(),
                  }}
                  errorText="Start date should be less than end date"
                  onUpdate={(id: any, value: any) => {
                    value && setNewFactoryEffectiveStartDate(value)
                  }}
                  location="bottom-right"
                />
              </Grid.Item>
              <Grid.Item>
                <DatePicker
                  id="effectiveEndDate"
                  label="Effective End Date"
                  disableDates={{ past: true }}
                  errorText="Start date should be less than end date"
                  onUpdate={(id: any, value: any) => {
                    value && setNewFactoryEffectiveEndDate(value)
                  }}
                  location="bottom-right"
                />
              </Grid.Item>
              <Grid.Item>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <Button
                    type="primary"
                    size="dense"
                    disabled={!isFormValid}
                    onClick={addNewFactory}
                  >
                    Add
                  </Button>
                  <Button size="dense" onClick={onCancel}>
                    Cancel
                  </Button>
                </div>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
      )}
    </>
  )
}
