import axios, { AxiosRequestConfig } from 'axios'
import { getAppConfig, getBaseUrl } from '../config/AppConfig'
import { analyticsService } from '../services/analytics/analytics-service'
import Cookies from 'js-cookie'
import { ToasterToastProps } from '@enterprise-ui/canvas-ui-react'

let setToast: (toast: ToasterToastProps) => void = () => {}

const initAxios = (_setToast: (toast: ToasterToastProps) => void) => {
  axios.defaults.baseURL = getBaseUrl()
  setToast = _setToast
}
//This var is to avoid duplicate toast messages
let showMessage: boolean = true
const AxiosUtil = axios.create()
AxiosUtil.interceptors.response.use(
  (response) => {
    analyticsService.requestTimerStop((response?.config as ConfigWithId)?.id)
    return response
  },
  (error) => {
    handleError(error)
    analyticsService.requestTimerStop(
      (error?.response?.config as ConfigWithId)?.id,
    )
    analyticsService.axiosException(error)
    throw error
  },
)
interface ConfigWithId extends AxiosRequestConfig {
  id: number
}

interface ConfigWithId extends AxiosRequestConfig {
  id: number
}

const addAxiosInterceptors = () => {
  AxiosUtil.interceptors.request.use(
    (config) => {
      if (isSSOSessionActive() && config.headers) {
        const accessToken = localStorage.getItem('access_token')
        const idToken = localStorage.getItem('id_token')
        if (accessToken) {
          config.headers.Authorization = accessToken
        }
        if (idToken) {
          config.headers.id_token = idToken
        }
      } else {
        throw new axios.Cancel('SSO Session expired.')
      }

      config.params = { key: getAppConfig().apiKey }

      try {
        ;(config as ConfigWithId).id = analyticsService.requestTimerStart(
          config.url!.replace(getBaseUrl(), ''),
        )
      } catch (error) {
        console.error('Error while tracking analytics call', error)
      }
      return config
    },
    (error) => {
      analyticsService.axiosException(error)
      return error
    },
  )
}

const handleError = (error: any) => {
  if (error?.response?.status === 401 && showMessage) {
    //Handle Unauthorization
    setToast({
      type: 'error',
      heading: 'Unauthorized',
      message:
        'User session not valid! Please refresh the page and login again.',
    })
    showMessage = false
    setTimeout(() => {
      //set to default after 3 sec
      showMessage = true
    }, 3000)
  } else if (error.message === 'Network Error' && showMessage) {
    //Handle network errors
    setToast({
      type: 'error',
      heading: 'Network Error',
      message: 'Please check your network.',
    })
    showMessage = false
    setTimeout(() => {
      //set to default after 3 sec
      showMessage = true
    }, 3000)
  }
}

export const getDomainName = (): string => {
  const hostName = window && window.location && window.location.hostname
  return (
    hostName &&
    hostName.substring(
      hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1,
    )
  )
}

const isSSOSessionActive = () => {
  if (getDomainName() === 'partnersonline.com') {
    //Check SSO only for the POL route
    const tokenUserName = getUserIdFromAccessToken() //Get user name from access token
    const ssoSessionCookie = Cookies.get('SSOSESSIONINFO') //Get SSO Cookie details
    if (ssoSessionCookie && ssoSessionCookie.length > 0) {
      const ssoSessionCookieJson = JSON.parse(atob(ssoSessionCookie))
      if (
        tokenUserName &&
        ssoSessionCookieJson.login_status &&
        ssoSessionCookieJson.user &&
        ssoSessionCookieJson.user.toUpperCase() === tokenUserName.toUpperCase()
      ) {
        //Valid case. User is active and user name matches
        return true
      }
    }
    //Invalid case. Session expired, clear tokens and loggoff
    localStorage.removeItem('access_token') //Remove token from LS
    localStorage.removeItem('id_token')
    setToast({
      type: 'success',
      heading: 'Session Expired or Company Changed!',
      message:
        'POL session logged out or company changed. Please refresh and continue',
    })
    setTimeout(() => {
      window.location.href = '/' //Navigate to home page to login again
    }, 3000)
    return false
  } else {
    //No need to check SSO for internal users.
    return true
  }
}

const getUserIdFromAccessToken = () => {
  const accessToken = localStorage.getItem('access_token')
  if (accessToken) {
    const userDetails = accessToken.split('.')[1]
    if (userDetails) {
      const userDetailsJSON = JSON.parse(atob(userDetails))
      return userDetailsJSON.username
    }
  }
  return null
}

const getUserDetailsFromIdToken = () => {
  const idToken = localStorage.getItem('id_token')
  if (idToken) {
    const userDetails = idToken.split('.')[1]
    if (userDetails) {
      const userDetailsJSON = JSON.parse(atob(userDetails))
      return {
        id: userDetailsJSON.samaccountname.toLowerCase(),
        firstName: userDetailsJSON.firstname,
        lastName: userDetailsJSON.lastname,
        mail: userDetailsJSON.mail,
      }
    }
  }
  return null
}

export {
  AxiosUtil,
  initAxios,
  addAxiosInterceptors,
  getUserIdFromAccessToken,
  getUserDetailsFromIdToken,
}
