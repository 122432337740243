import React, { useState } from 'react'
import SearchContainer from '../components/SearchContainer/SearchContainer'
import SearchResultsGrid from '../components/SearchResultsGrid/SearchResultsGrid'
import { ItemSearchResultType } from '../types/ItemSearchResultType'
import { ProgressBar } from '@enterprise-ui/canvas-ui-react'
import { SearchCriteriaType } from '../types/SearchCriteriaType'
import { FactorialReleaseNotes } from '../components/ReleaseNotes'

const Home: React.FC = () => {
  const [searchResults, setSearchResults] = useState<ItemSearchResultType[]>([])
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteriaType>()
  const [searchInProgress, setSearchInProgress] = useState<boolean>(false)
  return (
    <>
      {searchInProgress && <ProgressBar indeterminate />}
      <SearchContainer
        onSearchResultsAvailable={setSearchResults}
        setSearchInProgress={setSearchInProgress}
        setSearchCriteria={setSearchCriteria}
      />
      {searchResults.length > 0 && (
        <SearchResultsGrid
          searchResultsData={searchResults}
          searchCriteria={searchCriteria}
        />
      )}

      <FactorialReleaseNotes />
    </>
  )
}

export default Home
