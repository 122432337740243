import {
  Button,
  Spinner,
  ToastProvider,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { useState, FunctionComponent } from 'react'
import EnterpriseIcon, { DownloadIcon } from '@enterprise-ui/icons'

interface ExportProps {
  onClick(): Promise<any>
}

const Export: FunctionComponent<ExportProps> = ({ onClick }) => {
  const [inProgress, setInProgress] = useState<boolean>(false)
  const makeToast = ToastProvider.useToaster()

  const downloadFile = () => {
    setInProgress(true)
    onClick()
      .then(() => {
        setInProgress(false)
      })
      .catch(() => {
        makeToast({
          type: 'error',
          heading: 'Failed to download excel',
          message: 'Encountered an error while downloading excel',
          toastid: Math.random().toString(),
        })
        setInProgress(false)
      })
  }
  return (
    <div>
      <Tooltip location="top" content="Export data to excel">
        <Button onClick={downloadFile} type="ghost" disabled={inProgress}>
          <EnterpriseIcon icon={DownloadIcon} />

          {inProgress && (
            <div style={{ position: 'absolute' }}>
              <Spinner />
            </div>
          )}
        </Button>
      </Tooltip>
    </div>
  )
}

export { Export }
