import React from 'react'
import { Button } from '@enterprise-ui/canvas-ui-react'
import { captureButtonClickEvent } from '../../services/analytics/events-service'
import EnterpriseIcon, { TrashIcon } from '@enterprise-ui/icons'

interface DeleteFactoryButtonProps {
  showButton: boolean
  onDeleteFactoryButtonClick: () => void
}

export const DeleteFactoryButton: React.FC<DeleteFactoryButtonProps> = ({
  showButton,
  onDeleteFactoryButtonClick,
}) => {
  return (
    <>
      {showButton && (
        <Button
          className="hc-pr-lg"
          onClick={() => {
            captureButtonClickEvent('Delete Factory')
            onDeleteFactoryButtonClick()
          }}
        >
          <EnterpriseIcon className="hc-mr-dense" icon={TrashIcon} size="lg" />
          Delete Factory
        </Button>
      )}
    </>
  )
}
