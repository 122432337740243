export enum UserType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export enum ReviewStatus {
  INITIATED = 'INITIATED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
}

export const ReasonForChangeOptions = [
  'Capability',
  'Capacity',
  'Costing',
  'Compliance',
  'Delivery',
  'Other',
]

export const ReasonForRejection = [
  'Capacity Concerns',
  'Factory Performance',
  'COP',
  'Other',
]

export const getDefaultStartDate = () => {
  return new Date('1900/01/01 GMT')
}

export const getDefaultEndDate = () => {
  return new Date('9999/12/31 GMT')
}
