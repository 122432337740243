import React, { useState, useEffect, useCallback } from 'react'
import { Form } from '@enterprise-ui/canvas-ui-react'

interface PcnEventIdsSearchProps {
  pcnEventIdsInitialState?: string[]
  onPcnEventIdsChange(pcnEventIds: string[]): void
}
const PcnEventIdSearch: React.FC<PcnEventIdsSearchProps> = ({
  pcnEventIdsInitialState,
  onPcnEventIdsChange,
}) => {
  const [pcnEventIds, setPcnEventIds] = useState<string[]>([])

  const onInputChange = useCallback(
    (value: string) => {
      if (value.length > 0) {
        const inputEventIds: string[] = []
        value.split(/[ ,]+/).forEach((val) => {
          inputEventIds.push(val)
        })
        setPcnEventIds(inputEventIds)
      } else {
        setPcnEventIds([])
      }
    },
    [setPcnEventIds],
  )

  useEffect(() => {
    onPcnEventIdsChange(pcnEventIds)
  }, [pcnEventIds, onPcnEventIdsChange])

  useEffect(() => {
    if (pcnEventIdsInitialState && pcnEventIdsInitialState.length > 0) {
      setPcnEventIds([...pcnEventIdsInitialState])
      onInputChange(pcnEventIdsInitialState.join())
    }
  }, [pcnEventIdsInitialState, setPcnEventIds, onInputChange])

  return (
    <Form.Field
      label="PCN Event Id"
      type="text"
      id={'pcnEventId'}
      onChange={(event: any) => onInputChange(event.target.value)}
      value={pcnEventIds}
    />
  )
}

export default PcnEventIdSearch
