import { UserType, ReviewStatus } from '../../utils/Constants'
import { BusinessPartnerType, Vendor } from '../../types/BusinessPartnerType'
import {
  FactoryTimeline,
  FactoryTimelineItem,
} from '../../types/ItemDetailsType'
import { AutoCompleteOptionType } from '../../types/AutoCompleteOptionType'
import { UserInfoType } from '../../types/UserInfoType'
import { BulkChangeRequestType } from '../../types/BulkChangeRequestType'

export const createChangeRequest = (changeRequestFields: {
  selectedBusinessPartner: BusinessPartnerType
  tcin: string
  dpci: string
  selectedVendor: AutoCompleteOptionType
  newTimeline: FactoryTimeline
  changedFactoryTimeline: FactoryTimeline
  modifiedVOPTimeLine: FactoryTimeline
  reasonForChange: string
  changeDescription: string
  cycleDescription?: string[]
  userInfo: UserInfoType
  internalContacts?: string[]
  externalContacts?: string[]
  bulk_req_id?: string
  vopChange?: Boolean
}) => {
  const {
    selectedBusinessPartner,
    tcin,
    dpci,
    selectedVendor,
    newTimeline,
    changedFactoryTimeline,
    modifiedVOPTimeLine,
    reasonForChange,
    changeDescription,
    cycleDescription,
    userInfo,
    internalContacts,
    externalContacts,
    bulk_req_id,
    vopChange,
  } = changeRequestFields
  const vendor = selectedBusinessPartner.vendors.filter((vendor: Vendor) => {
    return vendor.gms_vendor_number === selectedVendor.id
  })
  const changeRequest: BulkChangeRequestType = {
    bp_id: selectedBusinessPartner ? selectedBusinessPartner.bp_id : '',
    bp_name: selectedBusinessPartner ? selectedBusinessPartner.bp_name : '',
    tcin,
    dpci,
    bulk_req_id: bulk_req_id,
    vendor_id: vendor && vendor[0]?.vendor_id,
    vendor_name: vendor && vendor[0]?.vendor_name,
    gms_vendor_number: vendor && vendor[0]?.gms_vendor_number,
    has_vop_changed: vopChange === true ? true : false,
    factory_timelines: cleanTimeline(newTimeline.factoryTimelineItems),
    diff_factory_timelines: cleanTimeline(
      changedFactoryTimeline.factoryTimelineItems,
    ),
    vop_timelines: cleanTimeline(modifiedVOPTimeLine.factoryTimelineItems),
    reason_for_change: reasonForChange,
    reason_for_change_description: changeDescription,
    cycle_description: cycleDescription,
    change_request_initiator: { ...userInfo, company: undefined },
    review_status:
      userInfo.user_type === UserType.INTERNAL || vopChange
        ? ReviewStatus.APPROVED
        : ReviewStatus.INITIATED,
    internal_contacts: internalContacts,
    external_contacts: externalContacts,
  }

  return changeRequest
}

export const cleanTimeline = (timelineItems: FactoryTimelineItem[]) => {
  const cleanTimelineItems = timelineItems.map((timelineItem) => {
    const factories = timelineItem.factories.map((factory) => {
      return {
        factory_id: factory.factory_id,
        factory_name: factory.factory_name,
        vendor_order_spec_id: factory.vendor_order_spec_id,
        is_added: factory.is_added,
        is_removed: factory.is_removed,
        is_vop_changed: factory.is_vop_changed,
      }
    })
    return {
      ...timelineItem,
      factories: factories,
    }
  })

  return cleanTimelineItems
}
