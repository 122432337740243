import React, { FunctionComponent } from 'react'
import { SpotlightIds } from './SpotlightIds'
// @ts-ignore
import { Spotlight } from '@enterprise-ui/canvas-ui-react'
import SpotlightContent from './SpotlightContent'
import { useTour } from '../../context/TourContext'

export interface TourSpotlightProps {
  spotlightId: SpotlightIds
  contentWidth: string
  contentLocation: string
}

const FactorialSpotlight: FunctionComponent<TourSpotlightProps> = ({
  spotlightId,
  contentWidth,
  contentLocation,
}) => {
  const { currentTourStep, nextTourStep, isTourEnabled } = useTour()

  const onCloseContent = () => {
    nextTourStep()
  }

  return (
    <>
      {spotlightId === currentTourStep && isTourEnabled && (
        <Spotlight
          spotlightLocation="top-left"
          contentData={SpotlightContent[spotlightId]}
          contentLocation="top-left"
          size="normal"
          spotlightAnimation
          spotlightAnimationDuration="infinite"
          contentIsVisible={true}
          onCloseContent={onCloseContent}
          hasOverlay
        />
      )}
    </>
  )
}

export default FactorialSpotlight
