import React, { useEffect, useState } from 'react'
import { BulkChangeResponseType } from '../../types/BulkChangeResponseType'
import { processChangeRequestResponse } from './BulkChangeRequestUtil'
import { TcinsCard, WarningMessagesCard } from './TcinsCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { Button } from '@enterprise-ui/canvas-ui-react'
import { ItemVendorFactoryTimeline } from '../../types/ItemVendorFactoryType'

interface BulkChangeResponseProps {
  bulkChangeResponse: BulkChangeResponseType[]
  invalidItemVendorFactoryTimelines: ItemVendorFactoryTimeline[]
  unchangedItemVendorFactoryTimelines: ItemVendorFactoryTimeline[]
  onExit: () => void
}
const BulkChangeResponse: React.FC<BulkChangeResponseProps> = ({
  bulkChangeResponse,
  invalidItemVendorFactoryTimelines,
  unchangedItemVendorFactoryTimelines,
  onExit,
}) => {
  const [successTcins, setSuccessTcins] = useState<string[]>([])
  const [failureTcins, setFailureTcins] = useState<string[]>([])
  const [failureMessages, setFailureMessages] = useState<string[]>([])

  const invalidTcins = invalidItemVendorFactoryTimelines.map(
    (timeline) => timeline.tcin,
  )

  const unchangedTcins = unchangedItemVendorFactoryTimelines.map(
    (timeline) => timeline.tcin,
  )

  useEffect(() => {
    const processedChangeResponse =
      processChangeRequestResponse(bulkChangeResponse)
    setSuccessTcins(processedChangeResponse.success.map((resp) => resp.tcin))
    setFailureTcins(processedChangeResponse.failure.map((resp) => resp.tcin))
    setFailureMessages(
      processedChangeResponse.failure.map(
        (resp) => `${resp.tcin}: ${resp.error_message}`,
      ),
    )
  }, [bulkChangeResponse])
  return (
    <>
      {failureTcins.length > 0 && (
        <div>
          <p style={{ fontWeight: 'bold' }}>
            <span>
              <FontAwesomeIcon
                className="hc-mr-dense"
                icon={faExclamationTriangle}
                size="lg"
                color="#b85300"
              />
            </span>
            Failed to submit Change Requests for one or more TCINs. See below
            for failed TCINs and error report.
          </p>
        </div>
      )}
      {failureTcins.length === 0 && (
        <div>
          <p style={{ fontWeight: 'bold' }}>
            <span>
              <FontAwesomeIcon
                className="hc-mr-dense"
                icon={faCheckCircle}
                size="lg"
                color="#008300"
              />
            </span>
            Successfully submitted Change Requests for all TCINs.
          </p>
        </div>
      )}
      {successTcins.length > 0 && (
        <TcinsCard
          tcins={successTcins}
          title="Successfully created Change Requests"
          tcinsPerRow={8}
        />
      )}
      {failureTcins.length > 0 && (
        <>
          <TcinsCard
            tcins={failureTcins}
            title="Failed to submit Change Requests"
            tcinsPerRow={8}
            isWarn={true}
          />
          <WarningMessagesCard
            warnTitle="Server Errors Report"
            warningMessages={failureMessages}
          />
        </>
      )}
      {invalidTcins.length > 0 && (
        <>
          <TcinsCard
            tcins={invalidTcins}
            title="TCINs with Validation errors"
            tcinsPerRow={8}
            isWarn={true}
          />
        </>
      )}
      {unchangedTcins.length > 0 && (
        <>
          <TcinsCard
            tcins={unchangedTcins}
            title="TCINs with unchanged timelines"
            tcinsPerRow={8}
            isWarn={true}
          />
        </>
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" onClick={onExit}>
          <span style={{ textTransform: 'capitalize' }}>Exit </span>
        </Button>
      </div>
    </>
  )
}

export default BulkChangeResponse
