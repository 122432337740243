import React, { ReactNode } from 'react'
import { useState } from 'react'

const HelpContext = React.createContext<any>(null)

interface Props {
  children: ReactNode
}

const HelpContextProvider = ({ children }: Props) => {
  const onHelpCloseInit = () => {}
  const [label, setLabel] = useState<string>('')
  const [startingQuery, setStartingQuery] = useState<string>('')
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false)
  const [onHelpClose, setOnHelpClose] = useState<() => void>(onHelpCloseInit)
  return (
    <HelpContext.Provider
      value={{
        label,
        setLabel,
        startingQuery,
        setStartingQuery,
        isHelpOpen,
        setIsHelpOpen,
        onHelpClose: onHelpClose ?? onHelpCloseInit,
        setOnHelpClose,
      }}
    >
      {children}
    </HelpContext.Provider>
  )
}

const useHelp = (): {
  label: string
  setLabel: (label: string) => void
  startingQuery: string
  setStartingQuery: (query: string) => void
  isHelpOpen: boolean
  setIsHelpOpen: (isHelpOpen: boolean) => void
  onHelpClose: () => void
  setOnHelpClose: (onHelpClose: () => void) => void
} => {
  return React.useContext(HelpContext)
}

export { HelpContextProvider, useHelp }
