import React, { FunctionComponent, useEffect, useState } from 'react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { lookupFactoryName } from '../../services/LookupService'
import { LookupValue } from '../../types/LookupValue'
import { useUserInfo } from '../../context/UserInfoContext'
import { useBusinessPartnerInfo } from '../../context/BusinessPartnerInfoContext'
import { UserType } from '../../utils/Constants'

interface FactoryNameSearchProps {
  factoryNameSearchInitialState: string
  onFactoryChange(factoryId: string): void
  onFactoryNameChange(factoryName?: string): void
}
const FactoryNameSearch: FunctionComponent<FactoryNameSearchProps> = ({
  factoryNameSearchInitialState,
  onFactoryChange,
  onFactoryNameChange,
}) => {
  const [factoryName, setFactoryName] = useState<LookupValue>()
  const { userInfo } = useUserInfo()
  const { businessPartner } = useBusinessPartnerInfo()
  const [factoryNameLookupForExternal, setFactoryNameLookupForExternal] =
    useState<LookupValue[]>([])

  useEffect(() => {
    if (factoryNameSearchInitialState) {
      if (userInfo.user_type === UserType.INTERNAL) {
        lookupFactoryName(factoryNameSearchInitialState).then(
          (lookupValues: LookupValue[]) => {
            lookupValues &&
              lookupValues.length > 0 &&
              setFactoryName(
                lookupValues.find(
                  (lookup) => lookup.value === factoryNameSearchInitialState,
                ),
              )
          },
        )
      } else {
        if (businessPartner?.locations) {
          const factoryLookup: LookupValue[] = businessPartner.locations?.map(
            (location) => {
              return {
                id: location.location_id,
                value: location.location_name,
                label: location.location_name,
              }
            },
          )
          const exterinitlookup = factoryLookup.find(
            (lookup) => lookup.value === factoryNameSearchInitialState,
          )
          setFactoryName(exterinitlookup)
        }
      }
    }
  }, [businessPartner, factoryNameSearchInitialState, userInfo.user_type])

  useEffect(() => {
    if (factoryName && factoryName.value) {
      onFactoryNameChange(factoryName.value)
      onFactoryChange(factoryName.id)
    } else {
      onFactoryNameChange('')
      onFactoryChange('')
    }
  }, [factoryName, onFactoryNameChange, onFactoryChange])

  useEffect(() => {
    if (userInfo.user_type === UserType.EXTERNAL && businessPartner) {
      const factoryLookup: LookupValue[] = businessPartner.locations
        ? businessPartner.locations?.map((location) => {
            return {
              id: location.location_id,
              value: location.location_name,
              label: location.location_name,
            }
          })
        : []
      setFactoryNameLookupForExternal(factoryLookup)
    }
  }, [userInfo.user_type, businessPartner])

  const factoryNameLookUpValues = async (value: any) => {
    if (userInfo.user_type === UserType.EXTERNAL) {
      if (value) {
        return factoryNameLookupForExternal.filter(
          (factoryName) => factoryName.label.indexOf(value) === 0,
        )
      }
      return factoryNameLookupForExternal
    } else {
      if (!value) return []
      if (factoryName && value === factoryName.label) return [factoryName]
      return await lookupFactoryName(encodeURIComponent(value))
    }
  }

  return (
    <Autocomplete
      label="Factory Name"
      id="factoryName"
      location="left"
      filter={Autocomplete.filters.none}
      onUpdate={(id: any, value: any) => setFactoryName(value)}
      value={factoryName}
      options={factoryNameLookUpValues}
    />
  )
}

export default FactoryNameSearch
