import React, { ReactNode, useEffect } from 'react'
import { useState } from 'react'

const LocalStorageContext = React.createContext<any>(null)

interface Props {
  children: ReactNode
}

enum LocalStorageKeys {
  BulkEditFeedbackSubmittedAt = 'factorial_bulkEditFeedbackSubmittedAt',
  ReleaseNotesSeenAt = 'factorial_releaseNotesSeenAt',
}

const LocalStorageContextProvider = ({ children }: Props) => {
  const initialBulkEditFeedbackSubmittedAt =
    localStorage.getItem(LocalStorageKeys.BulkEditFeedbackSubmittedAt) ??
    '01-01-1900'
  const initialReleaseNotesSeenAt =
    localStorage.getItem(LocalStorageKeys.ReleaseNotesSeenAt) ?? '01-01-1900'

  const [bulkEditFeedbackSubmittedAt, setBulkEditFeedbackSubmittedAt] =
    useState<Date>(new Date(initialBulkEditFeedbackSubmittedAt))

  const [releaseNotesSeenAt, setReleaseNotesSeenAt] = useState<Date>(
    new Date(initialReleaseNotesSeenAt),
  )

  useEffect(() => {
    if (bulkEditFeedbackSubmittedAt) {
      localStorage.setItem(
        LocalStorageKeys.BulkEditFeedbackSubmittedAt,
        bulkEditFeedbackSubmittedAt.toDateString(),
      )
    }
  }, [bulkEditFeedbackSubmittedAt])

  useEffect(() => {
    if (releaseNotesSeenAt) {
      localStorage.setItem(
        LocalStorageKeys.ReleaseNotesSeenAt,
        releaseNotesSeenAt.toDateString(),
      )
    }
  }, [releaseNotesSeenAt])

  return (
    <LocalStorageContext.Provider
      value={{
        bulkEditFeedbackSubmittedAt,
        setBulkEditFeedbackSubmittedAt,
        releaseNotesSeenAt,
        setReleaseNotesSeenAt,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  )
}

const useLocalStorage = (): {
  bulkEditFeedbackSubmittedAt: Date
  setBulkEditFeedbackSubmittedAt: (bulkEditFeedbackSubmittedAt: Date) => void
  releaseNotesSeenAt: Date
  setReleaseNotesSeenAt: (releaseNotesSeenAt: Date) => void
} => {
  return React.useContext(LocalStorageContext)
}

export { LocalStorageContextProvider, useLocalStorage }
