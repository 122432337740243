import Firefly, { CustomInteraction, CustomMetrics, Json } from 'firefly-sdk-ts'
import { getAppConfig, getFireflyUrl } from '../../config/AppConfig'
import { getUserDetailsFromIdToken } from '../../utils/AxiosUtil'

let isFireflyInitialized = false

let eventManagerConfig = {
  url: '',
  apiKey: '',
  eventManagerKey: '',
  persistMethod: '',
  retries: 1,
}

export const initializeFireFly = () => {
  eventManagerConfig = {
    url: getFireflyUrl(),
    apiKey: getAppConfig().apiKey,
    eventManagerKey: 'internalApp',
    persistMethod: 'localStorage',
    retries: 1,
  }
  Firefly.registerEventManagerWithConfig(eventManagerConfig)
  setGlobalLayerData()
}

export const setGlobalLayerData = () => {
  let globalData: Json = {}
  const userDetails = getUserDetailsFromIdToken()

  globalData.application = {
    name: 'Factorial',
  }
  if (userDetails) {
    globalData.user = {
      id: userDetails.id,
      customDimension1: userDetails.firstName + ' ' + userDetails.lastName,
      customDimension2: userDetails.mail,
    }
  }
  Firefly.setGlobalDataLayer(globalData)
}

export function trackCustomEvent(
  eventType: string,
  key: string,
  value: string | number,
  customMetricNumber?: number,
  location?: string,
) {
  if (!isFireflyInitialized) {
    initializeFireFly()
  }
  const customInteraction = {
    key,
    value: value.toString(),
  } as CustomInteraction

  if (location) {
    customInteraction.location = location
  }

  const customMetrics = {
    metric4: customMetricNumber,
  } as CustomMetrics

  return Firefly.getEventManager(eventManagerConfig).addEvent({
    event: { type: eventType },
    customInteraction: customInteraction,
    customMetrics: customMetrics,
  })
}
