import React, { ReactNode } from 'react'
import { useState } from 'react'

let PageInfoContext = React.createContext<any>(null)

interface Props {
  children: ReactNode
}

const PageInfoContextProvider = ({ children }: Props) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  return (
    <PageInfoContext.Provider value={{ pageTitle, setPageTitle }}>
      {children}
    </PageInfoContext.Provider>
  )
}

const usePageInfo = (): {
  pageTitle: string
  setPageTitle: (pageTitle: string) => void
} => {
  return React.useContext(PageInfoContext)
}

export { PageInfoContextProvider, usePageInfo }
