import { Card, ExpandableSection } from '@enterprise-ui/canvas-ui-react'
import React, { useState } from 'react'
import './TcinsCard.scss'
import { TcinsList } from './TcinsList'

const TcinsCard: React.FC<{
  tcins: string[]
  tcinsPerRow?: number
  title: string
  text?: string
  isWarn?: boolean
}> = ({ tcins, title, text, tcinsPerRow = 4, isWarn = false }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <div className="hc-pa-normal">
      <div className={isWarn ? 'warning-card' : 'success-card'}>
        <p>{text}</p>
        <Card>
          <ExpandableSection
            expanded={isExpanded}
            onExpand={() => {
              setIsExpanded(!isExpanded)
            }}
          >
            <div style={{ fontWeight: 500, color: 'black' }}>
              {`${title} (${tcins.length} TCINs)`}
            </div>
            <ExpandableSection.Content className="hc-ml-expanded">
              <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <TcinsList tcins={tcins} tcinsPerRow={tcinsPerRow} />
                <br></br>
              </div>
            </ExpandableSection.Content>
          </ExpandableSection>
        </Card>
      </div>
    </div>
  )
}

const WarningMessagesCard: React.FC<{
  warningMessages: string[]
  warnTitle: string
}> = ({ warningMessages, warnTitle }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <div className="hc-pa-normal">
      <div className={'warning-card'}>
        <Card>
          <ExpandableSection
            expanded={isExpanded}
            onExpand={() => {
              setIsExpanded(!isExpanded)
            }}
          >
            <div style={{ fontWeight: 500, color: 'black' }}>
              {`${warnTitle} (${warningMessages.length} TCINs)`}
            </div>
            <ExpandableSection.Content className="hc-ml-expanded">
              <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {warningMessages.map((msg) => {
                  return <p>{msg}</p>
                })}
                <br></br>
              </div>
            </ExpandableSection.Content>
          </ExpandableSection>
        </Card>
      </div>
    </div>
  )
}

export { TcinsCard, WarningMessagesCard }
